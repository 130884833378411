
// Libraries
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form } from 'react-final-form';
import classnames from 'classnames';

// Helpers
import { corporateSalesValidation } from './helpers/corporateSalesValidation'

// Hooks
import { useSpecialOrder, useTimer } from '../../../hooks';

// Components
import Button from '../../../components/Button';
import { Input } from '../../Input/Input';
import { Loader } from '../../Loader';

// Constants
import { useResize } from '../../../constants';
import { useFooterData } from '../../../hooks/useFooterData';

// Icons
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorCircleOutlineIcon from '@material-ui/icons/ErrorOutline';

// Styles
import styles from './style.module.scss';


const CorporateSales = ({ onClose }) => {
  const { subInfoForBanners } = useFooterData();
  const { id } = useParams();
  const { specialOrderHandler, loading: sendingProcess } = useSpecialOrder();
  const { isTablet } = useResize();
  const { time, setTime, hasEnded } = useTimer(1000);
  const [error, setError] = useState(null);

  const onSubmit = (data) => { 
    specialOrderHandler({ ...data, type: 1, productId: id })
      .then(() => setTime(5000))
      .catch(setError)
  };

  useEffect(() => hasEnded && time <= 0 && onClose(), [time]);

  const reset = () => setError(null);

  const Content = () => (
    <>
      <div className={styles.Title}>Корпоративные продажи</div>
      <div className={styles.Wrapper}>
        <div className={styles.SubTitle}>
          {subInfoForBanners?.corporate_text}
        </div>
        <Form
          onSubmit={onSubmit}
          validate={corporateSalesValidation}
          render={({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit} className={styles.Form}>
                <Input
                  name='title'
                  placeholder={isTablet ? 'Названия интересующих товаров' : 'Введите названия интересующих Вас товаров'}
                />
                <Input name='message'  placeholder='Ваше сообщение' />
                <Input name='email' placeholder='Ваш e-mail' />
                <Input name='phone' placeholder='Ваш телефон' />
                <Button
                  htmlType='submit'
                  filled
                  style={{
                    width: '100%',
                    height: 48,
                    marginTop: isTablet ? 12 : 39
                  }}
                >
                  Отправить запрос
                </Button>
              </form>
            )
          }}
        />
      </div>
    </>
  );

  const Send = () => (
    <div className={styles.Status}>
      <Loader />
      <div className={styles.Message}>Заявка отправляется...</div>
    </div>
  );

  const Success = () => (
    <div className={styles.Status}>
      <CheckCircleOutlineIcon style={{width: 40, height: 40, fill: '#008a00'}} />
      <div className={classnames(styles.Message, styles.Message_success)}>
        Заявка успешно отправлена! Скоро мы с вами свяжемся.
      </div>
      <div className={styles.Timer}>
        Закрытие окна через {time / 1000}...
      </div>
    </div>
  );

  const Error = () => (
    <div className={styles.Status}>
      <ErrorCircleOutlineIcon style={{width: 40, height: 40, fill: '#d53e3e'}} />
      <div className={classnames(styles.Message, styles.Message_error)}>
        Заявка не смогла быть отправлена по {!error ? 'неизвестной' : ''} причине{error ? ` '${error}'` : ''}. Попробуйте заполнить заявку снова.
      </div>
      <div className={classnames(styles.Message, styles.Message_action)} onClick={reset}>
        Заполнить снова
      </div>
    </div>
  );

  const hasError = error !== null;
  const hasSubmittedSuccessful = time > 0 && !hasError;

  const hasAnyEvent = sendingProcess || hasSubmittedSuccessful || hasError

  return (
    <div className={hasAnyEvent && styles.Container}>
      {sendingProcess && <Send />}
      {hasSubmittedSuccessful && <Success />}
      {hasError && <Error />}
      {!hasAnyEvent && <Content />}
    </div>
  )
};

export default CorporateSales;
