import classNames from 'classnames';
import { ReactComponent as CloseIcon } from '../../assets/close_red.svg';

import styles from './ErrorPopup.module.scss';

export const ErrorPopup = ({ text, className = '', style = {}, handlerClose }) => {
  return (
    <div className={classNames(styles.ErrorPopup, className)} style={style}>
      <span className={styles.ErrorPopup__text}>{text}</span>
      <CloseIcon className={styles.ErrorPopup__close} onClick={handlerClose} />
    </div>
  );
};
