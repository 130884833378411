// Components
import Divider from '../Divider';
import Button from '../Button';

// Styles
import styles from './style.module.scss';


const SETTINGS = {
  title: true,
  phone: true,
  email: true,
};


const ConnectWithSupport = ({ settings = {} }) => {
  const mergedSettings = {
    ...SETTINGS,
    ...settings,
  };

  return (
    <div className={styles.ConnectWithSupport}>
      {mergedSettings.title && (
        <>
          <div className={styles.Title}>Всё ещё остались вопросы?</div>
          <div className={styles.Divider}>
            <Divider />
          </div>
        </>
      )}
      {mergedSettings.phone && (
        <div className={styles.Phone}>
          <a href="tel: 88124297407">8 (812) 429-74-07</a>
        </div>
      )}
      {mergedSettings.email && (
        <div className={styles.Mail}>
          <a href="mailto: info@hermitageshop.ru">
            info@hermitageshop.ru
          </a>
        </div>
      )}
      <Button
        style={{ width: '100%' }}
        onClick={() => window.jivo_api.open()}
      >
        Чат с поддержкой
      </Button>
    </div>
  );
}

export default ConnectWithSupport;
