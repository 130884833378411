import { validateEmail } from '../../../helpers';

export const loginValidation = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = 'Поле обязательно!';
  }

  else if (!validateEmail(values.email)) {
    errors.email = 'Неверный формат email.';
  }

  if (!values.password) {
    errors.password = 'Поле обязательно!';
  }

  return errors;
};
