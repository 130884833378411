
// Hooks
import { useFooterData } from '../../../hooks/useFooterData';

// Styles
import styles from './style.module.scss';


export const Cookies = () => {
  const { subInfoForBanners } = useFooterData();

  return (
    <div>
      <div className={styles.Title}>Использование Cookies</div>
      <div dangerouslySetInnerHTML={{ __html: subInfoForBanners?.text_cookies }} />
    </div>
  );
};
