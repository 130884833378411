
import React from 'react';
import classnames from 'classnames';

import styles from './style.module.scss';


const Stat = ({ type, value }) => {
	const [isOpened, setIsOpened] = React.useState(false);
	const LENGTH_OF_STRING = 17;
	const MAX_COUNT_OF_ROWS = 5;
	const hasMore = value.length > LENGTH_OF_STRING * MAX_COUNT_OF_ROWS;

	return (
		<div className={styles.Stat}>
			<div className={styles.Type}>
				{type}
			</div>
			<div
				className={classnames(
					styles.Value,
					isOpened && styles.Value_full
				)}
			>
				{value}
			</div>
			{hasMore && (
				<div
					onClick={() => setIsOpened(prev => !prev)}
					className={styles.Action}
				>
					{isOpened
						? 'Свернуть'
						: 'Развернуть'
					}
				</div>
			)}
		</div>
	);
};

export default Stat;