// Libraries
import classnames from 'classnames';
import { useParams } from 'react-router-dom';

// Styles
import styles from './style.module.scss';

const CategoryCard = ({ item, classes, onClick }) => {
  const { subCategory } = useParams();

  return (
    <div
      onClick={onClick}
      className={classnames(
        styles.CategoryCard,
        (item?.seo_url && subCategory === item.seo_url
          || subCategory === item.name.toLowerCase().replace(/ /g, '_')) && styles.CategoryCard_selected,
        classes?.card,
      )}
    >
      <div className={styles.Icon}>
        <img src={item.image} alt='' />
      </div>
      <div className={classnames(styles.Label, classes?.label)}>{item.name}</div>
    </div>
  )
};

export default CategoryCard;
