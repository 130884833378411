
// Libraries
import _ from 'lodash';
import moment from 'moment';

// Components
import LoadContainer from '../../../components/LoadContainer';
import OrderTab from '../../../components/OrderTab';
import Divider from '../../../components/Divider';

// Hooks
import { useGetCustomerOrders } from '../hooks';

// Styles
import styles from './style.module.scss'


export const MyOrders = () => {
  const { data = [], loading } = useGetCustomerOrders();

  return (
    <div className={styles.MyOrders}>
      <Divider />
      <LoadContainer
        component={props => <OrderTab data={props} />}
        type="orders"
        loading={loading}
        items={_.orderBy(data, ({ created_at }) => moment(created_at, 'DD.MM.YYYY hh:mm:ss').valueOf(), ['desc'])}
        gridSettings={{
          itemsPerRow: {
            desktop: 1,
            tablet: 1,
            largeMobile: 1,
            mobile: 1,
          },
          itemsPerPage: {
            desktop: 5,
            tablet: 5,
            largeMobile: 5,
            mobile: 5,
          },
          gridRowGap: 0,
          gridColGap: 0,
        }}
      />
    </div>
  );
};
