import { validateEmail } from '../../../helpers';

export const registerValidation = (values) => {
  const errors = {};
  const required = 'Поле обязательно!';

  if (!values.customerNameAndLastName) {
    errors.customerNameAndLastName = required;
  }

  if (!values.sex) {
    errors.sex = required;
  }

  if (!values.birthday) {
    errors.birthday = required;
  }

  if (!values.email) {
    errors.email = required;
  }

  else if (!validateEmail(values.email)) {
    errors.email = 'Неверный формат email.';
  }

  if (!values.password) {
    errors.password = required;
  }

  if (values.password?.length < 8) {
    errors.password = 'Пароль должен быть неменее 8 символов';
  }

  if (values.password !== values.password_confirmation) {
    errors.password_confirmation = 'Пароли не совпадают.';
  }

  if (!values.personal_data) {
    errors.personal_data = required;
  }

  return errors;
};
