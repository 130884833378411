// Libraries
import { useState } from 'react';
import moment from 'moment';

// Components
import Divider from '../../Divider';
import ConnectWithSupport from '../../ConnectWithSupport';
import TicketsSelectorComponent from '../../TicketsSelector';
import Contacts from '../../Contacts';
import CalendarComponent from '../../Calendar';

// Constants
import { TICKET_VARIABLES, useResize } from '../../../constants';

// Styles
import styles from './style.module.scss';
import { formatPrice } from '../../../helpers/formatPrice';

const MuseumItem = ({ data = {} }) => {
  const { isMobile } = useResize();

  const {
    imgSrc = 'https://artmus.ru/content/upload/images/%D1%8D%D1%80%D0%BC%D0%B8%D1%82%D0%B0%D0%B6.jpg',
    name = 'Рембрандт',
    price = 500,
    dateOfStart = moment().subtract(5, 'days').format('DD'),
    dateOfEnd = moment().format('DD'),
  } = data;

  const [selectedValues, setSelectedValues] = useState({
    date: moment().format('DD'),
    time: moment().format('hh:mm:ss'),
    tickets: {
      [TICKET_VARIABLES.adults]: 1,
      [TICKET_VARIABLES.children]: 0,
      [TICKET_VARIABLES.preferential]: 0,
    },
  });

  const ticketsSelectorData = Object.keys(TICKET_VARIABLES).map((ticket, i) => ({
    type: ticket,
    count: selectedValues.tickets[ticket],
    price: 300 * (i + 1),
  }));

  const handleChangeValues = (field) => (value) =>
    setSelectedValues((prev) => ({
      ...prev,
      [field]: value,
    }));

  const Row = () => (
    <div className={styles.Row}>
      <Divider />
      <div className={styles.Row__Content}>
        <div className={styles.Text}>
          {formatPrice(price)}
        </div>
      </div>
      <Divider />
    </div>
  );

  const Preview = () => (
    <div className={styles.Preview}>
      <img src={imgSrc} alt="" />
    </div>
  );

  const Calendar = () => (
    <div className={styles.Calendar}>
      <CalendarComponent title="Выберите дату и время посещения" />
    </div>
  );

  const TicketsSelector = () => (
    <div className={styles.TicketsSelector}>
      <TicketsSelectorComponent
        data={ticketsSelectorData}
        onChange={handleChangeValues('tickets')}
      />
    </div>
  );

  const BottomInfo = () => (
    <>
      <Divider />
      <div className={styles.BottomInfo}>
        <div className={styles.Contacts}>
          <Contacts />
        </div>
        {isMobile && <Divider />}
        <div className={styles.ContactWithSupport}>
          <ConnectWithSupport />
        </div>
      </div>
    </>
  );

  return (
    <>
      <div className={styles.Name}>{name}</div>
      <Row />
      <div className={styles.About}>
        Северный город Франции Овер-сюр-Уаз напоминал Винсенту Ван Гогу о родной Голландии. Здесь
        художник написал множество пейзажей и городских видов, но «Хижины» обладают особенным
        сентиментальным художественным очарованием...
      </div>
      <Preview />
      <Calendar />
      <TicketsSelector />
      <BottomInfo />
    </>
  );
};

export default MuseumItem;
