// Libraries
import { useEffect, useMemo, useCallback } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from "react-redux";

// Components
import Page from '../../components/Page';
import Button from '../../components/Button';
import PromoCodeInput from '../../components/PromoСodeInput';
import BasketItem from '../../components/BasketItem';
import RowItems from '../../components/RowItems';
import { Loader } from '../../components/Loader';

// Helpers
import { formatPrice } from '../../helpers/formatPrice';

// Hooks
import { useCart, useGetCommonPriceCart, useGetOnlyNoveltiesProducts } from '../../hooks';

// Actions
import { setPromoValue,  setPromoError } from '../../actions';

// Selectors
import { selectPromoIsUsed, selectPromoError } from '../../selectors';

// Styles
import styles from './style.module.scss';

const getNoun = (count) => {
  // todo: переписать по-человечески

  // товар
  const numbersFirstType = [1, 21];

  // товара
  const numbersSecondType = [2, 3, 4, 22, 23, 24];

  return numbersFirstType.includes(count)
    ? 'товар'
    : numbersSecondType.includes(count)
    ? 'товара'
    : 'товаров';
};

const Basket = () => {
  const dispatch = useDispatch();
  const { products, loading } = useCart();
  const promoData = useSelector(store => store?.promoReducer?.data);
  const { noveltiesProducts } = useGetOnlyNoveltiesProducts();
  const promoError = useSelector(selectPromoError);
  const promoIsUsed = useSelector(selectPromoIsUsed);
  const stockBlocker = useMemo(() =>
    products.some(({ productOptionValue, quantity, quantity_selected }) => quantity_selected > (productOptionValue?.quantity || quantity)
  ), [products]);

  const preparedProducts = useMemo(() => {
    return products.map(product => {

      const isDiscountItem =
        promoData
        && promoData?.coupon?.type === 'present'
        && promoData?.products?.includes(+product.id);

      const discount = promoData?.coupon?.discount;
      const option = product?.productOptionValue;

      return ({
        ...product,
        option,
        image: option?.image || product.image || product?.product?.image,
        price: option?.price ?? product.price ?? product.product_price,
        special_price: !promoError && isDiscountItem
          ? option?.price
            ? option.price * (1 - discount / 100)
            : product.price * (1 - discount / 100)
          : product.special_price
      })
    })
  }, [products, promoData]);

  const Products = useCallback(() => {
    return preparedProducts.map((product) => (
      <BasketItem
        key={product.id}
        product={product}
      />
    ));
  }, [preparedProducts]);

  const { cartPrice, cartPriceWithoutDiscount, cartLength } = useGetCommonPriceCart(preparedProducts, promoData);

  useEffect(() => {
    if (!promoIsUsed) {
      dispatch(setPromoValue(''));
    }
  }, []);

  useEffect(() => {
    if(promoData?.coupon?.type === "sum" && cartPriceWithoutDiscount < parseInt(promoData?.coupon?.discount)) {
      dispatch(setPromoError(`Не хватает ${formatPrice(parseInt(promoData?.coupon?.discount) - cartPrice)} в корзине для применения купона`));
    } else if(promoData?.coupon?.type === "present" && parseInt(promoData?.coupon?.discount) > 100) {
      dispatch(setPromoError('Ошибка в купоне'));
    } else {
      if (promoData?.status) {
        dispatch(setPromoError(null));
      } else {
        dispatch(setPromoError(promoData?.message));
      }
    }
  }, [promoData, cartPrice, cartPriceWithoutDiscount, dispatch]);

  const Content = () => {
    if (loading) {
      return <Loader style={{ margin: '125px auto' }} />;
    }

    if (!loading && products.length === 0) {
      return <div className={styles.Empty}>Корзина пуста!</div>;
    }

    if (!loading && products.length > 0) {
      return (
        <>
          <div
            className={classnames(
              styles.LeftCol,
              products.length === 0 && styles.LeftCol_fullWidth,
            )}
          >
            <Products />
          </div>
          <div className={styles.RightCol}>
            <div className={styles.Total}>
              <div className={styles.Total__Text}>
                В корзине {cartLength} {getNoun(cartLength)}
              </div>
              {products.length > 0 && (
                <>
                  <div className={styles.Total__Border} />
                  <div className={styles.Total__Price}>
                    {!promoError && promoData && cartPrice !== cartPriceWithoutDiscount && (
                      <span className={styles.Total__OldPrice}>
                        {formatPrice(cartPriceWithoutDiscount)}
                      </span>
                    )}
                    <span>
                      {promoError === 'Ошибка в купоне' 
                        ? formatPrice(cartPriceWithoutDiscount)
                        : formatPrice(cartPrice)
                      }
                    </span>
                  </div>
                </>
              )}
            </div>
            <PromoCodeInput
              products={products}
              containerClasses={[styles.PromoCodeInput]}
            />
            <Button
              link="/ordering"
              linkClassName={styles.Basket__link}
              disabled={stockBlocker || products.length === 0}
            >
              Перейти к оформлению
            </Button>
            {stockBlocker && (
              <div className={styles.StockBlocker}>
                Перед оформлением заказа удалите из корзины отсутствующие товары или уменьшите количество
              </div>
            )}
          </div>
        </>
      );
    }

    return null;
  };

  return (
    <Page title="Моя корзина">
      <div className={styles.Basket}>
        <Content />
      </div>
      <RowItems
        title="Вам может понравиться"
        items={noveltiesProducts}
        link="/catalog"
      />
    </Page>
  );
};

export default Basket;
