
// Styles
import styles from './style.module.scss';

const Offer = () => {
  return (
    <div className={styles.Offer}>
      <div className={styles.Title}>Договор оферты</div>
      <div className={styles.Heading}>1. Термины</div>
      <p>
        <ul>
          <li>«Продавец» — компания, реализующая Товар, представленный в каталоге данного сайта.</li>
          <li>«Товар» — перечень наименований ассортимента.</li>
          <li>«Договор» — договор купли-продажи между Продавцом и любым физическим лицом, заключенный на существующих условиях, содержащихся в Договоре, включая все его приложения.</li>
          <li>«Покупатель» —физическое лицо, заключающее с Продавцом Договор.</li>
          <li>«Заказ» — отдельные позиции из ассортиментного перечня, указанные Покупателем при размещении заявки на интернет-сайте или через Оператора.</li>
          <li>«Оператор» — сторонняя организация, предоставляющая Покупателю информационно-консультационные услуги по размещению предварительного заказа Товара.</li>
          <li>«Стороны» — совместное упоминание Продавца и Покупателя.</li>
        </ul>
      </p>
      <div className={styles.Heading}>2. Предмет договора</div>
      <p>
        2.1. Продавец продает Товар в соответствии с действующим прейскурантом, опубликованным на интернет-сайте Продавца, а Покупатель производит оплату и принимает Товар в соответствии с условиями настоящего Договора.
      </p>
      <p>
        2.2. Настоящий Договор и приложения к нему являются официальными документами Продавца и неотъемлемой частью Оферты.
      </p>-
      <div className={styles.Heading}>
        3. Оформление заказа
      </div>
      <p>
        3.1. Принятие Покупателем условий настоящего Договора осуществляется посредством оформления Заказа.
      </p>
      <p>
        3.2. При оформлении Заказа Покупатель обязуется предоставить следующую информацию:
      </p>
      <p>
        <ul>
          <li>фамилия, имя;</li>
          <li>почтовый адрес (включая индекс);</li>
          <li>адрес электронной почты;</li>
          <li>контактные телефоны.</li>
        </ul>
      </p>
      <p>
        3.3. Покупатель несёт ответственность за достоверность информации, предоставленной при размещении Заказа.
      </p>
      <p>
        3.4. Все информационные материалы, представленные на сайте, носят справочный характер и не могут в полной мере передавать достоверную информацию об определенных свойствах и характеристиках Товара, таких как: цена, цвет, форма, размер и упаковка. В случае возникновения у Покупателя вопросов, касающихся свойств и характеристик Товара, перед размещением Заказа ему необходимо обратиться за консультацией к Оператору.
      </p>
      <div className={styles.Heading}>4. Срок исполнения заказа</div>
      <p>
        4.1. Срок, в который Продавец обязуется исполнить Заказ, составляет от одного рабочего дня до трех календарных месяцев и зависит от наличия заказанных позиций Товара на складе Продавца и времени, необходимого на обработку Заказа.
      </p>
      <p>
        4.2. В случае отсутствия части Заказа на складе Продавца, в том числе по причинам, не зависящим от последнего, Продавец вправе аннулировать указанный Товар из Заказа Покупателя. Продавец обязуется уведомить Покупателя об изменении комплектности его Заказа посредством электронной почты, телефона или при непосредственном получении Заказа Покупателем.
      </p>
      <p>
        4.3. Заказ считается исполненным в момент его передачи Покупателю. При наличном расчете оплатой полученного Заказа Покупатель подтверждает, что Заказ исполнен надлежащим образом.
      </p>
      <p>
        4.4. В случае предоставления Покупателем недостоверной информации о своих контактных данных или составе Заказа Продавец за ненадлежащее исполнение Заказа ответственности не несет.
      </p>
      <div className={styles.Heading}>5. Оплата заказа</div>
      <p>
        5.1. Оплата исполненного Заказа осуществляется путем перечисления Покупателем денежных средств посредством системы безналичных расчетов.
      </p>
      <p>
        5.2. Цена на каждую позицию Товара отображена на интернет-сайте. В случае изменения цены на заказанные позиции Товара, Оператор обязуется в кратчайшие сроки проинформировать Покупателя о таком изменении. Покупатель вправе подтвердить либо аннулировать Заказ.
      </p>
      <p>
        5.3. Приобретение некоторых групп товаров возможно только при осуществлении предоплаты. Размер предоплаты и сроки исполнения Заказа зависят от условий поставки и оговариваются в каждом конкретном случае отдельно с Оператором при подтверждении Заказа. Срок ожидания заказа может составлять от 1 недели до 3-х месяцев.</p>
      <div className={styles.Heading}>
        6. Возврат заказа
      </div>
      <p>
        6.1. Покупатель вправе отказаться от заказанного Товара и получить за него возврат средств в течение 6 (шести) месяцев с даты оформления Заказа и до момента его исполнения.
      </p>
      <p>
        6.2. Предметы индивидуального пользования согласно Постановлению Правительства РФ №55 от 19.01.1998 попадают под категорию товаров надлежащего качества не подлежащих обмену и возврату.
      </p>
      <p>
        6.3. В соответствии со ст.22 Закона РФ №2300-I «О Защите прав потребителей», уплаченная Покупателем сумма за Товар ненадлежащего качества подлежит возврату Покупателю в течение 5 календарных дней с момента предъявления соответствующего требования.
      </p>
      <div className={styles.Heading}>7. Права и обязанности Сторон</div>
      <p>
        7.1. Продавец не несет ответственности за ненадлежащее использование товаров Покупателем, заказанных на интернет-сайте.
      </p>
      <p>
        7.2. Продавец вправе передавать свои права и обязанности по исполнению Заказов третьим лицам.
      </p>
      <p>
        7.3. Продавец имеет право на осуществление записи телефонных разговоров с Покупателем. В соответствии с п.4 ст.16 Федерального закона «Об информации, информационных технологиях и о защите информации» Продавец обязуется: предотвращать попытки несанкционированного доступа к информации и/или передачу ее лицам, не имеющим непосредственного отношения к исполнению Заказов; своевременно обнаруживать и пресекать такие факты. Телефонные разговоры записываются в целях осуществления контроля деятельности Оператора и контроля качества исполнения Заказов.
      </p>
      <p>
        7.4. Покупатель обязуется не использовать билеты и товары, заказанные на интернет-сайте, в коммерческих целях.
      </p>
      <p>
        7.5. Все претензии по ненадлежащему исполнению заказа Покупатель вправе направить Продавцу. Вся поступившая информация обрабатывается в кратчайшие сроки.
      </p>
      <p>
        7.6. Продавец вправе направлять Покупателю сообщения рекламно-информационного характера. При желании Покупатель может отказаться от получения таких сообщений.
      </p>
      <div className={styles.Heading}>8. Прочие положения</div>
      <p>
        8.1. К отношениям между Покупателем и Продавцом применяется право Российской Федерации.
      </p>
      <p>
        8.2. Покупатель гарантирует, что все условия настоящих Правил ему понятны, и он принимает их в полном объёме.
      </p>
      <div className={styles.Heading}>9. Реквизиты Продавца</div>
      <p>
        ООО «Артеком» ИНН/КПП 7814121367/784001001191186, г. Санкт-Петербург, Дворцовая пл., д. 6-8, литер А, ч.пом.1-Н, ч.п. 205р/сч 40702810755230003661 в Северо-Западном Банке ПАО «Сбербанк России» Санкт-Петербург БИК 044030653, к/сч 30101810500000000653 E-Mail:
        {' '}
        <a href='mailto: info@hermitageshop.com'>info@hermitageshop.com</a>
      </p>
    </div>
  );
};

export default Offer;