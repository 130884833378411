import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';

// Actions
import { deleteProductFromCart } from '../actions';

// Constants
import { USER_CART_COOKIE, USER_COOKIE } from '../constants';

// Selectors
import { selectAllProductsFromCart } from '../selectors';

const deleteProductFromCartMutation = loader('../graphql/deleteCartItem.graphql');

export const useDeleteProductFromCart = () => {
  const dispatch = useDispatch();
  const cart = useSelector(selectAllProductsFromCart);
  const user = Cookies.get(USER_COOKIE);
  const [deleteCartItem, { loading }] = useMutation(deleteProductFromCartMutation);

  useEffect(() => {
      localStorage.setItem(USER_CART_COOKIE, JSON.stringify(cart));
  }, [cart]);

  const deleteProductFromCartHandler = (productId, optionId) => {
    if (user) {
      if (optionId) {
        deleteCartItem({ 
          variables: { 
            productId: parseInt(productId),
            optionId: parseInt(optionId) || null
          } 
      }).then((res) => {
        if (res?.data?.deleteCartItem?.status) {
          dispatch(deleteProductFromCart(productId, optionId));
        }
      })
    } else {
      deleteCartItem({ 
        variables: { 
          productId: parseInt(productId)
        } 
      }).then((res) => {
          if (res?.data?.deleteCartItem?.status) {
            dispatch(deleteProductFromCart(productId, optionId));
          }
        })
      }
    } else {
      dispatch(deleteProductFromCart(productId, optionId));
    }
  };

  return { deleteProductFromCartHandler, loading };
};
