import { memo } from "react";
import RowItems from '../../../components/RowItems';
import useGetNews from "../hooks/useGetNews";
import styles from '../style.module.scss';

export const Events = memo(() => {
  const { news } = useGetNews();

  return (
    <div className={styles.Row}>
      <RowItems
        title="Новости и события"
        items={news}
        itemsType="events"
        classes={{ Button: styles.Button, Items: styles.Items }}
        showMore={false}
      />
    </div>
  );
});

Events.displayName = 'Events';
