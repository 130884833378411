export const resetPasswordValidation = (values) => {
  const errors = {};
  const required = 'Поле обязательно!';

  if (!values.token) {
    errors.customerNameAndLastName = required;
  }

  if (!values.password) {
    errors.password = required;
  }

  if (values.password?.length < 8) {
    errors.password = 'Пароль должен быть неменее 8 символов';
  }

  if (values.password !== values.password_confirmation) {
    errors.password_confirmation = 'Пароли не совпадают.';
  }

  return errors;
};
