// Libraries
import { useState } from 'react';

// Components
import Page from '../../components/Page';
import ProductsHeader from '../../components/ProductsHeader';
import ProductCard from '../../components/ProductCard';
import LoadContainer from '../../components/LoadContainer';

const Search = () => {
  const [setShowFilters] = useState(false);

  return (
    <Page title="Найдено 36 товаров">
      <div>
        <ProductsHeader handleClickFilters={() => setShowFilters(true)} />
        <LoadContainer component={(item) => <ProductCard item={item} category={item.productToCategories[0]} />} type="liked" />
      </div>
    </Page>
  );
};

export default Search;
