import { loader } from 'graphql.macro';
import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { addNewProduct } from '../../../actions';
import {selectProductFromProductsById} from "../../../selectors";

const query = loader('../graphql/getProduct.graphql');

export const useProduct = (id) => {
  const [productTags, setProductTags] = useState([]);
  const dispatch = useDispatch();
  const [product, setProduct] = useState(null);

  const onCompletedHandler = res => {
    if (res?.getProduct) {
      setProduct(res.getProduct);
      dispatch(addNewProduct({ ...res.getProduct }));
    }
  }

  const [getProduct, { loading }] = useLazyQuery(query, {
    fetchPolicy: 'no-cache',
    variables: { id },
    onCompleted: onCompletedHandler,
  });
  const productFromProducts = useSelector(selectProductFromProductsById(id));

  useEffect(() => {
    getProduct();
  }, [id])

  useEffect(() => {
    if (productFromProducts) {
      setProductTags(
        productFromProducts?.productTags?.map(({ tag }) => {
          return { value: tag, label: tag };
        }),
      );
    }
  }, [productFromProducts]);

  return {
    product,
    id: product?.id,
    name: product?.productDescriptions?.name,
    price: product?.price,
    description: product?.productDescriptions?.description,
    special_price: product?.special_price,
    photos: product?.photos ?? [],
    productImages: product?.productImages ?? [],
    productToCategories: product?.productToCategories ?? [],
    productOptionValues: product?.productOptionValues ?? [],
    categoryId:
      productFromProducts?.productToCategories.length > 0
        ? productFromProducts.productToCategories[0].id
        : 2,
    productTags,
    stockStatus: product?.stockStatus,
    specialOrder: product?.special_order,
    productLoading: loading,
    quantity: product?.quantity,
    characteristics: product?.characteristics,
    model: product?.model,
    related: product?.related || []
  };
};
