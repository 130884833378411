const SliderArrow = ({ width = 23, height = 19 }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="1"
      d="M0.988281 9.53906C0.988281 9.76172 1.08203 9.96094 1.25781 10.125L9.14453 18.0352C9.32031 18.1992 9.50781 18.2695 9.71875 18.2695C10.1641 18.2695 10.5039 17.9414 10.5039 17.5078C10.5039 17.2969 10.4219 17.0859 10.2812 16.9453L6.77734 13.4062L3.30859 10.1953L6.03906 10.3242H21.9766C22.4219 10.3242 22.75 9.99609 22.75 9.53906C22.75 9.08203 22.4219 8.75391 21.9766 8.75391H6.03906L3.32031 8.88281L6.77734 5.67188L10.2812 2.13281C10.4336 1.98047 10.5039 1.78125 10.5039 1.55859C10.5039 1.125 10.1641 0.796875 9.71875 0.796875C9.50781 0.796875 9.30859 0.878906 9.08594 1.10156L1.25781 8.95312C1.08203 9.11719 0.988281 9.31641 0.988281 9.53906Z"
      fill="#01030C"
    />
  </svg>
);

export default SliderArrow;
