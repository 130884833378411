import { RemoveScroll } from 'react-remove-scroll';
import { MainContent, MobileContent } from './components';
import { useResize } from '../../constants';

import styles from './style.module.scss';

const HeaderCatalog = ({ isShow, setHide }) => {
  const { isMobile } = useResize();

  return (
    isShow && (
      <RemoveScroll>
        <div className={styles.HeaderCatalog}>
          <div className={styles.Wrapper}>
            {isMobile ? <MobileContent setHide={setHide} /> : <MainContent setHide={setHide} />}
          </div>
        </div>
      </RemoveScroll>
    )
  );
};

export default HeaderCatalog;
