const Search = () => (
  <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.97949 13.8223C8.44727 13.8223 9.80078 13.3477 10.9082 12.5566L15.0742 16.7227C15.2676 16.916 15.5225 17.0127 15.7949 17.0127C16.3662 17.0127 16.7705 16.5732 16.7705 16.0107C16.7705 15.7471 16.6826 15.4922 16.4893 15.3076L12.3496 11.1592C13.2197 10.0166 13.7383 8.60156 13.7383 7.06348C13.7383 3.3457 10.6973 0.304688 6.97949 0.304688C3.27051 0.304688 0.220703 3.33691 0.220703 7.06348C0.220703 10.7812 3.26172 13.8223 6.97949 13.8223ZM6.97949 12.3633C4.0791 12.3633 1.67969 9.96387 1.67969 7.06348C1.67969 4.16309 4.0791 1.76367 6.97949 1.76367C9.87988 1.76367 12.2793 4.16309 12.2793 7.06348C12.2793 9.96387 9.87988 12.3633 6.97949 12.3633Z"
      fill={'black'}
    />
  </svg>
);

export default Search;
