// Libraries
import { useRef, useMemo, useState } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import Tippy from '@tippyjs/react/headless';

// Components
import { Input } from '../Input/Input';
import Filters from '../../components/Filters';

// Hooks
import { useGetProducts } from '../../hooks';

// Assets
import filterIcon from '../../assets/actions/filters.svg';
import searchIcon from '../../assets/actions/search.svg';
import SelectArrow from '../../assets/components/SelectArrow';
import { ReactComponent as ResetSearchResultsIcon } from '../../assets/reset_search.svg';

// Selectors
import { selectKeyword } from '../../selectors';

// Actions
import { setSortColumn, setSort, setKeyword as resetKeyword } from '../../actions';

// Styles
import styles from './style.module.scss';

const ProductsHeader = ({ 
  sortTypes, 
  selectedSortType, 
  handleChangeSelectSortType, 
  onGetProducts,
  count = 0, 
  keyword, 
  chosenFilters,
  categories,
  showFilters,
  handleGetProducts,
  handleChangeChosenFilters,
  handleChangeChosenFiltersIds,
  onResetFilters,
  onOpenFilters,
  onCloseFilters
}) => {
  const dispatch = useDispatch();
  const keywordFromStore = useSelector(selectKeyword);
  const { handlerGetProducts } = useGetProducts();
  const [showSelector, setShowSelector] = useState(false);
  const filterRef = useRef(null);
  
  const handlerSelectorClick = (sortType, keyword) => {
    return () => {
      handleChangeSelectSortType(sortType);
      dispatch(setSort(sortType?.value));
      dispatch(setSortColumn(sortType?.sort_column));
      setShowSelector(false);
      onGetProducts({ keyword, sortType });
    };
  };

  const sortTypesContent = useMemo(() => {
    return {
      // popularity: (
      //   <div
      //     className={styles.SortBy__DropDownItem}
      //     onClick={handlerSelectorClick(sortTypes.popularity)}
      //   >
      //     {sortTypes.popularity.label}
      //   </div>
      // ),
      desc: (
        <div
          className={styles.SortBy__DropDownItem}
          onClick={handlerSelectorClick(sortTypes.desc, keywordFromStore)}
        >
          {sortTypes.desc.label}
        </div>
      ),
      asc: (
        <div
          className={styles.SortBy__DropDownItem}
          onClick={handlerSelectorClick(sortTypes.asc, keywordFromStore)}
        >
          {sortTypes.asc.label}
        </div>
      ),
      without: (
        <div
          className={styles.SortBy__DropDownItem}
          onClick={handlerSelectorClick(sortTypes.without, keywordFromStore)}
        >
          {sortTypes.without.label}
        </div>
      ),
    };
  }, [keywordFromStore]);

  const onEnterPress = ({ keyword }) => {
    handlerGetProducts({ keyword });
  };

  const handlerClick = (keyword) => () => {
    handlerGetProducts({ keyword });
  };

  const handlerResetSearchResults = (reset) => () => {
    dispatch(resetKeyword(null));
    handlerGetProducts({ keyword: null });
    // reset();
  };

  const renderSortTypes = useMemo(() => {
    return Object.values(sortTypesContent).map((sortTypeContent) => sortTypeContent);
  }, [sortTypesContent]);

  return (
    <div className={styles.ProductsHeader}>
      {count === 0 ? (
        <div className={styles.Search}>
          <Form
            initialValues={{ keyword: keywordFromStore }}
            className={styles.SearchContainer}
            onSubmit={onEnterPress}
            render={({ handleSubmit, values, form }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Input name={'keyword'} placeholder="Поиск..." className={styles.Search__input} />
                  {values?.keyword && (
                    <span className={styles.SearchContainerCopyInput}>
                      {values?.keyword}
                      <ResetSearchResultsIcon onClick={handlerResetSearchResults(form.reset)} />
                    </span>
                  )}
                  <div className={styles.Search__Icon} onClick={handlerClick(values?.keyword)}>
                    <img src={searchIcon} alt="" />
                  </div>
                </form>
              );
            }}
          />
        </div>
      ) : (
        <div className={styles.Found}>
          {keyword ? 'Найдено' : ''} {count} {getNoun(count, ['товар', 'товара', 'товаров'])} {keyword ? `по запросу "${keyword}"` : ''}
        </div>
      )}
      <div className={styles.Actions}>
        <Tippy 
          visible={showFilters}
          render={() => (
                <Filters 
                  ref={filterRef}
                  handleGetProducts={onGetProducts}
                  chosenFilters={chosenFilters}
                  categories={categories}
                  isShow={showFilters}
                  onChangeChosenFilters={handleChangeChosenFilters}
                  onChangeChosenFiltersIds={handleChangeChosenFiltersIds}
                  onReset={onResetFilters}
                  onHide={onCloseFilters} 
                />
            )}
          placement="top-start"
          onClickOutside={showFilters ? onCloseFilters : onOpenFilters}
          interactive
          sticky
          >
            <div onClick={onOpenFilters} className={styles.Filters}>
              <div className={styles.Filters__Icon}>
                <img src={filterIcon} alt="" />
              </div>
              <div className={styles.Filters__Text}>Фильтры</div>
            </div>
        </Tippy>
        <div className={styles.SortBy}>
          <div className={styles.SortBy__Text}>Сортировать:</div>
          <div className={styles.SortBy__Selector} onClick={() => setShowSelector((prev) => !prev)}>
            <div className={styles.SortBy__Text}>{selectedSortType.label}</div>
            <div
              className={classnames(
                styles.SortBy__Icon,
                showSelector && styles.SortBy__Icon_reverse,
              )}
            >
              <SelectArrow />
            </div>
          </div>
          {showSelector && <div className={styles.SortBy__DropDown}>{renderSortTypes}</div>}
        </div>
      </div>
    </div>
  );
};

const getNoun = (value, words) => {  
	value = Math.abs(value) % 100; 
	let num = value % 10;
	if(value > 10 && value < 20) return words[2]; 
	if(num > 1 && num < 5) return words[1];
	if(num === 1) return words[0]; 
	return words[2];
}

export default ProductsHeader;
