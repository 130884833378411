// Components
import Page from '../../components/Page';
import Button from '../../components/Button';
import Link from '../../components/Link';

// Assets
import notFoundImage from '../../assets/404.png';

// Styles
import styles from './style.module.scss';

const NotFound = () => (
  <Page withFooter={false}>
    <div className={styles.NotFound}>
      <div className={styles.Image}>
        <img src={notFoundImage} />
      </div>
      <div className={styles.Text}>
        К сожалению, такой страницы пока не существует. {'\n'} Вернёмся на главную?
      </div>
      <Button filled link='/'>На главную</Button>
    </div>
  </Page>
);

export default NotFound;
