const Heart = ({ fill, isFilled }) =>
  isFilled ? (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 17.9434C10.1758 17.9434 10.4199 17.8359 10.6055 17.7285C15.9668 14.252 19.5215 10.2578 19.5215 6.18555C19.5215 2.8457 17.2168 0.462891 14.209 0.462891C12.373 0.462891 10.8398 1.49805 10 3.09961C9.16992 1.50781 7.62695 0.462891 5.80078 0.462891C2.7832 0.462891 0.488281 2.8457 0.488281 6.18555C0.488281 10.2578 4.04297 14.252 9.4043 17.7285C9.58008 17.8359 9.82422 17.9434 10 17.9434Z"
        fill={fill}
      />
    </svg>
  ) : (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.488281 6.18555C0.488281 10.2578 4.04297 14.252 9.4043 17.7285C9.58008 17.8359 9.82422 17.9434 10 17.9434C10.1758 17.9434 10.4199 17.8359 10.6055 17.7285C15.9668 14.252 19.5215 10.2578 19.5215 6.18555C19.5215 2.8457 17.2168 0.462891 14.1406 0.462891C12.373 0.462891 10.8398 1.37109 10 2.77734C9.16992 1.38086 7.62695 0.462891 5.85938 0.462891C2.7832 0.462891 0.488281 2.8457 0.488281 6.18555ZM1.68945 6.18555C1.68945 3.5 3.45703 1.67383 5.84961 1.67383C7.66602 1.67383 8.78906 2.79688 9.46289 3.91992C9.66797 4.24219 9.80469 4.33984 10 4.33984C10.1953 4.33984 10.3223 4.23242 10.5469 3.91992C11.2402 2.80664 12.3438 1.67383 14.1504 1.67383C16.543 1.67383 18.3105 3.5 18.3105 6.18555C18.3105 9.84766 14.4922 13.7148 10.1758 16.5957C10.0977 16.6445 10.0391 16.6836 10 16.6836C9.96094 16.6836 9.91211 16.6445 9.83398 16.5957C5.51758 13.7148 1.68945 9.84766 1.68945 6.18555Z"
        fill={fill}
      />
    </svg>
  );

export default Heart;
