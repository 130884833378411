// Components
import Link from '../../components/Link';

// Styles
import styles from './style.module.scss';


const EventCard = ({ data, addStyles }) => {
  const { title, description, url, image } = data;

  return (
      <a
          href={url}
          className={styles.EventCard}
          style={addStyles}
          target="_blank"
          rel="noopener noreferrer"
      >
          <div className={styles.Shadow} />
          <div className={styles.Image}>
              <img src={image} alt="" />
          </div>
          <div className={styles.Content}>
              <div className={styles.Row}>
                  <div>
                      {description}
                  </div>
              </div>
              <div className={styles.Description}>
                  {title}
              </div>
          </div>
      </a>
  );
};

export default EventCard;
