// Libraries
import classnames from 'classnames';

// Components
import { TextField as MaterialInput } from '@material-ui/core';

// Styles
import styles from './style.module.scss';

const Input = ({ className, ...props }) => (
  <MaterialInput {...props} className={classnames(styles.Input, className)} />
);

export default Input;
