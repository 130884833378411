
// Hooks
import { useFooterData } from '../../../hooks/useFooterData';

// Styles
import styles from './styles.module.scss';

export const ProcessPersonalData = () => {
  const { subInfoForBanners } = useFooterData();

  return (
    <>
      <div className={styles.Title}>Обработка персональных данных</div>
      <div dangerouslySetInnerHTML={{ __html: subInfoForBanners?.processing_personal_data }} />
    </>
  );
};
