import { useContext, useState } from 'react';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { BannerContext } from '../../../Page';
import { BANNER_VARIANTS } from '../../../../constants';

const mutation = loader('../graphql/updatePassword.graphql');

export const useUpdatePassword = () => {
  const setBannerData = useContext(BannerContext);

  const [error, setError] = useState(null);
  const [isSuccessChangePassword, setIsSuccessChangePassword] = useState(null);
  const [updatePassword] = useMutation(mutation, {
    onCompleted: (res) => {
      if (res?.updateCustomerPassword.status === 'false') {
        setError(res.updateCustomerPassword.message);
        setIsSuccessChangePassword(false);
      }

      if (res?.updateCustomerPassword.status === 'true') {
        setError(null);
        setIsSuccessChangePassword(true);
        setBannerData({ variant: BANNER_VARIANTS.change_password });
      }
    },
  });

  const handlerUpdatePassword = (values) => {
    updatePassword({ variables: { input: values } });
  };

  const removeError = () => {
    setError(null);
  };

  return {
    handlerUpdatePassword,
    removeError,
    error,
    isSuccessChangePassword,
  };
};
