import { useLazyQuery, useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { USER_COOKIE } from '../../../constants';
import { useSelector } from "react-redux";

const query = loader('../graphql/loginCustomer.graphql');
const mutation = loader('../../../graphql/addProductToCart.graphql');

export const useLoginCustomer = () => {
  const [error, setError] = useState(null);
  const history = useHistory();
  const [addProductToCart] = useMutation(mutation);
  const { cart } = useSelector(store => store?.productsReducer)

  const [loginCustomer, { data, loading }] = useLazyQuery(query, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (res) => {
      if (res?.loginCustomer.access_token) {
        if (cart && cart.length > 0) {
          cart.forEach(product => {
            addProductToCart({ variables: {
              productId: +product.id,
              ...(product?.option_value_id && { option_value_id: +product.option_value_id }),
              quantity: product.quantity_selected,
            }});
          });
        }

        setError(null);
        Cookies.set(USER_COOKIE, JSON.stringify(res.loginCustomer), { expires: 365 });
        history.push('/lk');
      } else {
        setError(data.loginCustomer.message);
      }
    }
  });

  useEffect(() => {
    if (data?.loginCustomer && data.loginCustomer.access_token) {
      setError(null);
      Cookies.set(USER_COOKIE, JSON.stringify(data.loginCustomer), { expires: 365 });
    }
  }, [data]);

  const handlerLoginUser = (values) => {
    loginCustomer({ variables: { email: values.email, password: values.password } });
  };

  const removeError = () => {
    setError(null);
  };

  return {
    handlerLoginUser,
    error,
    removeError,
    loading
  };
};
