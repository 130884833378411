// Libraries
import Cookies from 'js-cookie';

// Components
import { Checkbox } from '../../../components/Checkbox/Checkbox';
import Link from '../../../components/Link';

// Constants
import {BANNER_VARIANTS} from '../../../constants';

import styles from './Confirmations.module.scss';
import {useContext} from "react";
import {BannerContext} from "../../../components/Page";

export const Confirmations = () => {
  const setBannerData = useContext(BannerContext);

  return (
    <div className={styles.Confirmations}>
      <Checkbox
        name={'agree'}
        label={
          <>
            <span>Я прочел/ла условия &nbsp;</span>
            <span onClick={() => setBannerData({ variant: BANNER_VARIANTS.process_personal_data })}>
              <Link alwaysLighting>
                Обработки персональных данных
              </Link>
            </span>
          </>
        }
      />
      <Checkbox
        name={'get_spam'}
        label="Получать красивую рассылку о новых поступлениях и скидках"
      />
    </div>
  );
};
