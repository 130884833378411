// Libraries
import classnames from 'classnames';
import { Field } from 'react-final-form';
import { useCallback } from 'react';

// Components
import { RadioGroup as RadioGroupMaterial, FormControlLabel, Radio } from '@material-ui/core';

// Styles
import styles from './style.module.scss';

export const RadioGroup = ({ label, data, classes, ...props }) => {
  const renderLabels = useCallback((value, label) => {
    return (
      <FormControlLabel
        key={value}
        value={value}
        label={label}
        control={<Radio />}
        className={classnames(classes?.Item)}
      />
    );
  }, []);

  return (
    <Field type={'radio'} {...props}>
      {({ input, meta }) => {
        return (
          <div className={styles.RadioGroup}>
            <div className={styles.Label}>{label}</div>
            <RadioGroupMaterial
              {...input}
              className={classnames(styles.Items, classes?.Items)}
              onChange={(e) => input.onChange(e.target.value)}
            >
              {data.map(({ value, label }) => {
                return renderLabels(value, label);
              })}
            </RadioGroupMaterial>
            {meta.error && meta.submitting && <span className={styles.Error}>{meta.error}</span>}
          </div>
        );
      }}
    </Field>
  );
};
