const Plus = () => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.11816 8.25195H7.04199V14.1758C7.04199 14.501 7.31445 14.7734 7.63965 14.7734C7.96484 14.7734 8.2373 14.501 8.2373 14.1758V8.25195H14.1699C14.4863 8.25195 14.7588 7.97949 14.7588 7.6543C14.7588 7.3291 14.4863 7.05664 14.1699 7.05664H8.2373V1.12402C8.2373 0.807617 7.96484 0.535156 7.63965 0.535156C7.31445 0.535156 7.04199 0.807617 7.04199 1.12402V7.05664H1.11816C0.792969 7.05664 0.520508 7.3291 0.520508 7.6543C0.520508 7.97949 0.792969 8.25195 1.11816 8.25195Z"
      fill="#01030C"
    />
  </svg>
);

export default Plus;
