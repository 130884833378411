// Libraries
import { useState } from 'react';
import classnames from 'classnames';
import { Field } from 'react-final-form';
import RSelect from 'react-select'

// Assets
import SelectArrow from '../../assets/components/SelectArrow';

// Styles
import styles from './style.module.scss';
import './react-select.scss';

const ReactSelectAdapter = ({ input, ...rest }) => {
	return <RSelect {...input} {...rest} classNamePrefix={'react-select'} searchable />;
}

export const ReactSelect = ({ name, placeholder, options }) => {
	return <Field name={name} placeholder={placeholder} options={options} component={ReactSelectAdapter} />
};

const Select = ({
	value = null,
	onChange = () => {},
	items = [],
	placeholder = 'Выберите...',
}) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<div
			className={classnames(styles.Select, isOpen && styles.Select_opened)}
			onClick={() => setIsOpen((prev) => !prev)}
		>
			<div className={styles.SelectedItem}>
				<div className={styles.SelectedValue}>
					{items.find((item) => item.value === value)?.label || placeholder}
				</div>
				<div className={styles.Arrow}>
					<SelectArrow rotated={isOpen} />
				</div>
			</div>
			<div className={styles.Items}>
				{items.map(({ label, value }) => (
					<div key={label} className={styles.Item} onClick={() => onChange({ label, value })}>
						{label}
					</div>
				))}
			</div>
		</div>
	);
};

export default Select;
