// Libraries
import { useContext } from 'react';

// Constants
import { BANNER_VARIANTS } from '../../constants';

// Contexts
import { BannerContext } from '../Page';

// Styles
import styles from './style.module.scss';
import { formatPrice } from '../../helpers/formatPrice';

const Excursion = ({ data }) => {
  const setBannerData = useContext(BannerContext);

  const { image, title, price, dateOfStart, dateOfEnd } = data;

  const handleShopBanner = () =>
    setBannerData({
      variant: BANNER_VARIANTS.excursion_item,
      data,
    });

  return (
    <div className={styles.Excursion}>
      <div className={styles.Image} onClick={handleShopBanner}>
        <img src={image} />
      </div>
      <div className={styles.Name}>{title}</div>
      <div className={styles.Row}>
        <div className={styles.Price}>
          {formatPrice(price)}
        </div>
      </div>
      <div className={styles.SelectTime} onClick={handleShopBanner}>
        Купить
      </div>
    </div>
  );
};

export default Excursion;
