import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { format } from 'date-fns';

const mutation = loader('../graphql/setCustomer.graphql');

export const useSetCustomer = () => {
  const [validationErrors, setValidationErrors] = useState([]);
  const [setCustomer, { data }] = useMutation(mutation, {
    onCompleted: (res) => {
      if (res?.setCustomer) {
        setError(null);
        setValidationErrors(null);
      } else {
        setError(data?.setCustomer.message);
      }
    },
    onError: (err) => {
      setValidationErrors(err.graphQLErrors[0]?.extensions.validation);
    }
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    if (data?.setCustomer) {
      setError(null);
      setValidationErrors(null);
    }
  }, [data]);

  const handlerSetCustom = (values) => {
    let firstname = '',
      lastname = '';

    if (values.name) {
      const array = values.name.split(' ');
      firstname = array[0];
      lastname = array[1];
    }

    const updateCustomer = {
      firstname,
      lastname,
      address: values.address,
      birth_day: format(new Date(values.birth_day), 'yyyy-MM-dd'),
      pol: values.pol === 'man' ? 0 : 1,
      email: values.email,
    };

    setCustomer({ variables: { input: updateCustomer } });
  };

  const removeError = () => {
    setError(null);
    setValidationErrors(null);
  };

  return {
    handlerSetCustom,
    error,
    removeError,
    validationErrors
  };
};
