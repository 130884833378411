/////////// Libraries
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';

// Components
import ProfileIcon from '../../../assets/components/Profile';
import DropdownList from '../../DropdownList';
import { SearchMobile } from '../../Search';

// Constants
import { USER_COOKIE } from '../../../constants';

// Actions
import { setFilterGroups, setKeyword } from '../../../actions';

// Hooks
import { useCategories } from '../../../hooks';
import { useActiveCategory } from '../hooks';

// Styles
import styles from './MobileContent.module.scss';

export const MobileContent = ({ setHide }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userCookie = Cookies.get(USER_COOKIE);
  const { selectCategories } = useActiveCategory();
  const { 
    specialOffersCategories, 
    catalogCategories, 
    subCategories,
    categories
  } = useCategories();

  const onClickToCategory = (item) => () => {
    if (item) {
      const category = categories.find(({ children }) => children.find(({ id }) => item.id === id));
      if (subCategories?.length) {
        selectCategories(category)();
      }
      dispatch(setFilterGroups(category?.filterGroup?.map(item => parseInt(item.id))));
      dispatch(setKeyword(null));
      setHide();
      history.push(`/catalog/${category.seo_url || category.name}/${item.seo_url || item.name}`.toLowerCase().replace(/ /g, '_'));
    }
  };

  const renderChildren = useCallback(
    (children) => {
      return children?.map((item) => {
        return (
          <div
            onClick={onClickToCategory(item)}
            key={item.name}
            className={styles.MobileContent__Link}
          >
            {item.name}
          </div>
        );
      });
    },
    [],
  );

  const getListData = useCallback(
    (items) => {
      return items.map(({ id, name, sticker, children }) => {
        return {
          id,
          title: name,
          icon: sticker && <img src={sticker} alt="" />,
          content: renderChildren(children.map((c) => ({ ...c, categoryName: name }))),
        };
      });
    },
    [renderChildren],
  );

  const renderSignIn = () => {
    return (
      <div className={styles.MobileContent__SignIn}>
        <Link to="/auth" className={styles.MobileContent__SignInLink} onClick={setHide}>
          <ProfileIcon />
          <div className={styles.MobileContent__SignInText}>Вход в аккаунт</div>
        </Link>
      </div>
    );
  };

  const renderPersonalArea = () => {
    return (
      <div className={styles.MobileContent__SignIn}>
        <Link to="/lk" className={styles.MobileContent__SignInLink} onClick={setHide}>
          <ProfileIcon />
          <div className={styles.MobileContent__SignInText}>Личный кабинет</div>
        </Link>
      </div>
    );
  };

  return (
    <div className={styles.MobileContent}>
      {!userCookie ? renderSignIn() : renderPersonalArea()}
      <SearchMobile setHide={setHide}/>
      <div className={styles.MobileContent__Title}>
        Спецпредложения
      </div>
      <div className={styles.MobileContent__Container}>
        <DropdownList items={getListData(specialOffersCategories)} compact setHide={setHide}/>
      </div>
      <div className={styles.MobileContent__Title}>
        Каталог
      </div>
      <div className={styles.MobileContent__Container}>
        <DropdownList items={getListData(catalogCategories)} compact setHide={setHide}/>
      </div>
    </div>
  );
};
