// Libraries
import { useContext } from 'react';

// Constants
import { BANNER_VARIANTS } from '../../constants';

// Contexts
import { BannerContext } from '../Page';

// Styles
import styles from './style.module.scss';
import { formatPrice } from '../../helpers/formatPrice';

const Ticket = ({ data }) => {
  const setBannerData = useContext(BannerContext);

  const { imgSrc, name, price, dateOfStart, dateOfEnd } = data;

  const handleShopBanner = () =>
    setBannerData({
      variant: BANNER_VARIANTS.museum_item,
      data,
      // data: '123'
    });

  return (
    <div className={styles.Ticket}>
      <div className={styles.Image} onClick={handleShopBanner}>
        <img src={imgSrc} />
      </div>
      <div className={styles.Name}>{name}</div>
      <div className={styles.Row}>
        <div className={styles.Price}>
          {formatPrice(price)}
        </div>
      </div>
      <div className={styles.SelectTime} onClick={handleShopBanner}>
        Выбрать время
      </div>
    </div>
  );
};

export default Ticket;
