import { memo } from 'react';
import logoIcon from '../../assets/logo.svg';
import styles from './style.module.scss';

const Support = memo(() => (
  <div className={styles.Support}>
    <div className={styles.ImageContainer}>
      <img src={logoIcon} />
    </div>
    <div className={styles.Text}>Каждая покупка поддерживает Государственный Эрмитаж</div>
  </div>
));

Support.displayName = 'Support';

export default Support;
