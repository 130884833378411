import { loader } from 'graphql.macro';
import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectOnlyNoveltiesCategory } from '../selectors';
import {addNewProduct} from "../actions";

const query = loader('../graphql/getProducts.graphql');

export const useGetOnlyNoveltiesProducts = () => {
  const dispatch = useDispatch();
  const novelties = useSelector(selectOnlyNoveltiesCategory);
  const [getProducts, { data, loading }] = useLazyQuery(query, {
    fetchPolicy: 'no-cache',
    onCompleted: (res) => {
      if (res?.getProducts) {
        for (const product of res.getProducts.products) {
          dispatch(addNewProduct(product));
        }
      }
    }
  });

  useEffect(() => {
    if (novelties?.id) {
      getProducts({ variables: { category: novelties.id, paginate: 16, page: 1 } });
    }
  }, [novelties]);

  return {
    noveltiesProducts: data?.getProducts.products ?? [],
    loading
  };
};
