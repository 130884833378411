/* eslint-disable no-mixed-spaces-and-tabs */
// Libraries
import { useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { Form } from 'react-final-form';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

// Helpers
import { productValidation } from './helpers/productValidation';
import { isEmptyObject } from '../../helpers';

// Selector
import { selectAllCategories } from '../../selectors';

// Actions 
import { setFilterGroups, setKeyword } from '../../actions'

// Components
import Page from '../../components/Page';
import Breadcrumbs from '../../components/Breadcrumbs';
import RowItems from '../../components/RowItems';
import DropdownList from '../../components/DropdownList';
import Select from '../../components/Select';
import Tags from '../../components/Tags';
import Button from '../../components/Button';
import PrevArrow from '../../components/Arrows/PrevArrow';
import NextArrow from '../../components/Arrows/NextArrow';
import { Price } from '../../components/Price';
import Modal from '../../components/Modal';
import { Input } from '../../components/Input/Input'

// Hooks
import {
    useFavorites,
    useCart,
    useIsProductInCart,
    useAddProductToCart,
    useSpecialOrder, useCategories,
} from '../../hooks';
import { useResize } from '../../constants';
import { useProduct } from './hooks';

// Assets
import HeartIcon from '../../assets/components/Heart';

// Styles
import styles from './style.module.scss';
import { Loader } from '../../components/Loader';
import Stat from './components/Stat';
import Counter from '../../components/Counter';
import SubCategories from '../../components/SubCategories';


const Product = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams();
    const {
        product,
        productImages,
        productTags,
        description,
        name,
        price,
        photos,
        special_price,
        quantity: productQuantity,
        productLoading,
        stockStatus,
        productToCategories,
        productOptionValues,
        specialOrder,
        characteristics,
        model,
        related
    } = useProduct(id);
    const { handlerFavorites, isFavorites, getFavorites, favoritesLoading } = useFavorites(id);
    const { cartLoading } = useCart();
    const { addProductToCartHandler, addProductToCartLoading } = useAddProductToCart(id);
    const { specialOrderHandler } = useSpecialOrder();
    const [modalOpen, setModalOpen] = useState(false);
    const location = useLocation()
    const { categories } = useCategories();
    const subCategoryName =
        new URLSearchParams(location.search).get('subCategory')
    ;
    const blockedStockStatus = ['нет в наличии', 'ожидание 2-3 дня', 'предзаказ']
      .includes(stockStatus?.name && stockStatus?.name.toLowerCase()) || !stockStatus;

    const findSubCategory = children => children.find(({ seo_url, name }) =>
        [
            seo_url ? seo_url.toLowerCase().replace(/ /g, '_') : '',
            name ? name.toLowerCase().replace(/ /g, '_') : ''
        ].includes(subCategoryName)
    );

    const currentCategory =
        categories.find(({ children }) => findSubCategory(children))
        || productToCategories.find(({ parent_id }) => parent_id === '0');

    const subCategory = currentCategory
        ? findSubCategory(currentCategory.children) || productToCategories.find(({ parent_id }) => parent_id === currentCategory.id)
        : null;

    const [option, setOption] = useState(productOptionValues.length > 0 ? productOptionValues[0] : null);
    const quantity = useMemo(() => option?.quantity || productQuantity, [product, option])
    const { product: cartProduct, isProductInCart } = useIsProductInCart(id, option?.id);
    const [sourceForDownSlider, setSourceForDownSlider] = useState([]);

    useEffect(() => {
        if(productOptionValues.length) {
            setOption(productOptionValues[0]);
        }
    }, [productOptionValues]);

    const handleToggleModalOpen = () => {
        setModalOpen(!modalOpen);
    };

    const onSubmitSpecialOrder = (data) => {
        specialOrderHandler({ ...data, type: 2, id });
        handleToggleModalOpen();
    };

    useEffect(() => {
        getFavorites();
    }, []);

    const handleAddToCart = () => {
        if (option) {
            addProductToCartHandler({
                productId: parseInt(id),
                option_value_id: parseInt(option?.id),
                price: option?.price || price,
                quantity_selected: 1,
                quantity: 1,
                special_price: option?.special_price || special_price,
                stockStatus,
                image: option.image
            })
        } else {
            addProductToCartHandler({
                productId: parseInt(id),
                price,
                quantity: 1,
                quantity_selected: 1,
                special_price,
                stockStatus,
            })
        }
    };

    const Stats = () => characteristics?.length > 0
        ? (
            <div className={styles.Stats}>
                {characteristics.map(({ type, value }) => type && value && <Stat type={type} value={value} />)}
                {model && <Stat type='Артикул' value={model} />}
            </div>
        )
        : <div>Характеристики отсутствуют</div>;

    const [selectedSlide, setSelectedSlide] = useState(sourceForDownSlider[0]);

    useEffect(() => {
        if (option) {
            const slides = productImages && productImages.length > 0
                ? productImages.map((item, index) => index === 0 ? option.image : item)
                : photos.map((item, index) => index === 0 ? option.image : item);
            setSourceForDownSlider(slides);
            setSelectedSlide(slides[0])
        } else {
            const slides = productImages && productImages.length > 0
                ? productImages[0]
                : photos;
            setSourceForDownSlider(slides);
            setSelectedSlide(slides[0])
        }
    }, [option, photos, productImages]);


    const { isLargeMobile } = useResize();

    //todo: merge states in future

    const indexOfSelectedSlide = photos.findIndex((slide) => slide === selectedSlide);

    const handlerSelectImg = (img) => () => {
        setSelectedSlide(img);
    };

    const AddProductToCartButton = () => {
        if (isProductInCart) {
            return (
                <Button
                    className={addProductToCartLoading && styles.CartButtonLoading}
                    link='/basket'
                    filled
                >
                    {addProductToCartLoading
                        ? <Loader style={{width: 25, height: 25}} />
                        : 'Оформить заказ'
                    }
                </Button>
            );
        } else {
            const disabled = (blockedStockStatus && (id && quantity <= 0 && productOptionValues.length === 0)) || option?.quantity <= 0;

            return (
                <Button
                    className={addProductToCartLoading && styles.CartButtonLoading}
                    onClick={handleAddToCart}
                    disabled={addProductToCartLoading || disabled}
                    filled
                >
                    {addProductToCartLoading
                        ? <Loader style={{width: 25, height: 25}} />
                        : disabled
                            ? 'Нет в наличии'
                            : 'Добавить в корзину'
                    }
                </Button>
            );
        }
    }

    const onChangeCategory = ({ categoryName, subcategoryName }) => {
        const { seo_url, filterGroup, children } = categories.find((c) =>
            (c?.seo_url && c.seo_url.toLowerCase().replace(/ /g, '_') === categoryName.toLowerCase().replace(/ /g, '_'))
            || c.name.toLowerCase().replace(/ /g, '_') === categoryName.toLowerCase().replace(/ /g, '_'));

        dispatch(setKeyword(null));
        dispatch(setFilterGroups(filterGroup?.map(item => parseInt(item.id))));
        if (subcategoryName) {
            history.push(`/catalog/${(seo_url || categoryName)}/${subcategoryName}`.toLowerCase().replace(/ /g, '_'));
        } else {
            history.push(`/catalog/${(seo_url || categoryName)}/${children[0]?.name}`.toLowerCase().replace(/ /g, '_'));
        }
    };

    const FavoritesButton = useCallback(() => {
        const HeartIconStyled = () => (
            <HeartIcon
                fill={isFavorites ? '#CF086E' : '#01030C'}
                isFilled={isFavorites}
            />
        );

        const buttonProps = {
            onClick: handlerFavorites(id),
        }

        const Content = () => favoritesLoading
            ? <Loader style={{width: '25px', height: '25px'}} />
            : <HeartIconStyled />;

        const TabletComponent = () => (
            <Button
                {...buttonProps}
                className={styles.LikeTablet}
                contentClassName={styles.LikeTablet__Content}
                disabled={favoritesLoading}
            >
                <div className={styles.LikeTablet__Heart}>
                    <Content />
                </div>
                {isFavorites ? "В избранном" : "Добавить в избранное"}
            </Button>
        );

        const DefaultComponent = () => (
            <button
                {...buttonProps}
                className={styles.Like}
                disabled={favoritesLoading}
            >
                <Content />
            </button>
        );

        return isLargeMobile ? <TabletComponent /> : <DefaultComponent />
    }, [id, isLargeMobile, isFavorites, favoritesLoading]);

    const BREADCRUMBS = [
        {
            title: 'Главная',
            to: '/',
        },
        {
            title: 'Каталог товаров',
            to: '/catalog',
        },
        {
            title: currentCategory?.name,
            onClick: () => onChangeCategory({ categoryName: currentCategory?.name })
        },
        {
            title: subCategory?.name,
            onClick: () => onChangeCategory({
                categoryName: currentCategory?.name,
                subcategoryName: subCategory?.seo_url || subCategory?.name
            })
        },
        {
            title: name,
            to: `/product/${id}`
        }
    ];

    const handlerPrevArrow = () => {
        indexOfSelectedSlide !== 0 && setSelectedSlide(() => photos[indexOfSelectedSlide - 1]);
    };

    const handlerNextArrow = () => {
        indexOfSelectedSlide !== photos.length - 1 &&
        setSelectedSlide(() => photos[indexOfSelectedSlide + 1]);
    };

    return (
        <Page>
            <Modal open={modalOpen} onClose={handleToggleModalOpen}>
                <Form
                    onSubmit={onSubmitSpecialOrder}
                    validate={productValidation}
                    render={({ handleSubmit, submitting, pristine, errors }) => {
                        return (
                            <form onSubmit={handleSubmit} className={styles.SpeacialOrderContainer}>
                                <div className={styles.SpeacialOrderContent}>
                                    <div className={styles.SpeacialOrderTitle}>Спецзаказ</div>
                                    <div className={styles.SpeacialOrderDescription}>
                                        Dictum porta eget ac congue vel fames viverra nibh feugiat sit amet
                                    </div>
                                    <Input
                                        name="title"
                                        className={styles.SpeacialOrderInput}
                                        placeholder="Введите названия интересующих вас товаров"
                                    />
                                    <Input
                                        name="message"
                                        className={styles.SpeacialOrderInput}
                                        placeholder="Ваше сообщение"
                                    />
                                    <Input
                                        name="email"
                                        className={styles.SpeacialOrderInput}
                                        placeholder="Ваш e-mail"
                                    />
                                    <Input
                                        name="phone"
                                        className={styles.SpeacialOrderInput}
                                        placeholder="Ваш телефон"
                                    />
                                    <Button
                                        className={styles.SpeacialOrderSubmit}
                                        filled
                                        disabled={submitting || pristine || !isEmptyObject(errors)}
                                    >
                                        Отправить запрос
                                    </Button>
                                </div>
                            </form>
                        );
                    }}
                />
            </Modal>
            <Breadcrumbs data={BREADCRUMBS} />
            <div className={styles.Row}>
                <div className={styles.Slider}>
                    <div className={styles.Slider__Selected}>
                        <img src={selectedSlide} alt="" />
                        {photos.length > 1 && (
                            <div className={styles.Slider__Nav}>
                                <PrevArrow
                                    arrowClassName={styles.Slider__PrevArrow}
                                    className={classNames({
                                        'slick-disabled': indexOfSelectedSlide === -1 || indexOfSelectedSlide === 0
                                    })}
                                    onClick={handlerPrevArrow}
                                />
                                <NextArrow
                                    arrowClassName={styles.Slider__NextArrow}
                                    className={classNames({
                                        'slick-disabled': indexOfSelectedSlide === photos.length - 1
                                    })}
                                    onClick={handlerNextArrow}
                                />
                            </div>
                        )}
                    </div>
                    {sourceForDownSlider && (
                        <div className={styles.Slider__Row}>
                            {Array.isArray(sourceForDownSlider) && sourceForDownSlider.map(img => (
                                <div
                                    key={img}
                                    className={classnames(
                                        styles.Slider__Slide,
                                        img === selectedSlide && styles.Slider__Slide_selected
                                    )}
                                    onClick={handlerSelectImg(img)}
                                >
                                    <img src={img} alt="" />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                {!productLoading && product && (
                    <div className={styles.Product}>
                        <div className={styles.Title}>{name}</div>
                        <Price
                            {...{
                                price: option?.price || price,
                                special_price: option?.special_price || special_price,
                                quantity: quantity
                            }}
                            className={styles.Price}
                        />
                        {/* isProductInCart && cartProduct && (
                            <Counter
                                limits={{
                                    min: (option?.quantity || product?.quantity) === 0 ? 0 : 1,
                                    max: (option?.quantity || product?.quantity) === 0 ? 0 : quantity,
                                }}
                                product={cartProduct}
                                showPrice={false}
                            />
                        ) */}
                        {productOptionValues.length > 0 && option && (
                            <div className={styles.Select}>
                                <Select
                                    value={option.id}
                                    placeholder="Выберите опции"
                                    items={productOptionValues.map(({ id, name }) => ({ label: name, value: id }))}
                                    onChange={({ value }) => setOption(productOptionValues.find(({ id }) => id === value))}
                                />
                            </div>
                        )}
                        <div className={styles.Actions}>
                            {!cartLoading && <AddProductToCartButton />}
                            {specialOrder && (
                                <Button className={styles.SpecialOrder} onClick={handleToggleModalOpen}>
                                    Спецзаказ
                                </Button>
                            )}
                            {!favoritesLoading && <FavoritesButton />}
                        </div>
                        <DropdownList
                            defaultOpenedItems={[]}
                            items={[
                                {
                                    title: 'Описание',
                                    content: <div className={styles.Description}>{description}</div>,
                                },
                                {
                                    title: 'Характеристики',
                                    content: <Stats />,
                                },
                            ]}
                            className={styles.Dropdown}
                        />
                        <Tags items={productTags} />
                        <SubCategories onChangeCategory={onChangeCategory} categories={categories} items={productToCategories} />
                    </div>
                )}
            </div>
            <RowItems
                items={related}
                title="Вам может понравиться"
                showMore={false}
            />
        </Page>
    );
};

export default Product;
