import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useSelector } from 'react-redux';
import { selectOnlyHistCategory, selectOnlyNoveltiesCategory } from '../../../selectors';

const query = loader('../../../graphql/getCategories.graphql');

export const useGetCategories = () => {
  // const dispatch = useDispatch();
  const hitsCategory = useSelector(selectOnlyHistCategory);
  const novelties = useSelector(selectOnlyNoveltiesCategory);
  const { loading } = useQuery(query, {
    onCompleted: (res) => {
      if (res?.Categories) {
        // dispatch(setCategories(res.Categories));
      }
    },
  });

  return { hitsCategory, novelties, loading };
};
