
const IN_PROGRESS_STATUSES = [
  'В обработке',
  'Новый',
  'Ожидание',
  'Оплачено',
  'Отгружен',
  'Полностью измененный',
  'Предварительный',
  'Собран'
];

const FINISH_STATUSES = [
  'Доставлено',
  'Сделка завершена'
];

const CANCEL_STATUSES = [
  'Возврат',
  'Возмещенный',
  'Отмена и аннулирование',
  'Отменено',
  'Полный возврат',
];

const STATUSES = {
  inProgress: IN_PROGRESS_STATUSES,
  finish: FINISH_STATUSES,
  cancel: CANCEL_STATUSES
};


export const convertOrderStatus = (status) => {
  const foundStatusCategory = Object.entries(STATUSES)
    .find(([statusCategory, statuses]) => statuses.includes(status));

  if (!foundStatusCategory) {
    return `Статус неизвестен, предположительный: ${status || 'не найден'}`
  }

  const [category] = foundStatusCategory;

  return category;
};
