import { useRef, useState } from "react";
import { URI } from "../../constants";

export const useCDEK = () => {
  const [pvz, setPvz] = useState(null);
  const cdekRef = useRef();

  // eslint-disable-next-line no-undef
  const [CDEK] = useState(() => new ISDEKWidjet({
    defaultCity: 'Санкт-Петербург', // какой город отображается по умолчанию
    cityFrom: 'Санкт-Петербург', // из какого города будет идти доставка
    country: 'Россия', // можно выбрать страну, для которой отображать список ПВЗ
    link: 'forpvz', // id элемента страницы, в который будет вписан виджет
    path: 'https://widget.cdek.ru/widget/scripts/', // директория с библиотеками
    servicepath: `${URI}/api/sdek_service`, // ссылка на файл service.php на вашем сайте
    hidedelt: true,
    choose: true,
    onReady: () => {
      const el = document.querySelector('div[data-mtype="cash"]');
      const elDress = document.querySelector('div[data-mtype="dress"]');
      el.style.display = 'none';
      elDress.style.display = 'none';
    },
    onChoose: (wat) => {
      if (cdekRef.current) {
        const burger = cdekRef.current.querySelector('.CDEK-widget__sidebar-burger');
        burger.click();
        setPvz(wat);
      }
    },
  }));

  return {
    CDEK,
    pvz,
    cdekRef,
  };
}; 