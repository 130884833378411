import { useState } from "react";

const DEFAULT_DATA = {
  open: false,
  severity: '',
  message: ''
};

const useSnackBar = () => {
  const [data, setData] = useState(DEFAULT_DATA);

  const handleChange = ({ open, severity, message }) => setData({
    open,
    severity,
    message
  });

  const handleClose = () => setData(DEFAULT_DATA);

  return {
    ...data,
    handleChange,
    handleClose
  }
};

export default useSnackBar;