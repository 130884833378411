// Libraries
import { memo } from 'react';

// Styles
import styles from './style.module.scss';
import { formatPrice } from '../../helpers/formatPrice';


const PriceMemo = ({ price, special_price, quantity, template }) => {
  const discount = Math.ceil((price - special_price) / price * 100);
  const origin_price = Number(price);
  const discount_price = Number(special_price);

  const formattedDiscount = discount === 100 ? 99 : discount;

  if (special_price && discount_price > 0) {
    return (
      <div className={styles.Container}>
        <div>
          <span className={styles.CurrentPrice}>
            {formatPrice(discount_price)}
          </span>
          <span className={styles.OldPrice}>
            {formatPrice(origin_price)}
          </span>
        </div>
        <div className={styles.Stickers}>
          {parseInt(formattedDiscount) && (
            <div className={styles.Discount}>
              -{formattedDiscount}%
            </div>
          )}
          {quantity === 1 && <div className={styles.Last}>Последний</div>}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.Container}>
      <div className={styles.CurrentPrice}>
        {origin_price !== 0.00
          ? template
            ? template
              .replace('{price}', formatPrice(origin_price))
            : formatPrice(origin_price)
          : (
            <span className={styles.CurrentPrice_unknown}>
              Цена неизвестна
            </span>
          )
        }
      </div>
      <div className={styles.Stickers}>
        {quantity === 1 && <div className={styles.Last}>Последний</div>}
      </div>
    </div>
  )
};

export const Price = memo(PriceMemo);
