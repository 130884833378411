import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';

const query = loader('../graphql/getUserInfo.graphql');

export const useGetUserInfo = () => {
  const { data, loading } = useQuery(query);

  const getUserName = () => {
    if (data?.getCustomer.firstname && data?.getCustomer.lastname) {
      return `${data?.getCustomer.firstname} ${data?.getCustomer.lastname}`;
    }

    return '';
  };

  return {
    name: getUserName(),
    email: data?.getCustomer.email ?? null,
    phone: data?.getCustomer.telephone ?? null,
    loading
  };
};
