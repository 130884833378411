// Libraries
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import scriptjs from 'scriptjs';

// Constants
import { URI } from '../../../../constants';

// Assets
import { ReactComponent as VkIcon } from '../../../../assets/socials_for_auth/vk.svg';
import { ReactComponent as GoogleIcon } from '../../../../assets/socials_for_auth/google.svg';
import { ReactComponent as AppleIcon } from '../../../../assets/socials_for_auth/apple.svg';

// Styles
import styles from '../../style.module.scss';

const SocialLinks = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    scriptjs.get('https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js', () => {
      const params = {
        clientId: 'develop.hermitageshop.ru.service',
        redirectURI: 'https://develop.hermitageshop.ru/api/auth/apple/login',
        scope: 'name email',
        state: 'q8UzhBd5FuSRJfwdI7W6z4jEOTqlZ7ydflktFufe',
        nonce: '4660a5e7-d8e4-4c16-a4dc-0b4ae7af7ebb.q8UzhBd5FuSRJfwdI7W6z4jEOTqlZ7ydflktFufe'
      };
      window.AppleID.auth.init(params);
    });
  }, [pathname]);

  const handleAuth = social_id => {
    window.location.href = `${URI}/api/auth/${social_id}/redirect`;
  };

  return (
    <div className={styles.SocialLinks}>
      <div
        onClick={() => handleAuth('vk')}
        className={styles.SocialLink}
      >
        <VkIcon />
      </div>
      <div
        onClick={() => handleAuth('google')}
        className={styles.SocialLink}
      >
        <GoogleIcon />
      </div>
      <div
        onClick={() => window.AppleID.auth.signIn()}
        className={styles.SocialLink}
      >
        <AppleIcon />
      </div>
    </div>
  )
};

export default SocialLinks;
