// Libraries
import { useState } from 'react';

// Components
import Page from '../../components/Page';
import Tabs from '../../components/Tabs';
import Container from '../../components/Container';
import { ReactComponent as LogoErmitag } from '../../assets/logo_ermitah.svg';
import { Login, Register } from './components';

// Styles
import styles from './style.module.scss';


const TABS = {
  signIn: 'signIn',
  signUp: 'signUp',
};

const TABS_TITLE = {
  [TABS.signIn]: 'Авторизация',
  [TABS.signUp]: 'Регистрация',
}

const TABS_SUBTITLE = {
  [TABS.signIn]: 'Для доступа к личному кабинету введите свой e-mail и пароль',
  [TABS.signUp]: 'Для доступа к личному кабинету зарегистрируйтесь'
}

const TABS_MAP = {
  [TABS.signIn]: 'Вход в аккаунт',
  [TABS.signUp]: 'Регистрация',
};

const tabsContent = {
  [TABS.signIn]: <Login />,
  [TABS.signUp]: <Register />,
};

const Auth = () => {
  const [tab, setTab] = useState(TABS.signIn);

  return (
    <Page>
      <Container type="small" centeredContent>
        <LogoErmitag className={styles.LogoErmitag} />
        <div className={styles.Auth}>
          <div className={styles.Title}>{TABS_TITLE[tab]}</div>
          <div className={styles.SubTitle}>
            {TABS_SUBTITLE[tab]}
          </div>
          <Tabs
            tabs={TABS_MAP}
            content={tabsContent}
            defaultTab={TABS.signIn}
            handleSetTab={setTab}
          />
        </div>
      </Container>
    </Page>
  );
};

export default Auth;
