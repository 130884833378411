import { useDispatch, useSelector } from 'react-redux';
import { selectActiveCategory, selectAllSubCategories } from '../../../selectors';
import { useEffect } from 'react';
import { setActiveCategory, setSubCategories } from '../../../actions';

export const useActiveCategory = () => {
  const dispatch = useDispatch();
  const activeCategory = useSelector(selectActiveCategory);
  const subCategories = useSelector(selectAllSubCategories(activeCategory));

  const selectCategories = (category) => () => {
    dispatch(setActiveCategory(category));
  };

  useEffect(() => {
    // if (activeCategory) {
      // dispatch(setSubCategories(subCategories));
    // }
  }, [activeCategory, subCategories]);

  return {
    activeCategory,
    selectCategories,
  };
};
