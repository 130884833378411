// Libraries
import classnames from 'classnames';

// Assets
import Star from '../../assets/components/Star';

// Styles
import styles from './style.module.scss';

const Rating = ({ value, maxRating = 5, onChange }) => {
  return (
    <div className={classnames(styles.Rating, onChange && styles.Rating_dynamic)}>
      {Array.from(Array(maxRating)).map((_, index) => (
        <div className={styles.Star} onClick={() => onChange(index + 1)}>
          <Star filled={value >= index + 1} />
        </div>
      ))}
    </div>
  );
};

export default Rating;
