import { createSelector } from 'reselect';
import { selectAllCategories } from './selectAllCategories';

const getCategory = (categoryId) => (categories) => {
  const category = categories.find((category) => category.id === categoryId);
  return category ? category.children : [];
};

export const selectAllSubCategories = (categoryId) =>
  createSelector(selectAllCategories, getCategory(categoryId));
