// Libraries
import { useMemo } from 'react';

// Components
import Button from '../Button';
import { EmptyContent } from '../EmptyContent';
import { Loader } from '../Loader';

// Constants
import { CATALOG, useResize } from '../../constants';

// Hooks
import { useGetProducts } from '../../hooks';

// Styles
import styles from './style.module.scss';

const DEFAULT_GRID_SETTINGS = {
  itemsPerRow: {
    desktop: 4,
    tablet: 3,
    largeMobile: 2,
    mobile: 2,
  },
  itemsPerPage: {
    desktop: 20,
    tablet: 20,
    largeMobile: 20,
    mobile: 20,
  },
  gridRowGap: 24,
  gridColumnGap: 24,
};

const LoadContainer = ({
  component,
  fetchMore,
  fetchMoreButton = true,
  gridSettings = {},
  items,
  type,
  loading
}) => {
  const { activeResizeMode } = useResize();
  const { commonSize } = useGetProducts();

  const usedGridSettings = useMemo(
    () => ({
      ...DEFAULT_GRID_SETTINGS,
      ...gridSettings,
    }),
    [gridSettings],
  );

  const renderComponents = useMemo(() => {
    return items.map((item, index) => {
      return (
        <div key={`${item.name}-${index}`} className={styles.Item}>
          {component(item)}
        </div>
      );
    });
  }, [items, component]);

  return (
    <>
      {loading && items.length === 0 && <Loader />}
      {items.length > 0 && (
        <>
          <div
            className={styles.Items}
            style={{
              gridTemplateColumns: `repeat(${usedGridSettings.itemsPerRow[activeResizeMode]}, 1fr`,
              gridRowGap: usedGridSettings.gridRowGap,
              gridColumnGap: usedGridSettings.gridColumnGap,
            }}
          >
            {renderComponents}
          </div>
          {type === CATALOG && items.length !== commonSize && fetchMoreButton && (
            <div className={styles.Button}>
              <Button onClick={fetchMore}>Показать ещё</Button>
            </div>
          )}
        </>
      )}
      {items.length === 0 && !loading && <EmptyContent type={type} />}
    </>
  );
};

export default LoadContainer;
