// Libraries
import classnames from 'classnames';

// Styles
import styles from './style.module.scss';

const TYPES = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};

const classes = {
  [TYPES.small]: styles.SmallContainer,
  [TYPES.medium]: styles.MediumContainer,
  [TYPES.large]: styles.LargeContainer,
};

const Container = ({ type = TYPES.medium, children, className, centeredContent }) => {
  return (
    <div
      className={classnames(classes[type], centeredContent && styles.CenteredContent, className)}
    >
      {children}
    </div>
  );
};

export default Container;
