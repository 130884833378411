// Libraries
import React from 'react';
import classnames from 'classnames';

// Components
import Link from '../Link';

// Styles
import styles from './style.module.scss';

const THEMES = {
  default: 'default',
  white: 'white',
};

const Button = ({
  htmlType,
  children,
  filled,
  link,
  inline,
  className,
  contentClassName,
  linkClassName,
  theme = THEMES.default,
  isOuter,
  ...props
}) => {
  const themeIsWhite = theme === THEMES.white;

  const CustomButton = React.useCallback(() => (
    <button
      {...props}
      type={htmlType}
      className={classnames(
        styles.Button,
        className,
        filled && styles.Button_filled,
        inline && styles.Button_inline,
        themeIsWhite && styles.Button_white,
      )}
    >
      <span className={classnames(styles.Content, contentClassName)}>
        {children}
      </span>
    </button>
  ), [
    props?.disabled,
    link,
    className,
    filled,
    inline,
    themeIsWhite,
    contentClassName,
    children
  ]);

  const InnerLink = React.useCallback(() => (
    <Link
      to={link}
      disabled={props.disabled}
      className={classnames(
        styles.Button,
        className,
        filled && styles.Button_filled,
        inline && styles.Button_inline,
        themeIsWhite && styles.Button_white,
      )}
    >
      <span className={classnames(styles.Content, contentClassName)}>
        {children}
      </span>
    </Link>
  ), [
    link,
    props,
    className,
    filled,
    inline,
    themeIsWhite,
    contentClassName,
    children
  ]);

  const OuterLink = React.useCallback(() => (
    <a
      href={link}
      className={classnames(
        styles.Button,
        className,
        filled && styles.Button_filled,
        inline && styles.Button_inline,
        themeIsWhite && styles.Button_white,
      )}
    >
      <span className={classnames(styles.Content, contentClassName)}>
        {children}
      </span>
    </a>
  ), [
    link,
    className,
    filled,
    inline,
    themeIsWhite,
    contentClassName,
    children
  ]);

  return link
    ? isOuter
      ? <OuterLink />
      : <InnerLink />
    : <CustomButton />
};

export default Button;
