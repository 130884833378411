// Libraries
import { useCallback, useMemo } from 'react';

// Components
import Page from '../../components/Page';
import Head from '../../components/Head';
import LoadContainer from '../../components/LoadContainer';
import { TICKETS } from '../../constants';
import { Loader } from '../../components/Loader';
import Excursion from '../../components/Excursion';

// Hooks
import { useGetExcursions } from '../../hooks/useGetExcursions';

// Styles
import styles from './style.module.scss';


const HEAD_TEXTS = {
  title: 'Экскурсии',
  text: 'На этой странице вы можете приобрести аудио экскурсию по Государственному Эрмитажу. После покупки, экскурсия автоматически будет добавлена в Ваш личный кабинет. Прослушать экскурсию можно в официальном приложении Эрмитаж. Аудиогид. Пожалуйста, обратите внимание - билет на посещение музея приобретается отдельно.'
}


const Excursions = () => {
  const { excursions, loading } = useGetExcursions();
  const renderExcursions = useCallback((item) => {
    return item && <Excursion key={item.id} data={item} />;
  }, []);

  if (loading) {
    return <Loader style={{ margin: '125px 50%' }} />;
  }

  return (
    <Page>
      <Head {...HEAD_TEXTS} />
      <div className={styles.Excursions}>
        <LoadContainer
          component={renderExcursions}
          fetchRequest={() => null}
          type={TICKETS}
          items={excursions}
          gridSettings={{
            itemsPerRow: {
              desktop: 3,
              tablet: 2,
              largeMobile: 1,
              mobile: 1,
            },
            itemsPerPage: {
              desktop: 15,
              tablet: 15,
              largeMobile: 15,
              mobile: 15,
            },
          }}
        />
      </div>
    </Page>
  );
};

export default Excursions;
