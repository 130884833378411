import classNames from 'classnames'

// Assets
import CloseIcon from '../../assets/close.svg'

// Styles
import styles from './style.module.scss';

const Modal = ({ children, open, onClose }) => {
  return (
    <div className={classNames(styles.Wrapper, { [styles.Wrapper_open]: open })}>
      <div className={styles.Card}>
        <div className={styles.CardHeader}>
          <img onClick={onClose} src={CloseIcon} alt="" className={styles.CloseIcon} />
        </div>
        {children}
      </div>
     <div className={styles.Background} />
    </div>
  )
}
export default Modal