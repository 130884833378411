
// Libraries

// Components
import Page from '../../components/Page';
import Button from '../../components/Button';
import Link from '../../components/Link';

// Assets
import { ReactComponent as CloseIcon } from '../../assets/close_red.svg';

// Styles
import styles from './style.module.scss';

const OrderRejected = () => {

  return (
    <Page>
      <div className={styles.OrderRejected}>
        <div className={styles.Circle}>
          <CloseIcon />
        </div>
        <div className={styles.Title}>Ошибка, оплата не прошла</div>
        <Link to='/basket'>
          <Button filled>
            Вернуться в корзину
          </Button>
        </Link>
      </div>
    </Page>
  );
};

export default OrderRejected;
