import { useLazyQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectOnlyHistCategory } from '../selectors';
import { addNewProduct } from '../actions';

const query = loader('../graphql/getProducts.graphql');

export const useGetOnlyHitsProducts = () => {
  const dispatch = useDispatch();
  const hitsCategory = useSelector(selectOnlyHistCategory);
  const [getProducts, { data, loading }] = useLazyQuery(query, {
    fetchPolicy: 'no-cache',
    onCompleted: (res) => {
      if (res?.getProducts) {
        for (const product of res.getProducts.products) {
          dispatch(addNewProduct(product));
        }
      }
    }
  });

  useEffect(() => {
    if (hitsCategory?.id) {
      getProducts({ variables: { category: hitsCategory.id, paginate: 16, page: 1 } });
    }
  }, [hitsCategory]);

  return {
    loading,
    hitsProducts: data?.getProducts?.products ?? []
  };
};
