// Libraries

// Hooks 
import { useFaq } from '../../hooks';

// Components
import Page from '../../components/Page';
import ConnectWithSupport from '../../components/ConnectWithSupport';
import DropdownList from '../../components/DropdownList';

// Styles
import styles from './style.module.scss';

const Faq = () => {
  const { faq } = useFaq();

  return (
    <Page>
    <div className={styles.Faq}>
      <div className={styles.Title}>Ответы на вопросы</div>
      <div className={styles.Row}>
        <div className={styles.Info}>
          <DropdownList
            items={faq.map(f => ({ id: f.id, title: f.question, content: f.answer, contentIsReactComponent: true }))}
            marker="maths"
          />
        </div>
        <div className={styles.SubInfo}>
          <ConnectWithSupport settings={{ title: false }} />
        </div>
      </div>
    </div>
  </Page>
)
}

export default Faq;
