const Burger = ({ fill = '#01030C' }) => (
  <svg width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0H20V1H0V0ZM0 6H20V7H0V6ZM20 12H0V13H20V12Z"
      fill={fill}
    />
  </svg>
);

export default Burger;
