import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useDispatch, useSelector } from 'react-redux';
import { setCategories } from '../actions';
import {
  selectAllCategories,
  selectCatalogCategories,
  selectSpecialOffersCategories,
  selectSubCategories,
} from '../selectors';

const query = loader('../graphql/getCategories.graphql');

export const useCategories = () => {
  const dispatch = useDispatch();
  const categories = useSelector(selectAllCategories);
  const catalogCategories = useSelector(selectCatalogCategories);
  const specialOffersCategories = useSelector(selectSpecialOffersCategories);
  const subCategoriesFromStore = useSelector(selectSubCategories);
  const { data, loading, error } = useQuery(query);

  useEffect(() => {
    if (data?.Categories && categories.length === 0) {
      dispatch(setCategories(data.Categories));
    }
  }, [data]);

  return {
    categories,
    categoriesLoading: loading,
    categoriesError: error,
    catalogCategories,
    specialOffersCategories,
    subCategories: subCategoriesFromStore,
  };
};
