
import { useLazyQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { addNewProduct } from '../actions';
import { useDispatch } from 'react-redux';

const query = loader('../pages/Product/graphql/getProduct.graphql');

export const useGetProduct = () => {
	const dispatch = useDispatch();

	const onCompletedHandler = res => {
		if (res?.getProduct) {
			dispatch(addNewProduct({ ...res.getProduct }));
		}
	}

	const [getProduct] = useLazyQuery(query, {
		fetchPolicy: 'no-cache',
		onCompleted: onCompletedHandler
	});

	const handleGetProduct = id => {
		getProduct({
			variables: {
				id
			}
		})
	};

	return {
		handleGetProduct
	}
};