// Libraries
import { useState, useContext } from 'react';

// Components
import Input from '../../Input';
import Button from '../../Button';
import { Title, SubTitle } from '../../PageParts';

// Constants
import { BANNER_VARIANTS } from '../../../constants';

// Helpers
import { validateEmail } from '../../../helpers/validateEmail';

// Contexts
import { BannerContext } from '../../Page';

// Styles
import styles from './style.module.scss';

const RemindPassword = () => {
  const setBannerData = useContext(BannerContext);
  const [emailVal, setEmailVal]  = useState("");

  const handleChangeEmailVal = (e) => {
    setEmailVal(e.target.value);
  };

  const onSubmit = async () => {
    const isValidateEmail = await validateEmail(emailVal);
  };

  return (
    <div className={styles.RemindPassword}>
      <Title>Восстановить пароль</Title>
      {/*
       <SubTitle>Dictum porta eget ac congue vel fames viverra nibh feugiat sit amet</SubTitle>
      */}
      <div className={styles.Content}>
        <Input 
          onChange={handleChangeEmailVal}
          value={emailVal} 
          label="E-mail" 
          className={styles.Input} 
        />
        <Button
          style={{ width: '100%', height: 48 }}
          onClick={() =>
            setBannerData({
              variant: BANNER_VARIANTS.remind_password,
            })
          }
          disabled={!emailVal}
        >
          Продолжить
        </Button>
      </div>
    </div>
  );
};

export default RemindPassword;
