// Components
import Button from '../../Button';
import Link from '../../Link';
import { Title, SubTitle } from '../../PageParts';

// Styles
import styles from './style.module.scss';

const RemindSuccess = () => {
  return (
    <div className={styles.RemindSuccess}>
      <Title>Запрос на восстановление пароля отправлен</Title>
      <SubTitle>Мы отправили на почту noname@mail.ru инструкции для изменения пароля</SubTitle>
      <Link to="/">
        <Button filled className={styles.Button} style={{}}>
          Готово
        </Button>
      </Link>
    </div>
  );
};

export default RemindSuccess;
