import Page from "../../components/Page";
import {ProcessPersonalData} from "../../components/Banner/ProcessPersonalData";


const Terms = () => {
  return (
    <Page>
      <ProcessPersonalData />
    </Page>
  )
};

export default Terms;