import { useEffect } from 'react';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllSlides } from '../../../selectors';
import { setSlides } from '../../../actions';

const getSliderQuery = loader('../graphql/getSlider.graphql');

export const useGetSlider = () => {
  const dispatch = useDispatch();
  const { data } = useQuery(getSliderQuery);
  const slides = useSelector(selectAllSlides);

  useEffect(() => {
    if (slides.length === 0 && data?.getSlider) {
      dispatch(setSlides(data.getSlider));
    }
  }, [data, dispatch, slides]);

  return {
    slides,
  };
};
