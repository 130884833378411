
// Library
import React, { useCallback, useMemo } from 'react';
import styles from './style.module.scss';
import DropdownList from '../DropdownList';
import OrderItem from '../OrderItem';
import { convertOrderStatus } from '../OrderItem/helpers';
import { formatPrice } from '../../helpers/formatPrice';


const STATUSES_MAP = {
	inProcess: {
		name: 'В процессе',
		circleColor: '#F2994A',
	},
	finish: {
		name: 'Завершен',
		circleColor: '#27AE60',
	},
	cancel: {
		name: 'Отменен',
		circleColor: '#BDBDBD',
	},
};


const OrderTab = ({ data }) => {
	const TopPanel = useCallback(() => (
		<div className={styles.TopPanel}>
			<div className={styles.Date}>Заказ от {data.created_at}</div>
			<div className={styles.Price}>
				{formatPrice(data?.sum)}
			</div>
			<div className={styles.Status}>
				<div
					className={styles.Status__Circle}
					style={{
						backgroundColor: STATUSES_MAP[convertOrderStatus(data.orderStatus.name)]?.circleColor,
					}}
				/>
				<div className={styles.Status__Name}>
					{data.orderStatus.name_show}
				</div>
			</div>
		</div>
	// ), [date, orders]);
	), []);

	const ListItems = useMemo(
		() => [
			{
				topPanel: <TopPanel />,
				content: data?.orderProducts.map((order, i) => (
					<OrderItem 
						key={i} 
						data={{ ...order, order_id: data.id }} 
						lastItem={i === data?.orderProducts.length - 1} 
					/>
				)),
			}
		],
		[data?.orderProducts],
	);

	return (
		<DropdownList
			items={ListItems}
			paddings={false}
			space={true}
		/>
	);
};

export default OrderTab;