// Components
import Link from '../Link';

// Styles
import styles from './style.module.scss';

const Tags = ({ items = [] }) => {
  return (
    <div className={styles.Tags}>
      {items.map(({ value, label }) => (
        <div key={label} className={styles.Tag}>
          <Link to="/">{label}</Link>
        </div>
      ))}
    </div>
  )
};

export default Tags;
