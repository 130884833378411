
// Libraries
import { useCallback, useContext } from 'react';

// Contexts
import { BannerContext } from '../../Page';

// Components
import { Title } from '../../PageParts';

// Hooks
import { useFooterData } from "../../../hooks/useFooterData";

// Styles
import styles from './style.module.scss';

const WarrantyAndQuality = () => {
	const { subInfoForBanners } = useFooterData();
	const setBannerData = useContext(BannerContext);

	return (
		<>
			<Title className={styles.Title}>Гарантия и качество</Title>
			<div dangerouslySetInnerHTML={{ __html: subInfoForBanners?.text_warranty_quality }} />
		</>
	);
};

export default WarrantyAndQuality;
