// Libraries
import { useState } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import 'moment/locale/ru';

// Assets
import ArrowIcon from '../../assets/components/SliderArrow.js';

// Styles
import styles from './style.module.scss';

const Calendar = ({ title }) => {
  //TODO: переделать на даты
  const [selectedDate, setSelectedDate] = useState(null);
  const [manipulatorDate, setManipulatorDate] = useState(moment().startOf('month'));
  const [selectedHours, setSelectedHours] = useState(null);

  const Table = () => {
    const days = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];
    const dateOfStart = manipulatorDate.clone().startOf('week').subtract(1, 'day');

    const cells = Array.from(new Array(35)).map(() => ({
      label: dateOfStart.add(1, 'day').format('DD'),
      value: dateOfStart.format('DD-MM-YYYY'),
    }));

    const Head = () => (
      <div className={styles.Head}>
        {days.map((day) => (
          <div className={styles.Cell}>{day}</div>
        ))}
      </div>
    );

    const Cells = () => (
      <div className={styles.Cells}>
        {cells.map(({ label, value }) => (
          <div
            className={classnames(styles.Cell, value === selectedDate && styles.Cell_selected)}
            onClick={() => setSelectedDate(value)}
          >
            {label}
          </div>
        ))}
      </div>
    );

    return (
      <div className={styles.Table}>
        <Head />
        <Cells />
      </div>
    );
  };

  const Button = ({ reversed, onClick }) => (
    <div
      className={classnames(styles.Button, reversed && styles.Button_reversed)}
      onClick={onClick}
    >
      <ArrowIcon />
    </div>
  );

  const Manipulator = () => (
    <div className={styles.Manipulator}>
      <Button
        onClick={() => setManipulatorDate((date) => moment(date).clone().subtract(1, 'months'))}
      />
      <div className={styles.Date}>{manipulatorDate.locale('ru').format('MMMM YYYY')}</div>
      <Button
        reversed
        onClick={() => setManipulatorDate((date) => moment(date).clone().add(1, 'months'))}
      />
    </div>
  );

  const TopRow = () => {
    return (
      <div className={styles.TopRow}>
        <div className={styles.Title}>{title}</div>
        <Manipulator />
      </div>
    );
  };

  const Hours = () => {
    // мок
    const hours = [
      {
        label: '11:00',
        value: '11:00',
      },
      {
        label: '12:00',
        value: '12:00',
      },
      {
        label: '13:00',
        value: '13:00',
      },
      {
        label: '13:30',
        value: '13:30',
      },
      {
        label: '14:00',
        value: '14:00',
      },
      {
        label: '15:30',
        value: '15:30',
      },
      {
        label: '15:45',
        value: '15:45',
      },
      {
        label: '16:00',
        value: '16:00',
      },
      {
        label: '17:00',
        value: '17:00',
      },
    ];

    return (
      <div className={styles.Hours}>
        {hours.map(({ label, value }) => (
          <div
            className={classnames(styles.Hour, selectedHours === value && styles.Hour_selected)}
            onClick={() => setSelectedHours(value)}
          >
            {label}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={styles.Container}>
      <TopRow />
      <Table />
      <Hours />
    </div>
  );
};

export default Calendar;
