// Libraries
import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';

// Components
import Page from '../../components/Page';
import Input from '../../components/Input';
import Rating from '../../components/Rating';
import Button from '../../components/Button';
import HaveQuestions from '../../components/HaveQuestions';
import Link from '../../components/Link';

// Styles
import styles from './style.module.scss';
import {useClearProductsFromCart} from "../../hooks/useClearProductsFromCart";

const OrderAccepted = () => {
  const [rating, setRating] = useState(4);
  const hasBeenAuth = !!Cookies.get('hermitage_user');
  const location = useLocation();
  const orderNumber = new URLSearchParams(location.search).get('order');
  const { handleClearProductsFromCart } = useClearProductsFromCart();

  const RequireAuth = () => (
    <>
      <div className={styles.SubTitle}>Зарегистрируйтесь, чтобы отслеживать Ваш заказ {"\n"} в приложении</div>
      <Link to='/auth'>
        <Button filled>
          Войти или зарегистрироваться
        </Button>
      </Link>
      <div className={styles.Container}>
        <HaveQuestions />
      </div>
    </>
  );

  const HasBeenAuth = () => (
    <>
      <div className={styles.SubTitle}>Вы можете оставить отзыв</div>
      <div className={styles.Wrapper}>
        <div className={styles.Review}>
          <Input placeholder="Ваш отзыв..." />
          <div className={styles.Grade}>Ваша оценка</div>
          <div className={styles.Rating}>
            <Rating value={rating} onChange={setRating} />
          </div>
          <Button style={{ width: '100%', height: 48 }}>Отправить</Button>
        </div>
        <HaveQuestions />
      </div>
    </>
  );

  useEffect(() => {
    handleClearProductsFromCart();
  }, []);

  return (
    <Page>
      <div className={styles.OrderAccepted}>
        <div className={styles.Title}>Заказ #{orderNumber} принят</div>
        {hasBeenAuth ? <HasBeenAuth /> : <RequireAuth />}
      </div>
    </Page>
  );
};

export default OrderAccepted;
