// Libraries
import classnames from 'classnames';
import { useCallback, useMemo } from 'react';

// Styles
import styles from './style.module.scss';

export const RowSelector = ({ value, data = [], onChange }) => {
  const onChangeHandler = useCallback(
    (value) => () => {
      onChange(value);
    },
    [onChange],
  );

  const renderRowSelectors = useMemo(() => {
    return data.map((item) => {
      return (
        <div
          key={item.value}
          className={classnames(styles.Item, value === item.value && styles.Item_selected)}
          onClick={onChangeHandler(item.value)}
          style={{ width: `calc(100% / ${data.length})` }}
        >
          {item.label}
        </div>
      );
    });
  }, [data, value, onChangeHandler]);

  return <div className={styles.RowSelector}>{renderRowSelectors}</div>;
};
