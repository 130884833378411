
// Libraries
import { useState, useEffect } from 'react';


const useTimer = (interval = 1000) => {
	const [time, setTime] = useState(0);
	const [hasEnded, setHasEnded] = useState(false);

	useEffect(() => {
		const timer = setInterval(() => {
			setTime(prev => {
				if (prev >= timer) {
					return prev - interval;
				}

				setHasEnded(true);

				return 0;
			});
		}, interval);

		return () => {
			clearInterval(timer);
		};
	}, []);

	return {
		time,
		setTime,
		hasEnded
	}
};

export { useTimer };