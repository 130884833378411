import { useState } from "react"

export const useYMap = () => {
    const [selectedMark, setSelectedMark] = useState(null);

    const handleSelectMark = data => setSelectedMark(data);

    return {
        selectedMark,
        handleSelectMark,
    }
}