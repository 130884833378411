import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import {  useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react'; 

import { setFaq } from '../actions';
import { selectFaq } from '../selectors';



const query = loader('../graphql/getFaq.graphql');


export const useFaq = () => {
  const dispatch = useDispatch();
  const faq = useSelector(selectFaq);
  const { data, loading } = useQuery(query);
  
  useEffect(() => {
    if (data?.getFaq) {
      dispatch(setFaq(data.getFaq));
    }
  }, [data, dispatch]);

  return {
    faq,
    loading,
  };
}