
// Hooks
import { useFooterData } from '../../../hooks/useFooterData';

// Styles
import styles from './style.module.scss';

const DeliveryAndPayment = () => {
  const { subInfoForBanners } = useFooterData();

  return (
    <div>
      <div className={styles.Title}>Доставка и оплата</div>
      <div dangerouslySetInnerHTML={{ __html: subInfoForBanners?.text_shipping_payment }} />
    </div>
  )
};

export default DeliveryAndPayment;
