export const SET_EXCURSIONS = 'SET_EXCURSIONS';
export const SET_EXCURSION = 'SET_EXCURSION';
export const SET_EXCURSION_PAYMENT = 'SET_EXCURSION_PAYMENT';

export const setExcursions = (excursions) => {
  return {
    type: SET_EXCURSIONS,
    payload: {excursions},
  };
};

export const setExcursion = (excursion) => {
  return {
    type: SET_EXCURSION,
    payload: {excursion},
  };
};

export const setExcursionPayment = (payment) => {
  return {
    type: SET_EXCURSION_PAYMENT,
    payload: {payment},
  };
};
