import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useState } from 'react';

const resetCustomerPasswordMutation = loader('../graphql/resetCustomerPassword.graphql');

export const useResetCustomerPassword = () => {
  const [data, setData] = useState(null);
  const [resetCustomerPassword] = useMutation(resetCustomerPasswordMutation, {
    onCompleted: (res) => {
      if (res?.resetCustomerPassword) {
        setData(res.resetCustomerPassword)
      }
    },
  });

  const resetCustomerPasswordHandler = ({email, token, password, password_confirmation}) => {
    return resetCustomerPassword({ variables: {
      email,
      token,
      password,
      password_confirmation
    }});
  };

  return {
    resetCustomerPasswordHandler,
    ...data
  };
};