
import React from 'react';
import ReactDOMServer from 'react-dom/server';



class TemplateProvider extends React.Component {
	constructor() {
		super();
		this.state = { template: () => null };
	}

	componentDidMount() {
		this.setState({
			template: content => this.props.ymaps.templateLayoutFactory.createClass(
				ReactDOMServer.renderToStaticMarkup(content)
			),
		});
	}

	render() {
		return this.props.children({ template: this.state.template });
	}
}

export default TemplateProvider;

// '<><div className={styles.Name}>{source.name}</div>{renderItem(source.info)} </>'

