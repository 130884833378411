
// Libraries
import { useState } from 'react';

// Components
import Page from '../../components/Page';
import Tabs from '../../components/Tabs';
import Profile from '../../components/Profile';
import { Favorites, MyOrders } from './components';

// Constant
import { ORDERS } from '../../constants';

// Styles
import styles from './style.module.scss';

const TABS = {
  profile: 'profile',
  orders: ORDERS,
  liked: 'liked',
};

const TABS_MAP = {
  [TABS.profile]: 'Профиль',
  [TABS.orders]: 'Мои заказы',
  [TABS.liked]: 'Избранное',
};

const tabsContent = {
  [TABS.profile]: <Profile />,
  [TABS.orders]: <MyOrders />,
  [TABS.liked]: <Favorites />,
};

const PersonalArea = () => {
  const [tab, setTab] = useState(tabsContent[TABS.profile])

  return (
    <Page>
      <div className={styles.PersonalArea}>
        <Tabs
          title="Личный кабинет"
          tabs={TABS_MAP}
          content={tabsContent}
          defaultTab={TABS.profile}
          handleSetTab={setTab}
          defaultValue={tab}
        />
      </div>
    </Page>
  );
};

export default PersonalArea;
