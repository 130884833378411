
// Libraries
import { useState, useMemo, useCallback, useEffect } from 'react';

// Components
import Page from '../../components/Page';
import DropdownList from '../../components/DropdownList';
import Head from '../../components/Head';
import { YMaps, Map as YMap, Placemark, withYMaps } from 'react-yandex-maps';

// Hooks
import { useFooterData } from "../../hooks/useFooterData";
import { useYMap } from "../../hooks/useYMap";

// Styles
import styles from './style.module.scss';
import TemplateProvider from '../../components/TemplateProvider';


const INFO_MAP_NAMES = {
  address: 'Адрес',
  metro: 'Метро',
  workingTime: 'График работы',
  phone: 'Телефон магазина',
};


const About = () => {
  const { subInfoForBanners: { about, shops } } = useFooterData();
  const { selectedMark } = useYMap();

  const marksData = useMemo(() => Array.isArray(shops)
    ? shops.reduce((reducer, { id, name, schedule, phone, address, metro, lat, lon }) => [
      ...reducer,
      {
        data: {
          id,
          name,
          info: {
            address,
            metro,
            workingTime: schedule,
            phone,
          },
        },
        coords: {
          lat,
          lng: lon
        }
      }
    ], [])
    : []
  , [shops]);

  const [mapData, setMapData] = useState({
    center: [0, 0],
    zoom: 15
  });

  const ShopContent = ({ field, value }) => (
    <div className={styles.Block}>
      <div className={styles.Title}>{INFO_MAP_NAMES[field]}</div>
      <div className={styles.Text}>{value}</div>
    </div>
  );

  const renderList = items => items.reduce(
    (items, { name, info }) => [
      ...items,
      {
        title: name,
        content: Object.entries(info).map(([field, value]) => (
          <ShopContent field={field} value={value} />
        )),
      },
    ],
    [],
  );

  const renderItem = item => Object.entries(item).map(([field, value]) => (
    <ShopContent field={field} value={value} />
  ))

  const ConnectedTemplateProvider = withYMaps(TemplateProvider, true, [
    'templateLayoutFactory',
  ]);

  const Map = useCallback(() => {
    if (Array.isArray(marksData) && marksData.length > 0) {
      return (
        <div className={styles.Map}>
          <YMaps query={{ apikey: "aba6af77-c3d5-4ef8-81ea-1e8afea52ef0" }}>
            <ConnectedTemplateProvider>
              {({ template }) => (
                <YMap
                  className={styles.Map__Ymap}
                  defaultState={mapData}
                >
                  {marksData.map(({ data, coords }) => (
                    <Placemark
                      geometry={[coords.lat, coords.lng]}
                      options={{
                        balloonContentLayout: template(
                          <div className={styles.MarkUpContent}>
                            <div className={styles.Name}>{data.name}</div>
                            {renderItem(data.info)}
                          </div>
                        ),
                      }}
                      modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
                    />
                  ))}
                </YMap>
              )}
            </ConnectedTemplateProvider>
          </YMaps>
        </div>
      )
    }

    return <></>;
  }, [mapData, marksData, selectedMark]);

  useEffect(() => {
    if (marksData && marksData[0]?.coords) {
      const { lat, lng } = marksData[0].coords;
      handleChangeMark({ lat, lng });
    }
  }, [marksData]);

  const handleChangeMark = ({ lat, lng }) => {
    if (lat && lng) {
      setMapData(prev => ({
        ...prev,
        center: [lat, lng]
      }))
    }
  };

  return (
    <Page>
      <Head
        title="О магазине"
        text={about}
      />
      <div className={styles.ShopInfo}>
        <DropdownList
          className={styles.List}
          defaultOpenedItems={[0]}
          items={renderList(marksData.map(({ data }) => data))}
          onChange={indexOfShows => {
            if (marksData[indexOfShows]) {
              const { lat, lng } = marksData[indexOfShows].coords;
              handleChangeMark({ lat, lng });
            }
          }}
          showOnlyOne
        />
        <Map />
      </div>
    </Page>
  );
};

export default About;
