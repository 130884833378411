// Libraries
import { useEffect, useMemo, useCallback, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Components
import CategoryCard from '../../CategoryCard';
import { Nav } from './Nav';


// Actions 
import { setFilterGroups, setKeyword } from '../../../actions'

// Hooks
import { useCategories } from '../../../hooks';

import styles from './MainContent.module.scss';
import { useActiveCategory } from '../hooks';

export const MainContent = ({ setHide }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { categories } = useCategories();
  const { activeCategory, selectCategories } = useActiveCategory();
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (activeCategory) {
      const categoryData = categories.find(({ id }) => id === activeCategory?.id);
      setItems(activeCategory.children.map((i) => ({ ...i, categoryId: categoryData.id, categoryName: categoryData.name })));
    } else {
      categories.map((category) => {
        setItems((prevArr) => [...prevArr, ...category?.children.map((c) => ({ ...c, categoryId: category.id, categoryName: category.name }))]);
      });
    }
  }, [activeCategory, categories]);

  const onClickToCategory = useCallback((data) => () => {
    if (data) {
      const root = activeCategory || categories.find(({ id }) => id === data.categoryId)
      const categoryData = root.children.find(({ id }) => id === data.id);
      dispatch(setFilterGroups(categoryData?.filterGroup?.map(item => parseInt(item.id))));
      dispatch(setKeyword(null));
      setHide();
      history.push(`/catalog/${root?.seo_url || root?.name}/${data?.seo_url || data?.name}`.toLowerCase().replace(/ /g, '_'));
    }
  }, [categories, activeCategory])

  const renderSubCategories = useMemo(() => {
    return items?.map((item) => (
      <CategoryCard 
        onClick={onClickToCategory(item)}
        item={item} 
        classes={{ card: styles.CategoryCard }} 
        key={item.id}
      />
    ));
  }, [items, setHide]);

  return (
    <>
      <Nav setHide={setHide}/>
      <div className={styles.Content}>{renderSubCategories}</div>
    </>
  );
};
