const ProfileIcon = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 8.8125C10.0918 8.8125 11.7969 6.94922 11.7969 4.68164C11.7969 2.43164 10.1006 0.665039 8 0.665039C5.91699 0.665039 4.20312 2.4668 4.20312 4.69922C4.21191 6.95801 5.9082 8.8125 8 8.8125ZM8 7.48535C6.7168 7.48535 5.61816 6.25488 5.61816 4.69922C5.61816 3.16992 6.69922 1.99219 8 1.99219C9.30957 1.99219 10.3818 3.15234 10.3818 4.68164C10.3818 6.2373 9.29199 7.48535 8 7.48535ZM2.47168 16.9424H13.5195C14.9785 16.9424 15.6729 16.5029 15.6729 15.5361C15.6729 13.2334 12.7637 9.90234 8 9.90234C3.22754 9.90234 0.318359 13.2334 0.318359 15.5361C0.318359 16.5029 1.0127 16.9424 2.47168 16.9424ZM2.05859 15.6152C1.83008 15.6152 1.7334 15.5537 1.7334 15.3691C1.7334 13.9277 3.96582 11.2295 8 11.2295C12.0254 11.2295 14.2578 13.9277 14.2578 15.3691C14.2578 15.5537 14.1699 15.6152 13.9414 15.6152H2.05859Z"
      fill="#01030C"
    />
  </svg>
);

export default ProfileIcon;
