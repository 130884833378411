// Components
import { Input } from '../../../components/Input/Input';
import { Field } from 'react-final-form';
import MuiPhoneNumber from "material-ui-phone-number";

import styles from './Contacts.module.scss';

export const Contacts = () => {
  return (
    <div className={styles.Contacts}>
      <Input placeholder="Имя и фамилия" name={'name'} />
      <div className={styles.Contacts__Cols}>
        <div className={styles.Contacts__Col}>
          <Input placeholder="E-mail" name={'email'} />
        </div>
        <div className={styles.Contacts__Col}>
          <Field name="phone">
            {({ input, meta }) => {
              return (
                <MuiPhoneNumber
                  {...input}
                  classes={{ root: styles.Contacts__Phone }}
                  name="phone"
                  placeholder="Телефон"
                  defaultCountry="ru"
                  onlyCountries={["ru"]}
                  countryCodeEditable={false}
                  disableDropdown
                  error={meta.error}
                  helperText={meta.error ? meta.error : null}
                />
              );
            }}
          </Field>
        </div>
      </div>
    </div>
  );
};
