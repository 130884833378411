import { useEffect, useState, useCallback, useContext, useMemo, memo } from 'react';
import classnames from 'classnames';
import Link from '../../components/Link';
import DropdownList from '../DropdownList';
import { BannerContext } from '../Page';
import { BANNER_VARIANTS, useResize } from '../../constants';
import vkIcon from '../../assets/socials/vk.svg';
import telegramIcon from '../../assets/socials/telegram.svg';
import dnaIcon from '../../assets/dna.png';
import { useGetCategories } from './hooks';
import { useFooterData } from "../../hooks/useFooterData";
import { useGetFooterData } from "../../hooks/useGetFooterData";
import styles from './style.module.scss';

const currentYear = new Date().getFullYear();

const Footer = memo(() => {
	const { isDesktop, isTablet, isLargeMobile } = useResize();
	const { hitsCategory, novelties } = useGetCategories();
	const setBannerData = useContext(BannerContext);

	const { loading } = useGetFooterData();
	const { subInfoForBanners } = useFooterData();

	const links = useMemo(() => {
		return {
			hits: {
				link: `/catalog/${hitsCategory?.id}`,
				value: 'Хиты продаж',
			},
			novelties: {
				link: `/catalog/${novelties?.id}`,
				value: 'Новинки',
			},
			all: {
				link: '/catalog',
				value: 'Все товары',
			},
		};
	}, [hitsCategory, novelties]);

	const changePopup = useCallback(
		(variant) => () => {
			setBannerData({
				variant,
			});
		},
		[setBannerData],
	);

	const LIST = useMemo(
		() => [
			{
				title: 'Каталог',
				content: (
					<>
						<div className={styles.Link}>
							<Link to={links.all.link}>{links.all.value}</Link>
						</div>
						<div className={styles.Link}>
							<Link to={links.novelties.link}>{links.novelties.value}</Link>
						</div>
						<div className={styles.Link}>
							<Link to="/catalog">Скидки</Link>
						</div>
						<div className={styles.Link}>
							<Link to={links.hits.link}>{links.hits.value}</Link>
						</div>
					</>
				),
			},
			{
				title: 'Посещение музея',
				content: (
					<>
						<div className={styles.Link}>
							<Link to="/tickets">Ваучеры</Link>
						</div>
						<div className={styles.Link}>
							<Link to="/excursions">Экскурсии</Link>
						</div>
						<div className={styles.Link}>
							<Link to="/faq">Общие вопросы</Link>
						</div>
					</>
				),
			},
			{
				title: 'Информация',
				content: (
					<>
						<div className={styles.Link}>
							<Link to="/about">О магазине</Link>
						</div>
						<div className={styles.Link} onClick={changePopup(BANNER_VARIANTS.delivery_and_payment)}>
							<Link>Доставка и оплата</Link>
						</div>
						<div className={styles.Link} onClick={changePopup(BANNER_VARIANTS.return_and_exchange)}>
							<Link>Возврат и обмен</Link>
						</div>
						<div className={styles.Link} onClick={changePopup(BANNER_VARIANTS.warranty_and_quality)}>
							<Link>Гарантия и качество</Link>
						</div>
					</>
				),
			},
			{
				title: 'Поддержка',
				content: (
					<>
						<div className={styles.Link}>
							<a href={`tel: ${subInfoForBanners?.phone}`}>
								{subInfoForBanners?.phone}
							</a>
						</div>
						<div className={styles.Link}>
							<a href={`mailto: ${subInfoForBanners.email}`} style={{ color: '#CF086E' }}>
								{subInfoForBanners?.email}
							</a>
						</div>
						<div
							className={classnames(styles.Link, styles.Chat)}
							onClick={() => window.jivo_api.open()}
						>
							<div className={styles.Point} />
							<span>Чат с поддержкой</span>
						</div>
						<div className={styles.TimeOfWork}>
							{subInfoForBanners?.schedule}
						</div>
					</>
				),
			},
		],
		[subInfoForBanners, links, changePopup],
	);

	const renderCols = useMemo(() => {
		return LIST.map(({ title, content }) => {
			return (
				<div key={title} className={styles.Col}>
					<div className={styles.Title}>{title}</div>
					{content}
				</div>
			);
		});
	}, [LIST]);

	return (
		<div className={styles.Footer}>
			<div className={classnames(styles.Row, styles.Row_withBorder, styles.Row_withPaddingRight)}>
				{!isLargeMobile ? (
					<>{renderCols}</>
				) : (
					<div className={styles.DropdownListWrapper}>
						<div className={styles.DropdownList}>
							<DropdownList items={LIST} className={styles.List} compact />
						</div>
						<div className={styles.Socials}>
							<a
								href="https://vk.com/hermitageshop"
								target="_blank"
								className={styles.Social}
								rel="noreferrer"
							>
								<img src={vkIcon} alt="" />
							</a>
						</div>
					</div>
				)}
			</div>
			{!isLargeMobile && (
				<div
					className={classnames(
						styles.Row,
						styles.Row_withBorder,
						styles.Row_alignCenter,
						styles.Row_socials,
					)}
				>
					<div className={styles.Socials}>
						<a href="https://vk.com/hermitageshop" target="_blank" className={styles.Social}>
							<img src={vkIcon} alt="" />
						</a>
						<a href="https://t.me/HermitageShop" target="_blank" className={styles.Social}>
							<img src={telegramIcon} alt="" />
						</a>
					</div>
					{isDesktop && (
						<div className={styles.Row}>
							<div className={styles.Link} onClick={changePopup(BANNER_VARIANTS.corporate_sales)}>
								<Link>Корпоративные продажи</Link>
							</div>
							<div
								className={styles.Link}
								onClick={changePopup(BANNER_VARIANTS.process_personal_data)}
							>
								<Link>Обработка персональных данных</Link>
							</div>
							<div className={styles.Link} onClick={changePopup(BANNER_VARIANTS.cookies)}>
								<Link>Использование Cookies</Link>
							</div>
							<div className={styles.Link} onClick={changePopup(BANNER_VARIANTS.offer)}>
								<Link>Публичная оферта</Link>
							</div>
						</div>
					)}
				</div>
			)}
			<div
				className={classnames(
					styles.Row,
					!isTablet && styles.Row_withBorder,
					styles.Row_alignCenter,
					styles.Row_copyright,
				)}
			>
				{isTablet && (
					<div className={!isLargeMobile && styles.Row}>
						<div className={styles.Link} onClick={changePopup(BANNER_VARIANTS.corporate_sales)}>
							<Link>Корпоративные продажи</Link>
						</div>
						<div
							className={styles.Link}
							onClick={changePopup(BANNER_VARIANTS.process_personal_data)}
						>
							<Link>Обработка персональных данных</Link>
						</div>
						<div className={styles.Link} onClick={changePopup(BANNER_VARIANTS.cookies)}>
							<Link>Использование Cookies</Link>
						</div>
						<div className={styles.Link} onClick={changePopup(BANNER_VARIANTS.offer)}>
							<Link>Публичная оферта</Link>
						</div>
					</div>
				)}
				<div className={styles.Rights}>
					Государственный Эрмитаж | ООО {`"АРТЕКОМ"`} | 2003 — {currentYear} г. | Все права защищены
				</div>
				<div className={classnames(styles.Row, styles.Row_developedBy)}>
					<a href="https://www.dnateam.ru" target="_blank" referrerPolicy='no-referrer'>
						<img src={dnaIcon} alt="" />
						<div className={styles.DevelopedBy}>Разработка — DNA</div>
					</a>
				</div>
			</div>
		</div>
	);
});

Footer.displayName = 'Footer';

export default Footer;
