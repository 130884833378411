// Libraries
import Cookies from 'js-cookie';

// Components
import Page from '../../components/Page';
import Benefits from '../../components/Benefits';
import Support from '../../components/Support';
import { Rows, Events } from './components';

// Hooks
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { useLazyQuery } from '@apollo/client';
import { useGetSlider } from './hooks';
import { useFavorites } from '../../hooks';

// Constants
import { USER_CART_COOKIE, USER_COOKIE } from '../../constants';


const getConfirmAuth = loader('../../graphql/getConfirmAuth.graphql');

const Landing = () => {
  const { search } = useLocation();
  const history = useHistory();
  const hash = search?.split('&')?.find(string => string.includes('social_hash'))?.split('=')[1];
  const { getFavorites } = useFavorites();

  useEffect(() => {
    getFavorites();
  }, []);

  const [callConfirmAuth] = useLazyQuery(getConfirmAuth, {
    onCompleted: res => {
      if (res?.getSocialAuthUser) {
        Cookies.set(USER_COOKIE, JSON.stringify(res?.getSocialAuthUser), { expires: 365 });
        localStorage.removeItem(USER_CART_COOKIE);
        history.push('/lk');
      }
    }
  });

  useEffect(() => {
    if (hash) {
      callConfirmAuth({ variables: { hash } });
    }
  }, [callConfirmAuth, hash]);

  const { slides } = useGetSlider();

  const pageHeaderProps = {
    theme: 'black',
  };

  return (
    <>
      <Page
        headerProps={pageHeaderProps}
        slides={slides}
        popUpPage='home'
      >
        <Benefits />
        <Rows />
        <Events />
        <Support />
      </Page>
    </>
  );
};

export default Landing;
