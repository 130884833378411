import { useContext, memo } from 'react';
import Button from '../Button';
import { BannerContext } from '../Page';
import benefitIcon1 from '../../assets/benefits/benefit1.svg';
import benefitIcon2 from '../../assets/benefits/benefit2.svg';
import benefitIcon3 from '../../assets/benefits/benefit3.svg';
import { useFooterData } from '../../hooks/useFooterData';
import styles from './style.module.scss';


const Benefits = memo(() => {
  const setBannerData = useContext(BannerContext);
  const { subInfoForBanners } = useFooterData();
  const { main_blocks } = subInfoForBanners;

  const icons = [
    benefitIcon1,
    benefitIcon2,
    benefitIcon3
  ]

  const benefits = icons.map((icon, i) => ({
    icon,
    title: main_blocks[i]?.title,
    text: main_blocks[i]?.description,
    buttonText: main_blocks[i]?.button_text,
    buttonLink: main_blocks[i]?.url,
    buttonOnClick: () => main_blocks[i]?.button_link_banner
      && setBannerData({ variant: main_blocks[i]?.button_link_banner }),
  }));

  const Benefit = ({ icon, title, text, buttonLink, buttonText, buttonOnClick }) => {
    const isChatBenefit = buttonText === 'Чат с поддержкой';

    const BenefitButton = () => {

      return (
        <div className={styles.ButtonContainer}>
          <Button
            link={!isChatBenefit && buttonLink}
            onClick={!isChatBenefit ? buttonOnClick : () => window.jivo_api.open()}
            isOuter={!!(!isChatBenefit && buttonLink)}
          >
            {buttonText}
          </Button>
        </div>
      )
    };

    return (
      <div className={styles.Benefit}>
        <div className={styles.ImageContainer}>
          <img src={icon} alt='icon' />
        </div>
        <div className={styles.Title}>{title}</div>
        <div className={styles.Text}>{text}</div>
        <BenefitButton />
      </div>
    );
  }

  return (
    <div className={styles.Benefits}>
      {benefits.map(benefit => (
        <Benefit key={benefit.title} {...benefit} />
      ))}
    </div>
  );
});

Benefits.displayName = 'Benefits';

export default Benefits;
