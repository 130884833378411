// Components
import Divider from '../Divider';

// Styles
import styles from './style.module.scss';


const OrderItem = ({ data, lastItem }) => {
  const { name, order_id, product, image, quantity } = data;
  const productToCategories = product?.productToCategories;

  return (
    <div className={styles.OrderItem}>
      <Divider />
      <div className={styles.Order}>
        <div className={styles.Preview}>
          <img src={image} alt="" />
        </div>
        <div className={styles.Info}>
          <div className={styles.MainInfo}>
            <div className={styles.Row}>
              <div className={styles.Category}>
                {productToCategories?.length > 0 && productToCategories[0].name}
              </div>
              <div className={styles.Id}>#{order_id}</div>
              <div className={styles.Id}>{quantity} шт</div>
            </div>
            <div className={styles.Name}>{name}</div>
          </div>
        </div>
      </div>
      {lastItem && <Divider />}
    </div>
  );
};

export default OrderItem;
