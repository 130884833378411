// Libraries
import classnames from 'classnames';

// Assets
import ArrowIcon from '../../../assets/components/SliderArrow.js';

// Styles
import styles from '../style.module.scss';

const PrevArrow = ({ arrowClassName, className, onClick }) => {
  const isDisabled = className.includes('slick-disabled');
  return (
    <button
      className={classnames(styles.Arrow, arrowClassName, isDisabled && styles.Arrow_disabled)}
      onClick={onClick}
    >
      <ArrowIcon />
    </button>
  );
};

export default PrevArrow;
