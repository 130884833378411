// Libraries
import classnames from 'classnames';
import { Field } from 'react-final-form';

// Components
import { TextField as MaterialInput } from '@material-ui/core';

// Styles
import styles from './style.module.scss';

export const Input = ({ className, placeholder, variant, InputProps, label, autoComplete = "on", ...props }) => {
  return (
    <Field {...props}>
      {({ input, meta }) => {
        return (
          <MaterialInput
            {...input}
            label={label}
            InputProps={InputProps}
            variant={variant}
            placeholder={placeholder}
            className={classnames(styles.Input, className)}
            error={meta.error || props.error}
            helperText={meta.error || props.error || null}
            autoComplete={autoComplete}
          />
        );
      }}
    </Field>
  );
};
