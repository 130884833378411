// Libraries
import { useContext } from 'react';
import { Form } from 'react-final-form';
import Cookies from 'js-cookie';

// Components
import { InputAdornment } from '@material-ui/core';
import { BannerContext } from '../Page';
import { Input } from '../Input/Input';
import { RadioGroup } from '../RadioGroup/RadioGroup';
import { DatePicker } from '../DatePicker/DatePicker';
import Button from '../Button';
import { ErrorPopup } from '../ErrorPopup';

// Helpers
import { isEmptyObject } from '../../helpers';
import { updateUserValidation } from './helpers';

// Hooks
import { useCustomer } from '../../hooks';
import { useSetCustomer } from './hooks';

// Constants
import { BANNER_VARIANTS, ERRORS_RU_TRANSLATIONS, USER_COOKIE, USER_SESSION } from '../../constants';

// Styles
import styles from './style.module.scss';
import { Loader } from '../Loader';

const initialRadioValues = [
  {
    label: 'Мужской пол',
    value: 'man',
  },
  {
    label: 'Женский пол',
    value: 'woman',
  },
];

const Profile = () => {
  const { user, loading } = useCustomer();
  const { handlerSetCustom, error: setCustomerError, validationErrors, removeError } = useSetCustomer();
  const setBannerData = useContext(BannerContext);

  const handlerSetBannerData = () => {
    setBannerData({
      variant: BANNER_VARIANTS.change_password,
    });
  };

  const handlerLogout = () => {
    Cookies.remove(USER_COOKIE);
    Cookies.remove(USER_SESSION);
    window.location.href = '/';
  };

  const getValidationError = (name) => {
    if (validationErrors) {
      const validationError = Object.entries(validationErrors)?.find(([key, value]) => key === name);
      return validationError?.length >= 1 ? validationError[1][0] : null;
    }

    return null;
  };

  const Content = () => (
    <div className={styles.Profile}>
      {setCustomerError && <ErrorPopup text={ERRORS_RU_TRANSLATIONS[setCustomerError] ?? setCustomerError} handlerClose={removeError} />}
      <Form
        onSubmit={handlerSetCustom}
        validate={updateUserValidation}
        initialValues={user}
        render={({ handleSubmit, submitting, pristine, errors, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Input 
                placeholder="Имя и фамилия" 
                label="Имя и фамилия" 
                name={'name'} 
                error={getValidationError('name')}
              />
              <Input 
                label="E-mail" 
                name={'email'} 
                placeholder="E-mail" 
                error={getValidationError('email')} 
              />
              <Input
                label="Пароль"
                placeholder={'Пароль'}
                type="password"
                name={'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <div className={styles.Action} onClick={handlerSetBannerData}>
                        Изменить
                      </div>
                    </InputAdornment>
                  ),
                }}
                error={getValidationError('password')}
              />
              <RadioGroup
                value={values.pol}
                placeholder={'Пол'}
                label="Пол"
                name={'pol'}
                data={initialRadioValues}
              />
              <DatePicker
                placeholder="Дата рождения"
                maxDate={new Date()}
                name={'birth_day'}
                value={values.birth_day}
              />
              <Input
                label="Адрес для доставки"
                placeholder="Адрес для доставки"
                name={'address'}
                error={getValidationError('address')}
              />
              <Button
                style={{ width: '100%', marginTop: 24, height: 48 }}
                disabled={submitting || pristine || !isEmptyObject(errors)}
              >
                Сохранить изменения
              </Button>
            </form>
          );
        }}
      />
      <button className={styles.Logout} onClick={handlerLogout}>
        Выйти из аккаунта
      </button>
    </div>
  );

  return loading
    ? <Loader />
    : <Content />
};

export default Profile;
