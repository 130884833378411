const Minus = () => (
  <svg width="15" height="2" viewBox="0 0 15 2" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.11816 1.26953H14.1699C14.4863 1.26953 14.7588 0.99707 14.7588 0.671875C14.7588 0.34668 14.4863 0.0742188 14.1699 0.0742188H1.11816C0.792969 0.0742188 0.520508 0.34668 0.520508 0.671875C0.520508 0.99707 0.792969 1.26953 1.11816 1.26953Z"
      fill="#01030C"
    />
  </svg>
);

export default Minus;
