export const convertPaymentMethodToNumber = (paymentMethod) => {
  if (paymentMethod === 'pauy') {
    return 1;
  }

  if (paymentMethod === 'cash') {
    return 2;
  }

  if (paymentMethod === 'card') {
    return 3;
  }

  return 1;
};
