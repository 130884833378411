const Close = ({ fill = '#01030C' }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="0.575195"
      y="14.3606"
      width="20.2024"
      height="1.01012"
      transform="rotate(-45 0.575195 14.3606)"
      fill={fill}
    />
    <rect
      x="14.8608"
      y="15.0748"
      width="20.2024"
      height="1.01012"
      transform="rotate(-135 14.8608 15.0748)"
      fill={fill}
    />
  </svg>
);

export default Close;
