const Star = ({ filled }) => (
  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity={filled ? 1 : 0.24}
      d="M3.92188 16.9531C4.24219 17.2031 4.64844 17.1172 5.13281 16.7656L9.26562 13.7266L13.4062 16.7656C13.8906 17.1172 14.2891 17.2031 14.6172 16.9531C14.9375 16.7109 15.0078 16.3125 14.8125 15.7422L13.1797 10.8828L17.3516 7.88281C17.8359 7.53906 18.0312 7.17969 17.9062 6.78906C17.7812 6.41406 17.4141 6.22656 16.8125 6.23438L11.6953 6.26562L10.1406 1.38281C9.95312 0.804688 9.67188 0.515625 9.26562 0.515625C8.86719 0.515625 8.58594 0.804688 8.39844 1.38281L6.84375 6.26562L1.72656 6.23438C1.125 6.22656 0.757812 6.41406 0.632812 6.78906C0.5 7.17969 0.703125 7.53906 1.1875 7.88281L5.35938 10.8828L3.72656 15.7422C3.53125 16.3125 3.60156 16.7109 3.92188 16.9531Z"
      fill="#01030C"
    />
  </svg>
);

export default Star;
