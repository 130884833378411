const Ticket = ({ fill = '#01030C' }) => (
  <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.62992 20.9669C5.82508 21.3123 6.0839 21.4499 6.46627 21.4146C7.53698 21.3335 8.27199 21.5306 9.36951 22.2446C9.68491 22.4289 9.99938 22.4336 10.3087 22.2512L23.0352 14.7444C23.337 14.5664 23.4903 14.2858 23.4917 13.9145C23.4352 12.5865 23.6357 11.8376 24.2472 10.9263C24.4684 10.6057 24.4812 10.3079 24.286 9.96252L20.3743 3.04055C20.1749 2.68769 19.9161 2.55007 19.538 2.59293C18.4597 2.67839 17.7205 2.47379 16.6272 1.76731C16.3075 1.57547 15.9931 1.57077 15.6913 1.74876L2.96475 9.25561C2.65545 9.43806 2.5022 9.71865 2.505 10.0974C2.55722 11.4179 2.36102 12.1743 1.75699 13.0812C1.5316 13.3943 1.51885 13.6921 1.71825 14.045L5.62992 20.9669ZM6.74691 20.0778L3.0304 13.5012C3.73108 12.5874 3.97585 11.6322 3.87413 10.3308L16.1481 3.09098C17.1928 3.83612 18.1324 4.09269 19.2573 3.92964L22.9738 10.5063C22.2732 11.4201 22.0208 12.3797 22.1259 13.6691L9.85194 20.909C8.80392 20.1758 7.87185 19.9148 6.74691 20.0778ZM8.83845 18.8842C9.06331 19.2821 9.48338 19.3846 9.87566 19.1532L20.6182 12.8167C21.018 12.5809 21.1336 12.1623 20.9087 11.7644L17.1582 5.12776C16.9376 4.73737 16.5209 4.62284 16.121 4.85868L5.37852 11.1952C4.98623 11.4266 4.86739 11.8571 5.088 12.2475L8.83845 18.8842ZM6.95964 13.4058L6.23416 12.122C6.17476 12.0169 6.20117 11.9212 6.31433 11.8545L7.13661 11.3694L7.95543 12.8184L6.95964 13.4058ZM11.5199 16.9722L8.04943 10.831L14.4844 7.0353L17.9548 13.1765L11.5199 16.9722ZM16.216 7.94583L15.3972 6.49687L16.2195 6.01184C16.3326 5.94509 16.4194 5.974 16.4788 6.07911L17.2043 7.3629L16.216 7.94583ZM8.28333 15.7481L7.47299 14.3142L8.46879 13.7268L9.27912 15.1607L8.28333 15.7481ZM17.5397 10.2882L16.7294 8.85424L17.7176 8.27132L18.5279 9.70526L17.5397 10.2882ZM9.51792 17.9328L8.79668 16.6565L9.79248 16.0692L10.6071 17.5106L9.78477 17.9956C9.67161 18.0624 9.58156 18.0454 9.51792 17.9328ZM18.8676 12.6381L18.0531 11.1966L19.0413 10.6137L19.7625 11.89C19.8262 12.0026 19.8031 12.0863 19.6899 12.153L18.8676 12.6381Z"
      fill={fill}
    />
  </svg>
);

export default Ticket;
