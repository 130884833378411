import { validateEmail } from '../../../helpers';

export const productValidation = (values) => {
  const errors = {};

  if (!values.title) {
    errors.title = 'Поле обязательно!';
  }
    
  if (!values.message) {
    errors.message = 'Поле обязательно!';
  }
    
  if (!values.phone) {
    errors.phone = 'Поле обязательно!';
  }
    
  if (!values.email) {
    errors.email = 'Поле обязательно!';
  }

  else if (!validateEmail(values.email)) {
    errors.email = 'Неверный формат email.';
  }

  return errors;
};
