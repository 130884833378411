// Libraries
import { useState, useEffect, createContext } from 'react';
import classnames from 'classnames';

// Components
import Header from '../Header';
import Footer from '../Footer';
import Banner from '../Banner';
import LandingSlider from '../LandingSlider';
import ToUpArrow from '../ToUpArrow';
import PopUp from '../PopUp';
import { Snackbar, Alert } from "@mui/material";
import useSnackBar from "../../hooks/useSnackBar";

// Constants
import { DEFAULT_BANNER_DATA } from '../../constants';

// Styles
import styles from './style.module.scss';


export const BannerContext = createContext(null);

const Page = ({
  children,
  title,
  classes,
  headerProps = {},
  slides,
  popUpPage,
  withHeader = true,
  withFooter = true
}) => {
  const [bannerData, setBannerData] = useState(DEFAULT_BANNER_DATA);
  const [headerScrollTheme, setHeaderScrollTheme] = useState(null);
  const { open, severity, message, handleClose } = useSnackBar();

  const bannerHasShown = !!bannerData.variant;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const onScroll = () => {
      if (document.getElementById('slider')) {
        const endOfSliderYPos = document.getElementById('slider').offsetHeight;
        const currentYPos = document.documentElement.scrollTop;

        if (currentYPos > endOfSliderYPos) {
          setHeaderScrollTheme('default')
        } else {
          setHeaderScrollTheme('black')
        }
      }
    }

    !bannerHasShown && window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <BannerContext.Provider value={setBannerData}>
      {/* <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar> */}
      {withHeader && (
        <Header
          {...headerProps}
          fixed={slides && !bannerHasShown}
          theme={bannerHasShown
            ? null
            : headerScrollTheme || headerProps?.theme
          }
        />
      )}
      <PopUp page={popUpPage} />
      <Banner {...bannerData} />
      {slides && !bannerHasShown && <LandingSlider data={slides} />}
      <div className={classnames(styles.Page, bannerHasShown && styles.Page_hide)}>
        <div className={classnames(styles.Content, classes?.content)}>
          {title && <div className={styles.Title}>{title}</div>}
          {children}
        </div>
        {withFooter && !bannerHasShown && <Footer />}
        <ToUpArrow />
      </div>
    </BannerContext.Provider>
  );
};

export default Page;
