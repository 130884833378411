import { useContext, useEffect, useState } from "react";
import { Form } from "react-final-form";

// Constants
import { BANNER_VARIANTS } from "../../../constants";

// Helpers
import { isEmptyObject } from "../../../helpers";
import { forgotPasswordValidation } from "./helpers";

// Hooks
import { useForgotCustomerPassword } from "./hooks/useForgotCustomerPassword";

// Components
import Button from "../../Button";
import { Input } from "../../Input/Input";
import { BannerContext } from "../../Page";
import { Title } from "../../PageParts";

// Styles
import styles from './style.module.scss';

const ForgotPassword = () => {
  const setBannerData = useContext(BannerContext);
  const { forgotCustomerPasswordHandler, message, status } = useForgotCustomerPassword();

  const onSubmit = ({ email }) => {
    forgotCustomerPasswordHandler(email).then((res) => {
      if(res.data.forgotCustomerPassword.status) {
        setBannerData({ 
          variant: BANNER_VARIANTS.success_popup, 
          data: { 
            title: res.data.forgotCustomerPassword.message,
            onComplete: () => {
              setBannerData({
                variant: BANNER_VARIANTS.reset_password,
                data: {
                  email
                }
              });
            }
          } 
        });
      }
    });
  };

  return (
    <>
      <div className={styles.Message}>
        {message && <div className={styles.Link}>{message}</div>}
      </div>
      <Form onSubmit={onSubmit} validate={forgotPasswordValidation}>
        {({ handleSubmit, submitting, pristine, errors }) => {
          return (
            <form onSubmit={handleSubmit} className={styles.ForgotPassword}>
              <Title className={styles.Title}>Отправить на почту код для сброса пароля</Title>
              <div className={styles.SubTitle}>
                На указанную почту отправляется 6 значный код, который используется при сбросе пароля.
                Запрашивать код можно не чаще чем 1 раз в минуту, время жизни кода - 20 мин, после чего он становится недействительным
              </div>
              <Input label="Почта" type={'text'} name={'email'} className={styles.Email}/>
              <Button style={{ width: '100%', maxWidth: '384px', height: 48 }} disabled={submitting || pristine || !isEmptyObject(errors)}>Отправить</Button>
            </form>
          )
        }}
      </Form>
    </>
  )
}

export default ForgotPassword;
