import { validateEmail } from '../../../../helpers';

export const forgotPasswordValidation = (values) => {
  const errors = {};
  const required = 'Поле обязательно!';

  if (!values.email) {
    errors.email = required;
  }

  else if (!validateEmail(values.email)) {
    errors.email = 'Неверный формат email.';
  }

  return errors;
};
