import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import RowItems from '../../../components/RowItems';
import {selectOnlyHistCategory, selectOnlyNoveltiesCategory} from '../../../selectors';
import styles from '../style.module.scss';

export const Rows = () => {
  const hitsCategory = useSelector(selectOnlyHistCategory);
  const novelties = useSelector(selectOnlyNoveltiesCategory);
  const hitsCategoryName = hitsCategory?.name.toLowerCase();
  const noveltiesName = novelties?.name.toLowerCase();
  const hitsCategoryChildren = hitsCategory?.children[0]?.name.toLowerCase();
  const noveltiesChildren = novelties?.children[0]?.name.toLowerCase();

  const ROWS = useMemo(() => [
    {
      title: 'Хиты продаж',
      link: hitsCategoryName && hitsCategoryChildren
        ? `/catalog/${hitsCategoryName}/${hitsCategoryChildren}`
        : '/',
    },
    {
      title: 'Новинки',
      link: noveltiesName && hitsCategoryChildren
        ? `/catalog/${noveltiesName}/${noveltiesChildren}`
        : '/',
    },
  ], [hitsCategoryChildren, hitsCategoryName, noveltiesChildren, noveltiesName]);

  return useMemo(() => {
    return ROWS.map(({title, link}) => (
      <div key={title} className={styles.Row}>
        <RowItems title={title} link={link}/>
      </div>
    ));
  }, [ROWS]);
};
