export const getListOrders = (orders) => {
  if (orders?.length > 0) {
    return orders.reduce((prev, curr) => {
      return [
        ...prev,
        ...curr.orderProducts.map((order) => {
          return {
            ...order,
            order_id: curr.id,
            status: curr.orderStatus,
            created_at: curr.created_at,
            sum: curr.sum,
          };
        }),
      ];
    }, []);
  }

  return [];
};
