// Components
import { useCallback } from 'react';

// Styles
import styles from './style.module.scss';


const SubCategories = ({ onChangeCategory, categories, items = [] }) => {
	const onClick = useCallback(({ parent_id, name }) => () => {
		if (categories.length) {
			if(parent_id === "0") {
				const category = categories.find((c) => c.name.toLowerCase().replace(/ /g, '_') === name.toLowerCase().replace(/ /g, '_'));
				onChangeCategory({ categoryName: category?.name, subcategoryName: category?.children[0]?.name });
			} else {
				const category = categories.find((c) => parseInt(c.id) === parseInt(parent_id));
				onChangeCategory({ categoryName: category?.name, subcategoryName: name });
			}
		}
	}, [categories]);

	return Array.isArray(items) && items.length > 0
	? (
		<div className={styles.SubCategories}>
			{items.map((item) => {
				if(item?.name) {
					return (
						<div key={item.id} className={styles.SubCategory}>
							<span onClick={onClick(item)}>{item.name}</span>
						</div>
					)
				}
			})}
		</div>
	) : <></>;
};

export default SubCategories;
