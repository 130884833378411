import { forwardRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useFilters } from '../../hooks/useFilters';
import Select from '../../components/Select';
import Button from '../../components/Button';
import { Loader } from '../../components/Loader'
import closeIcon from '../../assets/actions/close.svg';
import { selectSortColumn, selectTypeSort, selectFilterGroups } from '../../selectors';
import { setFilterGroups } from '../../actions'
import styles from './style.module.scss';

// eslint-disable-next-line react/display-name
const Filters = forwardRef(({  
	handleGetProducts, 
	categories,
	chosenFilters, 
	onChangeChosenFilters, 
	onChangeChosenFiltersIds, 
	onReset,
	isShow, 
	onHide 
}, ref) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { filters, loading } = useFilters(isShow);
	const sortColumn = useSelector(selectSortColumn);
	const sortType = useSelector(selectTypeSort);
	const filterGroups = useSelector(selectFilterGroups);
	const [isSendData, setIsSendData] = useState(false);
	const { category: categoryName } = useParams();

	useEffect(() => {
		if(categoryName) {
			dispatch(setFilterGroups(categories.find((c) => (c?.seo_url || c?.name)?.toLowerCase().replace(/ /g, '_') === categoryName)?.filterGroup?.map(item => parseInt(item.id))));
		} else {
			if(filters) {
				console.log(filters);
				dispatch(setFilterGroups(filters.map(f => parseInt(f.filters[0].group.id))));
			}
		}
		history.listen(() => {
			onChangeChosenFilters([]);
		});
	}, [history, filters, categoryName, dispatch, categories]);

	useEffect(() => {
		if (document.getElementsByTagName('jdiv')[0]) {
			document.getElementsByTagName('jdiv')[0].style.display = isShow ? "none" : "block";
		}
	}, [isShow]);

	const handleChangeChosenFilters = (field, value) => {
		let newArr = []

		if (chosenFilters.length) {
			if (chosenFilters?.filter((c) => c.field === field)[0]) {
				newArr = chosenFilters.map((c) => {
					if (c.field === field) {
						return { ...c, ...value };
					} else {
						return c;
					}
				});
			} else {
				newArr = [...chosenFilters, { field, ...value }];
			}
		} else {
			newArr = [{ field, ...value }];
		}

		onChangeChosenFilters(newArr);
	};

	useEffect(() => {
		if(isSendData) {
			handleGetProducts({ 
				sortType: { 
					value: sortType, 
					sort_column: sortColumn 
				}, 
				filters: chosenFilters.map(item => parseInt(item.value)) 
			})
			onChangeChosenFiltersIds(chosenFilters.map(item => parseInt(item.value)));
			setIsSendData(false);
			// onChangeChosenFilters([])
			onHide()
		}
	},[chosenFilters, isSendData])

	const handleSetFilters = () => {
		setIsSendData(true)
	}

	const SelectWrapper = ({ field, value, items, placeholder, index }) => (
		<div className={styles.Select}>
			<Select
				name={field}
				value={value}
				items={items}
				onChange={(value) => handleChangeChosenFilters(field, { ...value, index })}
				placeholder={
					chosenFilters?.filter((c) => c?.index === index)[0]
						? chosenFilters?.filter((c) => c?.index === index)[0]?.label
						: placeholder
				}
			/>
		</div>
	);

	return (
		<div ref={ref} className={styles.Filters}>
			<div className={styles.Wrapper}>
				<div className={styles.Header}>
					<div className={styles.Close} onClick={onHide}>
						<img src={closeIcon} alt="" />
					</div>
					<span className={styles.Title}>Фильтры</span>
					<span className={styles.ThrowOff} onClick={onReset}>
						Сбросить
					</span>
				</div>
				<div className={styles.Selects}>
					{filterGroups?.length ? (loading ? <Loader /> : filters
						.filter((f) => filterGroups?.some(i => i === parseInt(f.filters[0]?.group?.id)))
						.map((f, index) =>
							<SelectWrapper
								field={f.filters[0]?.group?.name}
								value={
									f.filters[0]?.group?.name === chosenFilters[index]?.field
										? chosenFilters[index]?.value
										: null
								}
								items={f.filters.map((f) => ({ label: f.name, value: f.id }))}
								placeholder={f.filters[0]?.group?.name}
								key={index}
								index={index}
							/>
					)) : 'Нет фильтров'}
					<Button className={styles.Button} onClick={handleSetFilters}>
						Применить фильтры
					</Button>
				</div>
			</div>
		</div>
  );
});

export default Filters;
