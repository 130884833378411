// Libraries
import { useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';

// Hooks
import { useActiveCategory } from '../hooks';
import { useCategories } from '../../../hooks';

// Actions
import { setKeyword } from '../../../actions';

// Styles
import styles from './Nav.module.scss';
import Sticker from '../../Sticker';

export const Nav = ({ setHide }) => {
  const history = useHistory();
  const dispatch = useDispatch()
  const { catalogCategories, specialOffersCategories } = useCategories();
  const { activeCategory, selectCategories } = useActiveCategory();

  const handleClick = useCallback((category) => {
    selectCategories(category)();
    const subCategories = [...catalogCategories, ...specialOffersCategories].find(c => c.id === category.id)['children'];
    if (!subCategories.length) {
      setHide();
    }
    dispatch(setKeyword(null));
  }, [catalogCategories, dispatch, history, specialOffersCategories]);

  const renderCatalogCategories = useMemo(() => {
    return catalogCategories.map((category) => {
      return (
        <span onClick={() => handleClick(category)} key={category.id}>
          <div
            className={classnames(styles.Nav__Link, {
              [styles.Nav__Link_selected]: category.id === activeCategory?.id,
            })}
          >
            {category.name}
            {category.sticker && <img src={category.sticker} alt="" />}
          </div>
        </span>
      );
    });
  }, [catalogCategories, handleClick, activeCategory]);

  const renderSpecialOffersCategories = useMemo(() => {
    return specialOffersCategories.map((category) => {
      return (
        <span onClick={() => handleClick(category)} key={category.id}>
          <div
            className={classnames(styles.Nav__Link, {
              [styles.Nav__Link_selected]: category.id === activeCategory?.id,
            })}
            onClick={selectCategories(category)}
          >
            {category.name}
            {category.name === 'Скидки' && (
              <Sticker />
            )}
          </div>
        </span>
      );
    });
  }, [specialOffersCategories, handleClick, activeCategory]);

  return (
    <div className={styles.Nav}>
      <div className={styles.Nav__Container}>
        <span className={styles.Nav__Container__title}>Спецпредложения</span>
        {renderSpecialOffersCategories}
      </div>
      <div className={styles.Nav__Container}>
        <span className={styles.Nav__Container__title}>Каталог</span>
        {renderCatalogCategories}
      </div>
    </div>
  );
};
