

export const useGetCommonPriceCart = (products = [], promoData) => {
  const getCartTotal = () => products && products.reduce((sum, { productOptionValue, quantity_selected, price, special_price }) => {
    return sum + quantity_selected * (productOptionValue ? (productOptionValue?.special_price || productOptionValue?.price) : (special_price || price))
  }, 0);

  const getCartTotalWithCertificates = () => {
    const total = getCartTotal();

    if (!promoData?.products) {
      return total;
    }

    const promoProducts = products.filter(product =>
      !(product.productOptionValue?.special_price) &&
        promoData.products.some(({ product_id, option_id }) =>
          product_id.toString() === (product.product_id || product.productId).toString()
          && (product?.productOptionValue && option_id
            ? product.productOptionValue.id.toString() === option_id.toString()
            : true
          )
        )
    );

    const sumPricePromoProducts = promoProducts.reduce(
      (sumPrice, { price, product_price, quantity_selected }) => sumPrice + (+(price ?? product_price)) * quantity_selected, 0)
    ;

    if (promoData?.coupon?.type === 'sum' && total >= parseInt(promoData.coupon.discount)) {
      return total - promoData.coupon.discount;
    } else if (promoData?.coupon?.type === "present") {
      return total - ((sumPricePromoProducts / 100) * promoData.coupon.discount);
    }

    return total;
  };

  const getCartLength = () => products.reduce((sum, { quantity_selected }) => sum + quantity_selected, 0);

  return {
    cartPrice: getCartTotalWithCertificates(),
    cartPriceWithoutDiscount: getCartTotal(),
    cartLength: getCartLength(),
  };
};
