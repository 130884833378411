import {
  SET_ACTIVE_CATEGORY,
  SET_CATEGORIES,
  SET_KEYWORD,
  SET_PRODUCT_COMMON_SIZE,
  SET_SLIDES,
  SET_SORT,
  SET_SUB_CATEGORIES,
  SET_FILTERS, ADD_CITIES,
  SET_FILTER_GROUPS
} from '../actions';
import { SET_SORT_COLUMN } from '../actions';

const initialState = {
  categories: [],
  subCategories: [],
  activeCategory: null,
  products: [],
  sort: null,
  sort_column: null,
  keyword: null,
  slides: [],
  productCommonSize: 0,
  filters: [],
  filterGroups: [],
  cities: Object.create(null),
};

const addCities = (cities) => {
  const collection = {};

  for (const city of cities) {
    collection[city.name] = { value: city.id, label: city.name };
  }
  return collection;
};

export const mainReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CITIES: {
      return {
        ...state,
        cities: addCities(action.payload),
      }
    }
    case SET_CATEGORIES: {
      return {
        ...state,
        categories: action.payload,
      };
    }
    case SET_KEYWORD: {
      return {
        ...state,
        keyword: action.payload,
      };
    }
    case SET_SUB_CATEGORIES: {
      return {
        ...state,
        subCategories: action.payload,
      };
    }
    case SET_ACTIVE_CATEGORY: {
      return {
        ...state,
        activeCategory: action.payload,
      };
    }
    case SET_SLIDES: {
      return {
        ...state,
        slides: action.payload,
      };
    }
    case SET_PRODUCT_COMMON_SIZE: {
      return {
        ...state,
        productCommonSize: action.payload,
      };
    }
    case SET_SORT: {
      return {
        ...state,
        sort: action.payload,
      };
    }
    case SET_SORT_COLUMN: {
      return {
        ...state,
        sort_column: action.payload,
      };
    }
    case SET_FILTERS:
      return {
        ...state,
        filters: action.payload
      }
    case SET_FILTER_GROUPS: 
      return {
        ...state,
        filterGroups: action.payload
      }
    default:
      return state;
  }
};
