// Hook
import { useMediaQuery } from 'react-responsive';

// Assets
import categoryIcon1 from '../assets/categories/category_1.png';
import categoryIcon2 from '../assets/categories/category_2.png';
import categoryIcon3 from '../assets/categories/category_3.png';
import categoryIcon4 from '../assets/categories/category_4.png';
import categoryIcon5 from '../assets/categories/category_5.png';
import categoryIcon6 from '../assets/categories/category_6.png';
import categoryIcon7 from '../assets/categories/category_7.png';
import discountIcon from '../assets/stickers/discount.svg';

export const API = 'https://api-test.tapshop.cc';

export const BANNER_VARIANTS = {
	delivery_and_payment: 'delivery_and_payment',
	museum_item: 'museum_item',
	excursion_item: 'excursion_item',
	change_password: 'change_password',
	remind_password: 'remind_password',
	forgot_password: 'forgot_password',
	reset_password: 'reset_password',
	remind_success: 'remind_success',
	corporate_sales: 'corporate_sales',
	cookies: 'cookies',
	process_personal_data: 'process_personal_data',
	offer: 'offer',
	return_and_exchange: 'return_and_exchange',
	warranty_and_quality: 'warranty_and_quality',
	success_popup: 'success_popup' 
};

export const DEFAULT_BANNER_DATA = {
	variant: null,
	data: null,
};

export const TICKET_VARIABLES = {
	adults: 'adults',
	children: 'children',
	preferential: 'preferential',
};

export const TICKET_TYPES = {
	[TICKET_VARIABLES.adults]: 'Взрослые',
	[TICKET_VARIABLES.children]: 'Дети',
	[TICKET_VARIABLES.preferential]: 'Льготный билет',
};

export const BASKET_ITEM_TYPES = {
	product: 'product',
	ticket: 'ticket',
	offer: 'offer',
	delivery: 'delivery',
};

export const NAV_ITEMS = {
	main: [
		{
			label: 'Дом',
			section: 1,
		},
		{
			label: 'Живопись',
			section: 2,
		},
		{
			label: 'Одежда и аксессуары',
			section: 3,
		},
		{
			label: 'Детям',
			section: 4,
		},
		{
			label: 'Книги',
			section: 5,
		},
		{
			label: 'Канцелярия',
			section: 6,
		},
		{
			label: 'Премиум',
			section: 7,
		},
		{
			label: 'В гостях у Эрмитажа',
			section: 8,
		},
	],
	sub: [
		{
			label: 'Скидки',
			section: 9,
			sticker: discountIcon,
		},
		{
			label: 'Коллекции',
			section: 10,
		},
		{
			label: 'Новинки',
			section: 11,
		},
		{
			label: 'Хиты продаж',
			section: 12,
		},
	],
};

export const SUB_CATEGORIES = [
	{
		id: 1,
		label: 'Одежда',
		icon: categoryIcon1,
	},
	{
		id: 2,
		label: 'Сумки',
		icon: categoryIcon2,
	},
	{
		id: 3,
		label: 'Дождевики',
		icon: categoryIcon3,
	},
	{
		id: 4,
		label: 'Мода музеев',
		icon: categoryIcon4,
	},
	{
		id: 5,
		label: 'Зонты',
		icon: categoryIcon5,
	},
	{
		id: 6,
		label: 'Сумки',
		icon: categoryIcon6,
	},
	{
		id: 7,
		label: 'Одежда',
		icon: categoryIcon7,
	},
	{
		id: 8,
		label: 'Одежда',
		icon: categoryIcon7,
	},
	{
		id: 9,
		label: 'Сумки',
		icon: categoryIcon6,
	},
	{
		id: 10,
		label: 'Мода музеев мира',
		icon: categoryIcon4,
	},
];

export const useResize = () => {
	const isDesktop = useMediaQuery({
		query: '(min-width: 1201px)',
	});

	const isTablet = useMediaQuery({
		query: '(max-width: 1200px)',
	});

	const isLargeMobile = useMediaQuery({
		query: '(max-width: 767px)',
	});

	const isMobile = useMediaQuery({
		query: '(max-width: 767px)',
	});

	const getMode = () => {
		if (isMobile) return 'mobile';

		if (isLargeMobile) return 'largeMobile';

		if (isTablet) return 'tablet';

		if (isDesktop) return 'desktop';
	};

	return { isDesktop, isTablet, isLargeMobile, isMobile, activeResizeMode: getMode() };
};

export const THEMES = {
	default: 'default',
	black: 'black',
};

export const USER_COOKIE = 'hermitage_user';
export const USER_SESSION = 'hermitage_session';
export const ORDERS = 'orders';
export const FAVORITES = 'favorites';
export const CATALOG = 'catalog';
export const TICKETS = 'tickets';
export const USER_CART_COOKIE = process.env.NODE_ENV === 'development' ? 'hermitage_user_cart_dev' : 'hermitage_user_cart';
export const DELIVERY_TYPES = {
	courier: 'courier',
	pickup: 'pickup',
};
export const URI = `${window.location.origin}`;

export const ERRORS_RU_TRANSLATIONS = {
	'Invalid Date Format': 'Недопустимый формат даты',
	'Date should not be after maximal date': 'Дата не должна быть больше текущей',
	'Date should not be before minimal date': 'Дата не должна быть меньше минимальной',
	'Validation error': 'Ошибка. Неправильный логин или пароль',
	'This email is already exists': 'Пользователь с такой почтой уже существует',
	'The provided credentials are incorrect': 'Предоставленные учётные данные не верны',
};
