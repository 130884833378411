import { validateEmail } from '../../../helpers';

export const updateUserValidation = (values) => {
  const errors = {};
  const required = 'Поле обязательно!';

  if (!values.name) {
    errors.name = required;
  }

  if (!values.email) {
    errors.email = required;
  }

  if (!validateEmail(values.email)) {
    errors.email = 'Неверный формат email.';
  }

  if (!values.pol) {
    errors.pol = required;
  }

  return errors;
};
