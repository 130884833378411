// Libraries
import { useEffect } from 'react';

// Components
import ProductCard from '../../../components/ProductCard';
import LoadContainer from '../../../components/LoadContainer';

// Constants
import { FAVORITES } from '../../../constants';

// Hooks
import { useFavorites } from '../../../hooks';

export const Favorites = () => {
  const { favorites, getFavorites, favoritesLoading } = useFavorites();

  useEffect(() => {
    getFavorites();
  }, []);

  const renderProductCard = (item) => {
    return item && <ProductCard id={item.id} item={item} category={item.category} />;
  };

  return (
    <LoadContainer
      type={FAVORITES}
      loading={favoritesLoading}
      items={favorites}
      component={renderProductCard}
    />
  );
};
