/* eslint-disable no-mixed-spaces-and-tabs */
import { useState } from 'react';
import { loader } from 'graphql.macro';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';

// Actions
import { addProducts, setProductCommonSize, setProducts } from '../actions';

// Selectors
import { selectCommonSize, selectTypeSort } from '../selectors';
import { selectSortColumn } from '../selectors';

const query = loader('../graphql/getProducts.graphql');

export const useGetProducts = () => {
	const productCommonSize = useSelector(selectCommonSize);
	const sortType = useSelector(selectTypeSort);
	const sortColumn = useSelector(selectSortColumn);
	const dispatch = useDispatch();
	const [page, setPage] = useState(1);
	const [fetchMoreLoading, setFetchMoreLoading] = useState(false);
	const [getProducts, { fetchMore, loading }] = useLazyQuery(query, {
		fetchPolicy: 'no-cache',
		nextFetchPolicy: 'cache-first',
		notifyOnNetworkStatusChange: true,
		onCompleted: (res) => {
			if (res?.getProducts.products && page === 1) {
				dispatch(setProducts(res.getProducts.products));
				dispatch(setProductCommonSize(res.getProducts.commonSize));
			}
		},
	});

	const handlerGetProducts = ({ 
		filters = [], 
		sort = sortType, 
		sort_column = sortColumn, 
		keyword,
		isFetchMore,
		category
	}) => {
		
		if (isFetchMore) {
			setFetchMoreLoading(true);
			fetchMore({
				variables: { 
					page: page + 1, 
					paginate: 16, 
					search: keyword, 
					category, 
					filters, 
					sort, 
					sort_column 
				},
			}).then(({ data }) => {
				if (data?.getProducts.products) {
					dispatch(addProducts(data.getProducts.products));
					setPage(page + 1);
				}
			}).finally(() => setFetchMoreLoading(false));
		} else {
			setPage(1);

			getProducts({ 
				variables: { 
					paginate: 16, 
					page: 1, 
					search: keyword, 
					category, 
					filters, 
					sort, 
					sort_column 
				} 
			});
		}
	}

	return {
		handlerGetProducts,
		fetchMoreLoading,
		loading,
		commonSize: productCommonSize
	};
};
