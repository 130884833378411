import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react'; 
import { setExcursions } from './../actions/setExcursions';
import { selectExcursions } from './../selectors/selectExcursions';

const query = loader('../graphql/getExcursions.graphql');


export const useGetExcursions = () => {
  const dispatch = useDispatch();
  const excursions = useSelector(selectExcursions);
  const { data, loading } = useQuery(query);
  
  useEffect(() => {
    if (data?.getExcursions?.excursions) {
      dispatch(setExcursions(data.getExcursions.excursions));
    }
  }, [data, dispatch, loading]);

  return {
    excursions,
    loading,
  };
}