export const updatePasswordValidation = (values) => {
  const errors = {};
  const required = 'Поле обязательно!';

  if (!values.old_password) {
    errors.old_password = required;
  }

  if (!values.password) {
    errors.password = required;
  }

  if (values.password !== values.password_confirmation) {
    errors.password_confirmation = 'Пароли должны совпадать';
  }

  return errors;
};
