import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useState } from 'react';

const forgotCustomerPasswordMutation = loader('../graphql/forgotCustomerPassword.graphql');

export const useForgotCustomerPassword = () => {
  const [data, setData] = useState(null);
  const [forgotCustomerPassword] = useMutation(forgotCustomerPasswordMutation, {
    onCompleted: (res) => {
      if (res?.forgotCustomerPassword) {
        setData(res.forgotCustomerPassword)
      }
    },
  });

  const forgotCustomerPasswordHandler = (email) => {
    return forgotCustomerPassword({ variables: { email } });
  };

  return {
    forgotCustomerPasswordHandler,
    ...data
  };
};