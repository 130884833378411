import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';

const mutation = loader('../graphql/specialOrder.graphql');

export const useSpecialOrder = () => {
  const [mutatedFunc, { data, loading, error }] = useMutation(mutation);

  const specialOrderHandler = (input) => mutatedFunc({ variables: { ...input } });

  return {
    specialOrderHandler,
    data,
    loading,
    error
  };
};
