// Libraries
import { useContext } from 'react';
import { Form } from 'react-final-form';

// Components
import { Input } from '../../Input/Input';
import Button from '../../Button';
import { Title } from '../../PageParts';
import { ErrorPopup } from '../../ErrorPopup';
import { ReactComponent as SuccessIcon } from '../../../assets/success.svg';

// Constants
import { DEFAULT_BANNER_DATA, BANNER_VARIANTS } from '../../../constants';

// Hooks
import { useUpdatePassword } from './hooks';

// Helpers
import { updatePasswordValidation } from './helpers';
import { isEmptyObject } from '../../../helpers';

// Contexts
import { BannerContext } from '../../Page';

// Styles
import styles from './style.module.scss';


const ChangePassword = () => {
  const setBannerData = useContext(BannerContext);
  const { handlerUpdatePassword, error, removeError, isSuccessChangePassword } = useUpdatePassword();

  const handlerSetBannerData = () => {
    setBannerData({
      variant: BANNER_VARIANTS.forgot_password,
    });
  };

  const closePopup = () => {
    setBannerData(DEFAULT_BANNER_DATA);
  };

  const renderSuccessMessage = () => {
    if (isSuccessChangePassword) {
      return (
        <div className={styles.SuccessChangePassword}>
          <SuccessIcon />
          <span className={styles.SuccessChangePassword__title}>Вы успешно изменили пароль</span>
          <span className={styles.SuccessChangePassword__subtitle}>
            Теперь вы можете использовать новый пароль для входа в аккаунт
          </span>
          <Button onClick={closePopup}>Готово</Button>
        </div>
      );
    }
  };

  const renderForm = () => {
    if (!isSuccessChangePassword) {
      return (
        <Form
          validate={updatePasswordValidation}
          onSubmit={handlerUpdatePassword}
          render={({ handleSubmit, submitting, pristine, errors }) => {
            return (
              <form onSubmit={handleSubmit} className={styles.ChangePassword}>
                <Title style={{marginBottom: 50}}>Изменить пароль</Title>
                {error && (
                  <ErrorPopup
                    style={{ margin: '16px 0 16px 0' }}
                    text={error}
                    handlerClose={removeError}
                  />
                )}
                <Input label="Старый пароль" type={'password'} name={'old_password'} />
                <div
                  className={styles.Link}
                  onClick={handlerSetBannerData}
                  style={{marginBottom: 28}}
                >
                  Я забыл старый пароль
                </div>
                <Input label="Новый пароль" type={'password'} name={'password'} />
                <Input
                  label="Повторите новый пароль"
                  type={'password'}
                  name={'password_confirmation'}
                />
                <Button
                  style={{
                    width: '100%',
                    height: 48,
                    marginTop: 24,
                  }}
                  disabled={submitting || pristine || !isEmptyObject(errors)}
                >
                  Сохранить изменения
                </Button>
              </form>
            );
          }}
        />
      );
    }

    return null;
  };

  return (
    <>
      {renderForm()}
      {renderSuccessMessage()}
    </>
  );
};

export default ChangePassword;
