import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import {useState} from "react";

const mutation = loader('../graphql/usePromocode.graphql');

export const usePromocode = () => {
  const [promoData, setPromoData] = useState(null);
  const [use] = useMutation(mutation, {
    onCompleted: (res) => {
      setPromoData(res?.usePromoCode);
    }
  });

  const onUsePromocode = (input) => {
    return use({ variables: { ...input } });
  };

  return {
    promoData,
    onUsePromocode,
  };
};
