import { createSelector } from 'reselect';
import { selectAllProductsFromCart } from './selectAllProductsFromCart';

const getProductById = (productId, optionId) => (products) => {
  return products?.find((item) => {
    if(item?.option_id) {
      return (parseInt(item?.productId) === parseInt(productId) || parseInt(item?.product_id) === parseInt(productId)) && parseInt(item.option_id) === parseInt(optionId)
    } else {
      return parseInt(item?.productId) === parseInt(productId) || parseInt(item?.product_id) === parseInt(productId)
    }
  });
};

export const selectProductInCartById = (productId, optionId) =>
  createSelector(selectAllProductsFromCart, getProductById(productId, optionId));
