import { loader } from 'graphql.macro';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addProductToFavorites, deleteProductFromFavorites, setFavorites } from '../actions';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { USER_COOKIE } from '../constants';

const addToFavoritesMutation = loader('../graphql/addToFavorites.graphql');
const deleteFromFavoritesMutation = loader('../graphql/deleteFromFavorites.graphql');
const getCustomerFavoritesQuery = loader('../graphql/getCustomerFavorites.graphql');

const getFavoritesState = (state) => state.productsReducer.favorites;

export const useFavorites = (productId) => {
  const history = useHistory();
  const [getCustomerFavorites, { loading }] = useLazyQuery(getCustomerFavoritesQuery, {
    onCompleted: (res) => {
      if (res?.getCustomerFavorites) {
        dispatch(setFavorites(res.getCustomerFavorites));
      }
    },
  });
  const dispatch = useDispatch();
  const [isFavorites, setIsFavorites] = useState(false);
  const [addToFavorites] = useMutation(addToFavoritesMutation, {
    onError: () => {
      deleteFromFavorites({ variables: { productId: Number(productId) } });
    },
  });
  const [deleteFromFavorites] = useMutation(deleteFromFavoritesMutation, {
    onError: () => {
      addToFavorites({ variables: { productId: Number(productId) } });
    },
  });
  const favorites = useSelector(getFavoritesState);
  const userCookie = Cookies.get(USER_COOKIE);

  const handlerGetFavorites = () => {
    if (userCookie) {
      getCustomerFavorites();
    }
  };

  const handlerAddToFavorites = (productId) => {
    dispatch(addProductToFavorites(productId));
    addToFavorites({ variables: { productId: Number(productId) } });
  };

  const handlerDeleteFromFavorites = (productId) => {
    dispatch(deleteProductFromFavorites(productId));
    deleteFromFavorites({ variables: { productId: Number(productId) } });
  };

  const handlerFavorites = (productId) => (e) => {
    e.preventDefault();

    if (!userCookie) {
      history.push('/auth');
      return;
    }

    if (!isFavorites) {
      handlerAddToFavorites(productId);
    } else {
      handlerDeleteFromFavorites(productId);
    }
  };

  const findFavorites = useCallback(
    (productId) => {
      const favoritesItem = favorites.find((item) => item.id === String(productId));
      if (favoritesItem) {
        setIsFavorites(true);
      } else {
        setIsFavorites(false);
      }
    },
    [favorites],
  );

  useEffect(() => {
    if (productId) {
      findFavorites(productId);
    }
  }, [favorites, findFavorites, productId]);

  return {
    handlerFavorites,
    getFavorites: handlerGetFavorites,
    favorites,
    isFavorites,
    favoritesLoading: loading,
  };
};
