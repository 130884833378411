// Libraries
import moment from 'moment';

// Components
import Divider from '../../Divider';
import ConnectWithSupport from '../../ConnectWithSupport';
import Contacts from '../../Contacts';
import { Loader } from '../../Loader';
import Button from '../../Button';
import { TextCropping } from '../../TextCropping';

// Hooks
import { useGetExcursionById } from '../../../hooks/useGetExcursionById';
import { usePaymentExcursion } from '../../../hooks';

// Constants
import { useResize } from '../../../constants';

// Styles
import styles from './style.module.scss';
import { formatPrice } from '../../../helpers/formatPrice';

const ExcursionItem = ({ data = {} }) => {
  const { isMobile } = useResize();
  const { excursion, loading }= useGetExcursionById(data.id);
  const { buyExcursionHandler, payment } = usePaymentExcursion();

  const {
    image,
    title,
    description,
    price,
    dateOfStart = moment().subtract(5, 'days').format('DD'),
    dateOfEnd = moment().format('DD'),
  } = excursion;

  if (loading) {
    return <Loader style={{ margin: '125px 50%' }} />;
  }

  const Row = () => (
    <div className={styles.Row}>
      <Divider />
      <div className={styles.Row__Content}>
        <div className={styles.Text}>
          {formatPrice(price)}
        </div>
      </div>
      <Divider />
    </div>
  );

  const Preview = () => (
    <div className={styles.Preview}>
      <img src={image} alt="" />
    </div>
  );

  const BottomInfo = () => (
    <>
      <Divider />
      <div className={styles.BottomInfo}>
        <div className={styles.Contacts}>
          <Contacts />
        </div>
        {isMobile && <Divider />}
        <div className={styles.ContactWithSupport}>
          <ConnectWithSupport />
        </div>
      </div>
    </>
  );

  const BottomPanel = () => (
    <div className={styles.BottomPanel}>
      <div className={styles.Total}>
        <div className={styles.Heading}>Итого к оплате</div>
        <div className={styles.Price}>
          {formatPrice(price)}
        </div>
      </div>
      <Button filled disabled={price === 0} className={styles.Buy} onClick={buyExcursionHandler(data.id)}>
        Купить
      </Button>
    </div>
  );

  return (
    <>
      <div className={styles.Name}>{title}</div>
      <Row />
      <div className={styles.About}>
        <TextCropping text={description}/>
      </div>
      <Preview />
      <BottomPanel />
      <BottomInfo />
    </>
  );
};

export default ExcursionItem;
