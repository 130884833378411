import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { setFooterData } from '../actions/setFooterData';

const query = loader('../graphql/getFooterInfo.graphql');


export const useGetFooterData = () => {
  const dispatch = useDispatch();

  const { loading } = useQuery(query, {
    onCompleted: (res) => {
      if (res?.getFooterInfo) {
        dispatch(setFooterData(res.getFooterInfo));
      }
    },
  });

  return {
    loading,
  };
}