export const getShippingMethod = (delivery, courier_place) => {
  if (delivery.type === 'courier' && courier_place === 'toDoor') {
    return 1;
  }

  if (delivery.type === 'courier' && courier_place === 'toPoint') {
    return 2;
  }

  if (delivery.type === 'pickup') {
    return 3;
  }

  return 2;
};
