import { createSelector } from "reselect";

export const selectExcursions = createSelector(
  state => state.excursionsReducer,
  excursionsReducer => excursionsReducer.excursions
);

export const selectExcursion = createSelector(
  state => state.excursionsReducer,
  excursionsReducer => excursionsReducer.excursion
);

export const selectPaymentExcursion = createSelector(
  state => state.excursionsReducer,
  excursionsReducer => excursionsReducer.payment
);
