// Components
import { Link } from 'react-router-dom';
import { Title, SubTitle } from '../../PageParts';

// Hooks
import { useFooterData } from '../../../hooks/useFooterData';

// Styles
import styles from './style.module.scss';

const ReturnAndExchange = () => {
  const { subInfoForBanners } = useFooterData();

  return (
    <div>
      <Title className={styles.Title}>Возврат и обмен</Title>
      <div dangerouslySetInnerHTML={{ __html: subInfoForBanners?.text_return_exchange }} />
    </div>
  );
};

export default ReturnAndExchange;
