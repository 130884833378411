import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';

const mutation = loader('../graphql/createOrder.graphql');

export const useCreateOrder = () => {
  const [createOrder] = useMutation(mutation);

  const createOrderHandler = (input) => {
    return createOrder({ variables: { input } });
  };

  return {
    createOrderHandler,
  };
};
