import Cookies from 'js-cookie';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useDispatch, useSelector } from 'react-redux';
import { USER_COOKIE } from '../constants';
import { selectPaymentExcursion } from '../selectors';
import { useHistory } from 'react-router-dom';
import { setExcursionPayment } from '../actions/setExcursions';

const buyExcursionMutation = loader('../graphql/buyExcursion.graphql');

export const usePaymentExcursion = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isAuth = !!Cookies.get(USER_COOKIE);
  const payment = useSelector(selectPaymentExcursion);
  const [buyExcursion] = useMutation(buyExcursionMutation, {
    onCompleted: (res) => {
      if (res?.paymentExcursion) {
        dispatch(setExcursionPayment(res.paymentExcursion));
        window.location.href = res.paymentExcursion.payment_link;
      }
    },
  });

  const buyExcursionHandler = (excursionId) => () => {
    if (isAuth) {
      buyExcursion({ variables: { excursionId } });
    } else {
      history.push('/auth');
    }
  };

  return { buyExcursionHandler, payment };
};