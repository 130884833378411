import { useMutation } from '@apollo/client';
import Cookies from 'js-cookie';
import { loader } from 'graphql.macro';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { format } from 'date-fns';
import { USER_CART_COOKIE, USER_COOKIE } from '../../../constants';

const mutation = loader('../graphql/registerCustomer.graphql');
const addProductMutation = loader('../../../graphql/addProductToCart.graphql');

export const useRegisterCustomer = () => {
  const userCartCookie = null;
  const [addProductToCart] = useMutation(addProductMutation);
  const [registerCustomer] = useMutation(mutation, {
    onCompleted: (res) => {
      if (res?.registerCustomer && res.registerCustomer.access_token) {
        const user = {
          access_token: res.registerCustomer.access_token,
          customer: res.registerCustomer.customer,
        };

        if (userCartCookie) {
          Object.values(JSON.parse(userCartCookie)).forEach((product) => {
            addProductToCart({ variables: { ...product, quantity_selected: 1 } });
          });
        }

        setError(null);
        Cookies.set(USER_COOKIE, JSON.stringify(user), { expires: 365 });
        localStorage.removeItem(USER_CART_COOKIE);
        history.push('/lk');
      } else {
        setError(res.registerCustomer.message);
      }
    },
  });
  const history = useHistory();
  const [error, setError] = useState(null);

  const handlerRegisterCustomer = (values) => {
    let firstname = '',
      lastname = '';

    if (values.customerNameAndLastName) {
      const array = values.customerNameAndLastName.split(' ');
      firstname = array[0];
      lastname = array[1];
    }

    const newCustomer = {
      firstname,
      lastname,
      email: values.email,
      password: values.password,
      password_confirmation: values.password_confirmation,
      pol: values.sex === 'man' ? 0 : 1,
      birth_day: format(new Date(values.birthday), 'yyyy-MM-dd'),
    };

    registerCustomer({ variables: { input: newCustomer } }).then();
  };

  const removeError = () => {
    setError(null);
  };

  return {
    handlerRegisterCustomer,
    error,
    removeError,
  };
};
