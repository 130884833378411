// Components
import Divider from '../Divider';
// import Counter from '../Counter';
import Button from '../Button';

// Constants
import { TICKET_TYPES } from '../../constants';

// Styles
import styles from './style.module.scss';
import { formatPrice } from '../../helpers/formatPrice';

const TicketsSelector = ({ data = [], onChange }) => {
  // const typesMap = data.reduce((reducer, item) => ({ ...reducer, [item.type]: item.count }), {});
  const totalPrice = data.reduce((sum, { count, price }) => sum + count * price, 0);

  const Row = ({ type, count, price }) => (
    <div className={styles.Row}>
      <Divider />
      <div className={styles.Content}>
        <div className={styles.Type}>{TICKET_TYPES[type]}</div>
        <div className={styles.Values}>
          <div className={styles.Price}>
            {formatPrice(price)}
          </div>
          {/*<Counter*/}
          {/*  value={count}*/}
          {/*  onChange={(dif) =>*/}
          {/*    onChange({*/}
          {/*      ...typesMap,*/}
          {/*      [type]: count + dif,*/}
          {/*    })*/}
          {/*  }*/}
          {/*/>*/}
        </div>
      </div>
    </div>
  );

  const Selector = () => (
    <>
      <div className={styles.Title}>Выберите тип билета</div>
      {data.map((item) => (
        <Row {...item} />
      ))}
      <Divider />
    </>
  );

  const BottomPanel = () => (
    <div className={styles.BottomPanel}>
      <div className={styles.Total}>
        <div className={styles.Heading}>Итого к оплате</div>
        <div className={styles.Sum}>
          {formatPrice(totalPrice)}
        </div>
      </div>
      <Button filled disabled={totalPrice === 0}>
        Добавить в корзину
      </Button>
    </div>
  );

  return (
    <div className={styles.TicketsSelector}>
      <Selector />
      <BottomPanel />
    </div>
  );
};

export default TicketsSelector;
