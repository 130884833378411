import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';

// Actions
import { addProductToCart as addProductToCartAction } from '../actions';

// Constants
import { USER_CART_COOKIE, USER_COOKIE } from '../constants';

// Selectors
import { selectAllProductsFromCart, selectProductFromProductsById } from '../selectors';
import useSnackBar from "./useSnackBar";

// Mutations
const addProductToCartMutation = loader('../graphql/addProductToCart.graphql');


export const useAddProductToCart = (productId) => {
  const dispatch = useDispatch();
  const user = Cookies.get(USER_COOKIE);
  const productsFromCart = useSelector(selectAllProductsFromCart);
  const productFromProducts = useSelector(selectProductFromProductsById(productId));
  const [addProductToCart, { loading }] = useMutation(addProductToCartMutation);
  const { handleChange } = useSnackBar();

  useEffect(() => {
      localStorage.setItem(USER_CART_COOKIE, JSON.stringify(productsFromCart));
  }, [productsFromCart]);

  const addProductToCartHandler = (product) => {
    const newProduct = { ...productFromProducts, ...product };
    newProduct.price = Number(newProduct.price).toFixed(0);
    newProduct.special_price = newProduct.special_price ? Number(newProduct.special_price).toFixed(0) : null;

    if (product?.option_value_id) {
      newProduct.option_id = product.option_value_id;
      newProduct.productOptionValue = newProduct?.productOptionValues?.find(({ id }) => id?.toString() === product?.option_value_id?.toString());
    }

    delete newProduct['description'];
    
    if (user) {
      addProductToCart({ variables: { ...product, price: product.price, quantity: 1 } }).then(() => {
        dispatch(addProductToCartAction(newProduct));
      });
    } else {
      dispatch(addProductToCartAction(newProduct));
    }

    handleChange({ open: true, severity: 'success', message: 'Товар добавлен в корзину' });
  };

  return { addProductToCartHandler, addProductToCartLoading: loading };
};
