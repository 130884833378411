const Basket = () => (
  <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.27441 13.6465H17.5312C17.874 13.6465 18.1729 13.3652 18.1729 12.9873C18.1729 12.6094 17.874 12.3281 17.5312 12.3281H7.42383C6.92285 12.3281 6.61523 11.9766 6.53613 11.4404L6.39551 10.5176H17.5488C18.832 10.5176 19.4912 9.72656 19.6758 8.46973L20.3789 3.82031C20.3965 3.70605 20.4141 3.56543 20.4141 3.48633C20.4141 3.06445 20.0977 2.77441 19.6143 2.77441H5.26172L5.09473 1.6582C5.00684 0.981445 4.76074 0.638672 3.86426 0.638672H0.779297C0.427734 0.638672 0.128906 0.946289 0.128906 1.29785C0.128906 1.6582 0.427734 1.96582 0.779297 1.96582H3.75L5.15625 11.6162C5.34082 12.8643 6 13.6465 7.27441 13.6465ZM18.9199 4.10156L18.2959 8.31152C18.2256 8.85645 17.9355 9.19043 17.417 9.19043L6.20215 9.19922L5.45508 4.10156H18.9199ZM7.96875 17.918C8.75977 17.918 9.39258 17.2939 9.39258 16.4941C9.39258 15.7031 8.75977 15.0703 7.96875 15.0703C7.16895 15.0703 6.53613 15.7031 6.53613 16.4941C6.53613 17.2939 7.16895 17.918 7.96875 17.918ZM16.1865 17.918C16.9863 17.918 17.6191 17.2939 17.6191 16.4941C17.6191 15.7031 16.9863 15.0703 16.1865 15.0703C15.3955 15.0703 14.7539 15.7031 14.7539 16.4941C14.7539 17.2939 15.3955 17.918 16.1865 17.918Z"
      fill="#01030C"
    />
  </svg>
);

export default Basket;
