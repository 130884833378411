// Libraries
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { MuiThemeProvider as ThemeProvider, createTheme } from '@material-ui/core';

// Service
import history from './history';

// Pages
import Landing from './pages/Landing';
import Search from './pages/Search';
import Product from './pages/Product';
import Tickets from './pages/Tickets';
import About from './pages/About';
import PersonalArea from './pages/PersonalArea';
import Faq from './pages/Faq';
import Basket from './pages/Basket';
import Ordering from './pages/Ordering';
import Catalog from './pages/Catalog';
import OrderAccepted from './pages/OrderAccepted';
import OrderRejected from './pages/OrderRejected';
import Auth from './pages/Auth';
import NotFound from './pages/404';
import Excursions from './pages/Excursions';
import Terms from './pages/Terms';

// Styles
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-circular-progressbar/dist/styles.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#CF086E',
    },
    secondary: {
      main: '#CF086E',
    },
  },
});

const ROUTES = [
  {
    path: '/search',
    component: Search,
  },
  {
    path: '/terms',
    component: Terms
  },
  {
    path: '/product/:id',
    component: Product,
  },
  {
    path: '/tickets',
    component: Tickets,
  },
  {
    path: '/excursions',
    component: Excursions,
  },
  {
    path: '/about',
    component: About,
  },
  {
    path: '/lk',
    component: PersonalArea,
  },
  {
    path: '/faq',
    component: Faq,
  },
  {
    path: '/basket',
    component: Basket,
  },
  {
    path: '/ordering',
    component: Ordering,
  },
  {
    path: '/catalog/:category/:subCategory',
    component: Catalog,
  },
  {
    path: '/catalog/:category',
    component: Catalog,
  },
  {
    path: '/catalog',
    component: Catalog,
  },
  {
    path: '/order_accepted',
    component: OrderAccepted,
  },
  {
    path: '/order_rejected',
    component: OrderRejected,
  },
  {
    path: '/auth',
    component: Auth,
  },
];

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter history={history} basename={process.env.PUBLIC_URL}>
        <Switch>
          <Route exact path="/" component={Landing} />
          {ROUTES.map((route) => (
            <Route key={route.path} {...route} />
          ))}
          <Route exact path="" component={NotFound} />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
