import { Link } from 'react-router-dom';

// Components
import Button from '../../Button';

import styles from './Offer.module.scss';

export const Offer = ({ product, events }) => {
  return (
    <div className={styles.Offer}>
      <div className={styles.Category}>{product?.category}</div>
      <Link to={`/product/${product.product_id}`} className={styles.Name}>{product?.name}</Link>
      <div className={styles.Info}>{product?.info}</div>
      <div className={styles.Prices}>
        <div className={styles.Price}>{product?.product_price}</div>
        {product?.oldPrice && (
          <>
            <div className={styles.OldPrice}>{product?.oldPrice}</div>
            <div className={styles.Discount}>
              -{Math.ceil((product?.price / product?.oldPrice - 1) * -100)}%
            </div>
          </>
        )}
      </div>
      <div className={styles.OfferActions}>
        <Button
          filled
          style={{ height: 40, marginRight: 12 }}
          onClick={events.addItem}
          className={styles.Button}
        >
          Добавить в корзину
        </Button>
        <Button onClick={events.deleteItem} className={styles.Button}>
          Отказаться
        </Button>
      </div>
    </div>
  );
};
