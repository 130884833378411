// Libraries
import classnames from 'classnames';

// Components
import { Link as RouterLink } from 'react-router-dom';

// Styles
import styles from './style.module.scss';

const Link = ({ ...props }) => {
  const { children, className, to, alwaysLighting, disabled } = props;

  const classes = classnames(styles.Link, className, alwaysLighting && styles.Link_lighting,
    disabled && styles.Disabled);

  if (disabled) {
    return <RouterLink to={'#'} className={classes}>{children}</RouterLink>
  }

  return (
    <>
      {to ? (
        <RouterLink className={classes} to={to}>
          {children}
        </RouterLink>
      ) : (
        <div className={classes}>{children}</div>
      )}
    </>
  );
};

export default Link;
