import { useSelector, useDispatch } from 'react-redux';
import { addToProductsHistory } from '../actions';
import { selectProductsHistory } from '../selectors';

export const useProductsHistory = id => {
  const dispatch = useDispatch();
  const productsHistory = useSelector(selectProductsHistory);
  const productHistory = typeof productsHistory === 'object'
    && Object.prototype.hasOwnProperty.call(id)
    && productsHistory[id];
  const addProductToHistory = categoryId => dispatch(addToProductsHistory({ id, categoryId }));

  return {
    productHistory,
    addProductToHistory
  };
};
