import {
  ADD_PRODUCT_TO_FAVORITES,
  DELETE_PRODUCT_FROM_FAVORITES,
  SET_FAVORITES,
  SET_PRODUCTS,
  SET_PRODUCT_CART,
  ADD_PRODUCT_TO_CART,
  DELETE_PRODUCT_FROM_CART,
  UPDATE_PRODUCT_CART,
  ADD_NEW_PRODUCT,
  ADD_PRODUCTS,
  CLEAR_PRODUCT_FROM_CART,
  SET_CART_PRICE,
  SET_PRODUCT
} from '../actions';
import { USER_CART_COOKIE } from '../constants';
import { ADD_TO_PRODUCTS_HISTORY } from '../actions/addToProductsHistory';

const userCartCookie = localStorage.getItem(USER_CART_COOKIE);

const initialState = {
  products: new Map(),
  favorites: [],
  cart: JSON.parse(userCartCookie || '[]'),
  cartPrice: 0,
  history: {}
};

const addProductToFavorites = (state = initialState, productId) => {
  if (state.products.has(String(productId))) {
    const product = state.products.get(String(productId));
    return [...state.favorites, product];
  }

  return state.favorites;
};

const deleteProductFromFavorites = (state, productId) => {
  return state.favorites.filter((item) => item.id !== String(productId));
};

const generateCartCollection = (cart, newCart) => {
  let collection = [];

  if (collection.length > 0) {
    cart.forEach((item) => {
      collection[item.product_id] = item;
    });
  }

  if (newCart instanceof Array) {
    newCart.forEach((item) => {
      collection[item.product_id] = item;
    });
  }

  if (newCart instanceof Object) {
    collection = newCart;
  }

  return collection;
};

const generateProductCollection = (oldProducts, products) => {
  let collection = new Map();

  products.forEach((item) => {
    collection.set(item.id, item);
  });

  return collection;
};

const addProductsToCollection = (oldProducts, products) => {
  let collection = new Map(oldProducts);

  products.forEach((item) => {
    if (!collection.has(item.id)) {
      collection.set(item.id, item);
    }
  });

  return collection;
};


const deleteProductFromCart = (cart, payload) => {
  const newCart = cart.filter((item) => {
    if(payload?.optionId) {
      return parseInt(item?.option_id) !== parseInt(payload?.optionId);
    } else {
      return parseInt(item?.product_id) !== parseInt(payload.productId) && parseInt(item?.productId) !== parseInt(payload.productId);
    }
  });

  localStorage.setItem(USER_CART_COOKIE, JSON.stringify(newCart));

  return newCart;
};

const addProductToCart = (cart = [], product) => {
  let newProduct = product;

  if(newProduct?.produtId) {
    newProduct = { ...newProduct, product_id: newProduct.produtId };
    delete newProduct.productId;
  }
  const newCart = [...cart, newProduct];
  localStorage.setItem(USER_CART_COOKIE, JSON.stringify(newCart));
  if (newCart.length === 0) {
    localStorage.removeItem(USER_CART_COOKIE);
  }

  return newCart;
};

const updateProductInCart = (cart, product) => {
  const newCart = [...cart];
  const key = product?.product_id ? 'product_id' : 'productId';
  const foundIndex = newCart.findIndex(item => {
    const option = item?.productOptionValue;

    if (option) {
      return item[key] === product[key] && item.productOptionValue.id === product.productOptionValue.id
    }

    return item[key] === product[key];
  });

  newCart[foundIndex] = {
    ...newCart[foundIndex],
    quantity_selected: product.quantity_selected
  };

  return newCart;
};

const addNewProduct = (products, newProduct) => {
  let collection = new Map(products);
  collection.set(newProduct.id, newProduct);
  return collection;
};

export const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_PRODUCTS_HISTORY: {
      return {
        ...state,
        history: {
          ...state.history,
          [action.payload.id]: {
            categoryId: action.payload.categoryId
          }
        }
      }
    }

    case ADD_NEW_PRODUCT: {
      return {
        ...state,
        products: addNewProduct(state.products, action.payload),
      }
    }
    case SET_PRODUCTS: {
      return {
        ...state,
        products: generateProductCollection(state.products, action.payload),
      };
    }
    case SET_FAVORITES: {
      return {
        ...state,
        favorites: action.payload,
      };
    }
    case ADD_PRODUCT_TO_FAVORITES: {
      return {
        ...state,
        favorites: addProductToFavorites(state, action.payload),
      };
    }
    case DELETE_PRODUCT_FROM_FAVORITES: {
      return {
        ...state,
        favorites: deleteProductFromFavorites(state, action.payload),
      };
    }
    case SET_PRODUCT_CART: {
      return {
        ...state,
        cart: generateCartCollection(state.cart, action.payload),
      };
    }
    case ADD_PRODUCT_TO_CART: {
      return {
        ...state,
        cart: addProductToCart(state.cart, action.payload),
      };
    }
    case DELETE_PRODUCT_FROM_CART: {
      return {
        ...state,
        cart: deleteProductFromCart(state.cart, action.payload),
      };
    }
    case UPDATE_PRODUCT_CART: {
      return {
        ...state,
        cart: updateProductInCart(state.cart, action.payload),
      };
    }
    case CLEAR_PRODUCT_FROM_CART: {
      return {
        ...state,
        cart: []
      }
    }
    case SET_CART_PRICE: {
      return {
        ...state,
        cartPrice: action.payload
      }
    }
    case ADD_PRODUCTS: {
      return {
        ...state,
        products: addProductsToCollection(state.products, action.payload)
      }
    }
    case SET_PRODUCT: {
      return {
        ...state,
        cart: generateCartCollection(state.cart, action.payload),
      };
    }
    default:
      return state;
  }
};
