import { useSelector } from 'react-redux';

const getProductsState = (state) => state.productsReducer.products;
const getCommonSize = (state) => state.mainReducer.productCommonSize;

export const useProducts = () => {
  const products = useSelector(getProductsState);
  const commonSize = useSelector(getCommonSize);

  return {
    products: products || [],
    commonSize,
  };
};
