// Components
import { createTheme, FormLabel, MuiThemeProvider } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Field } from 'react-final-form';
import ruLocale from "date-fns/locale/ru";
import format from "date-fns/format";

//Constants
import { ERRORS_RU_TRANSLATIONS } from '../../constants';

const ERRORS = Object.values(ERRORS_RU_TRANSLATIONS);

const theme = createTheme({
  overrides: {
    MuiTextField: {
      root: {
        verticalAlign: "middle",
        fontSize: "12px",
        width: 150,
        "& fieldset": {
          border: "solid 2px #0070D8"
        }
      }
    },
    MuiIconButton: {
      label: {
        color: 'inherit'
      }
    },
    MuiPickersDay: {
      day: {
        color: '#000'
      },
      current: {
        color: '#000',
      },
      dayDisabled: {
        color: 'rgba(0, 0, 0, 0.54)'
      },
    }
  }
});

class RuLocalizedUtils extends DateFnsUtils {
  getCalendarHeaderText(date) {
    return format(date, "LLLL YYY", { locale: this.locale });
  }

  getDatePickerHeaderText(date) {
    return format(date, "dd MMMM", { locale: this.locale });
  }
}



export const DatePicker = ({
  name,
  value,
  placeholder,
  style,
  ...props
}) => {
  return (
    <Field name={name}>
      {({ input }) => {
        return (
          <MuiThemeProvider theme={theme}>
            <MuiPickersUtilsProvider locale={ruLocale} utils={RuLocalizedUtils}>
              {placeholder && (
                <FormLabel component="legend" style={{ fontSize: '12px', marginBottom: 10 }}>
                  {placeholder}
                </FormLabel>
              )}
              <KeyboardDatePicker
                {...props}
                {...input}
                value={value}
                format="dd.MM.yyyy"
                mask="__.__.____"
                placeholder="День.Месяц.Год"
                cancelLabel="отмена"
                style={{ width: '100%', marginBottom: 24, ...style, color: '#fff' }}
                maxDate={Date.now()}
                minDateMessage={ERRORS[2]}
                maxDateMessage={ERRORS[1]}
                invalidDateMessage={ERRORS[0]}
              />
            </MuiPickersUtilsProvider>
          </MuiThemeProvider>
        );
      }}
    </Field>
  );
};
