import classNames from "classnames";
import { useContext } from "react";
import { Form } from "react-final-form";

// Hooks
import { useResetCustomerPassword } from "./hooks/useResetCustomerPassword";

// Helpers
import { resetPasswordValidation } from "./helpers";
import { isEmptyObject } from "../../../helpers";

// Componennts
import { BANNER_VARIANTS } from "../../../constants";
import { Title } from "../../PageParts";
import { BannerContext } from "../../Page";
import { Input } from "../../Input/Input";
import Button from "../../Button";

// Styles
import styles from './style.module.scss';

const ResetPassword = ({ data }) => {
  const setBannerData = useContext(BannerContext);
  const { resetCustomerPasswordHandler, status, message } = useResetCustomerPassword();

  const onSubmit = (formData) => {
    resetCustomerPasswordHandler({ ...formData, email: data?.email }).then((res) => {
      if(res.data.resetCustomerPassword.status) {
        setBannerData({ 
          variant: BANNER_VARIANTS.success_popup, 
          data: { 
            title: res.data.resetCustomerPassword.message,
            subtitle: 'Теперь вы можете использовать новый пароль для входа в аккаунт'
          } 
        });
      }
    });
  };

  return (
    <>
      <div className={classNames({
        [styles.Message]: true,
        [styles.Error]: !status
      })}>
        {message}
      </div>
      <Form onSubmit={onSubmit} validate={resetPasswordValidation}>
        {({ handleSubmit, submitting, pristine, errors }) => {
          return (
            <form onSubmit={handleSubmit} className={styles.ForgotPassword}>
              <Title className={styles.Title}>Сбросить пароль на новый</Title>
              <Input label="Код, который пришел на почту" type={'text'} name={'token'} className={styles.Input}/>
              <Input label="Новый пароль" type={'password'} name={'password'} className={styles.Input}/>
              <Input
                label="Повторите новый пароль"
                type={'password'}
                name={'password_confirmation'}
                className={styles.Input}
                autoComplete="new-password"
              />
              <Button disabled={submitting || pristine || !isEmptyObject(errors)}>Отправить</Button>
            </form>
          )
        }}
      </Form>
    </>
  )
}

export default ResetPassword;
