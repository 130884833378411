import { Fragment } from 'react';
// Components
import Link from '../Link';

// Assets
import rightArrowIcon from '../../assets/actions/rightArrow.svg';

// Styles
import styles from './style.module.scss';

const MOCK = [
  {
    title: 'Главная',
    to: '/',
  },
  {
    title: 'Каталог товаров',
    to: '/catalog',
  },
  {
    title: 'Одежда и аксессуары',
    to: '',
  },
];

const Breadcrumbs = ({ data = MOCK }) => {
  return (
    <div className={styles.Breadcrumbs}>
      {data.map(({ title, to, onClick }, i) => !!title && (onClick ? (
        <Fragment key={title}>
          <div onClick={onClick} className={styles.Link}>
            {title}
          </div>
          {i !== data.length - 1 && <img src={rightArrowIcon} className={styles.Arrow} alt='' />}
        </Fragment>
      ) : (
        <Fragment key={title}>
          <Link to={to} exact className={styles.Link}>
            {title}
          </Link>
          {i !== data.length - 1 && <img src={rightArrowIcon} className={styles.Arrow} alt='' />}
        </Fragment>
      )))}
    </div>
  );
};

export default Breadcrumbs;
