// Styles
import styles from './style.module.scss';

const Contacts = () => {
  return (
    <div className={styles.Contacts}>
      <div className={styles.Title}>Адрес и часы работы</div>
      <div className={styles.Item}>
        <div className={styles.Heading}>Адрес</div>
        <div className={styles.Text}>Дворцовая площадь, 6-8</div>
      </div>
      <div className={styles.Item}>
        <div className={styles.Heading}>Станция метро</div>
        <div className={styles.Text}>Адмиралтейская</div>
      </div>
      <div className={styles.Item}>
        <div className={styles.Heading}>График работы</div>
        <div className={styles.Text}>Пн–Пт, 12:00–19:30</div>
      </div>
    </div>
  );
};

export default Contacts;
