import { SET_EXCURSION, SET_EXCURSIONS, SET_EXCURSION_PAYMENT } from "../actions/setExcursions";

const initialState = {
  excursions: [],
  excursion: {},
  payment: {},
}

export const excursionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EXCURSIONS: {
      return {
          ...state,
          excursions: action.payload.excursions
      }
    }
    case SET_EXCURSION: {
      return {
          ...state,
          excursion: action.payload.excursion
      }
    }
    case SET_EXCURSION_PAYMENT: {
      return {
          ...state,
          payment: action.payload.payment
      }
    }
    default:
      return state
  }
};
