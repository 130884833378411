// Libraries
import { useContext, useEffect, useState } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import classNames from 'classnames';

// Components
import Link from '../../Link';
import Logo from '../../../assets/components/Logo';
import BurgerIcon from '../../../assets/components/Burger';
import CloseBurgerIcon from '../../../assets/components/Close';
import TicketIcon from '../../../assets/components/Ticket';
import VRIcon from '@mui/icons-material/PanoramaPhotosphere';
import IconButton from '../../IconButton';
import SearchIcon from '../../../assets/components/Search';
import BasketIcon from '../../../assets/components/Basket';
import ProfileIcon from '../../../assets/components/Profile';
import Search from '../../Search';
import { Links } from './Links';
import Arrow from "../../../assets/components/Arrow";

// Actions
import { setKeyword } from '../../../actions';

// Hooks
import { useCart, useGetCommonPriceCart } from '../../../hooks';
import { DEFAULT_BANNER_DATA, THEMES, USER_COOKIE, useResize } from '../../../constants';
import { useMediaQuery } from 'react-responsive';
import { useDispatch } from 'react-redux';

// Context
import { BannerContext } from '../../Page';

// Styles
import styles from '../style.module.scss';


const COLORS_OF_ACTIONS = {
  [THEMES.default]: '#01030C',
  [THEMES.black]: '#fff',
};

export const HeaderContent = ({ showMenu, showSearch, setShowMenu, setShowSearch, theme }) => {
  const isTablet = useMediaQuery({
    query: '(max-width: 1200px)',
  });
  const dispatch = useDispatch();
  const [showMuseumMenu, setShowMuseumMenu] = useState(false);
  const setBannerData = useContext(BannerContext);
  const { products, getProductCart, loading } = useCart();
  const { cartLength } = useGetCommonPriceCart(products);
  const [hoverIcon1, setHoverIcon1] = useState(false);
  const [hoverIcon2, setHoverIcon2] = useState(false);
  const { isDesktop, isLargeMobile } = useResize();
  const userCookie = Cookies.get(USER_COOKIE);
  const hasBlackTheme = theme === THEMES.black;
  const history = useHistory();

  useEffect(() => {
    if (userCookie && products?.length === 0) {
      getProductCart();
    }
  }, []);

  const iconButtonColor = hasBlackTheme ? 'white' : 'black';

  const onShowAllProducts = () => {
    dispatch(setKeyword(null));


    if (history.location.pathname !== '/catalog' 
      && history.location.pathname !== '/catalog/'
      ) {
        history.push('/catalog');
    }
  };

  return (
    <div className={styles.Wrapper}>
      <div className={styles.Main}>
        <div onClick={() => setBannerData(DEFAULT_BANNER_DATA)}>
          <Link
            to="/"
            className={styles.Logo}
          >
            <Logo forBlackTheme={hasBlackTheme} />
          </Link>
        </div>
        <div className={styles.Nav}>
          <div className={styles.Row}>
            <div className={styles.Burger} onClick={() => setShowMenu((prev) => !prev)}>
              {!showMenu ? (
                <BurgerIcon fill={COLORS_OF_ACTIONS[theme]} />
              ) : (
                <CloseBurgerIcon fill={COLORS_OF_ACTIONS[theme]} />
              )}
            </div>
            {!isLargeMobile && (
              <div 
                onClick={onShowAllProducts}
                className={classNames(styles.Link, styles.All, {
                  [styles.BlackTheme]: hasBlackTheme,
                  [styles.selected]: history.location.pathname === '/catalog' || history.location.pathname === '/catalog/'
                })}>
                Все товары
              </div>
            )}
            {isDesktop && <Links hasBlackTheme={hasBlackTheme} isFaded={showMenu}/>}
          </div>
          <div className={styles.Row}>
            {!isLargeMobile && (
              <>
                <a
                  className={classNames({[styles.Tickets]: true, [styles.DefaultTheme]: !hasBlackTheme})}
                  href="https://vr.hermitageshop.ru/"
                  onMouseOver={() => setHoverIcon2(true)}
                  onMouseOut={() => setHoverIcon2(false)}
                >
                  <VRIcon style={{fill: hoverIcon2 && !hasBlackTheme ? '#cf086e' : COLORS_OF_ACTIONS[theme] }} />
                  <span style={{ marginLeft: 12 }}>
                    {isTablet ? 'VR' : 'VR-experience'}
                  </span>
                </a>
                <div
                  className={classNames({ [styles.Tickets]: true, [styles.DefaultTheme]: !hasBlackTheme })}
                  onMouseOver={() => setHoverIcon1(true)}
                  onMouseOut={() => setHoverIcon1(false)}
                  onClick={() => setShowMuseumMenu(prev => !prev)}
                >
                  <TicketIcon fill={hoverIcon1 && !hasBlackTheme ? '#cf086e' : COLORS_OF_ACTIONS[theme]} />
                  <span style={{ marginLeft: 12 }}>
                    {isTablet ? 'Музей' : 'Посещение музея'}
                  </span>
                  <div className={classNames({ [styles.Arrow]: true, [styles.Arrow_show]: showMuseumMenu })}>
                    <Arrow fill={hoverIcon1 && !hasBlackTheme ? '#cf086e' : COLORS_OF_ACTIONS[theme]} />
                  </div>
                  {showMuseumMenu && (
                    <div className={styles.MuseumMenu}>
                      <Link
                        to="/"
                        className={styles.MuseumMenu__Link}
                        disabled
                      >
                        Входные ваучеры в музей
                      </Link>
                      <Link
                        to="/excursions"
                        className={styles.MuseumMenu__Link}
                      >
                        Экскурсии
                      </Link>
                    </div>
                  )}
                </div>
                <IconButton
                  classes={{
                    button: styles.IconButton,
                  }}
                  onClick={() => setShowSearch((prev) => !prev)}
                  color={showSearch ? 'pink' : iconButtonColor}
                  iconComponent={<SearchIcon />}
                />
              </>
            )}
            <Link to="/basket" className={styles.Basket}>
              <IconButton
                classes={{
                  link: styles.IconButton,
                }}
                color={iconButtonColor}
                iconComponent={<BasketIcon />}
              />
              <div className={styles.Counter}>{!loading && cartLength}</div>
            </Link>
            {!isLargeMobile && (
              <IconButton
                classes={{
                  link: styles.IconButton,
                }}
                link={userCookie ? '/lk' : '/auth'}
                color={iconButtonColor}
                iconComponent={<ProfileIcon />}
              />
            )}
          </div>
        </div>
      </div>
      <Search isShow={showSearch} onHide={() => setShowSearch(false)} theme={theme} showMenu={showMenu}/>
    </div>
  );
};
