const SelectArrow = ({ rotated, color = '#01030C' }) => (
  <svg
    width="14"
    height="9"
    viewBox="0 0 14 9"
    fill="none"
    transform={rotated ? 'rotate(180)' : ''}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="1"
      d="M6.99219 8.28906C7.15625 8.28906 7.30469 8.21875 7.42188 8.09375L13.3438 1.85938C13.4453 1.75781 13.5078 1.63281 13.5078 1.47656C13.5078 1.17188 13.2734 0.9375 12.9688 0.9375C12.8281 0.9375 12.6797 0.992188 12.5781 1.09375L7 6.96875L1.40625 1.09375C1.3125 0.992188 1.17188 0.9375 1.02344 0.9375C0.71875 0.9375 0.484375 1.17188 0.484375 1.47656C0.484375 1.63281 0.546875 1.75781 0.648438 1.86719L6.57031 8.10156C6.69531 8.21875 6.82812 8.28906 6.99219 8.28906Z"
      fill={color}
    />
  </svg>
);

export default SelectArrow;
