import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { getListOrders } from '../helpers';

const query = loader('../../../graphql/getCustomerOrders.graphql');

export const useGetCustomerOrders = () => {
  const { data, loading } = useQuery(query);

  return {
    data: data?.getCustomerOrders,
    orders: getListOrders(data?.getCustomerOrders),
    loading,
  };
};
