import { useSelector } from 'react-redux';
import { selectProductInCartById } from '../selectors/selectProductInCartById';

export const useIsProductInCart = (productId, optionId) => {
  const product = useSelector(selectProductInCartById(productId, optionId));
  return {
    product,
    isProductInCart: !!product,
  };
};
