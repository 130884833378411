// Libraries
import { memo } from 'react';
import classnames from 'classnames';

// Styles
import styles from './style.module.scss';
import { formatPrice } from '../../helpers/formatPrice';

const PriceMemo = ({ price, special_price, quantity, className }) => {
  const discount = Math.ceil((price - special_price) / price * 100);
  const origin_price = Number(price);
  const discount_price = Number(special_price);

  const classNames = classnames(styles.Container, className ?? '');
  const formattedDiscount = discount === 100 ? 99 : discount;

  if (special_price) {
    return (
      <div className={classNames}>
        <div>
          <span className={styles.CurrentPrice}>
            {formatPrice(discount_price)}
          </span>
          <span className={styles.OldPrice}>
            {formatPrice(origin_price)}
          </span>
        </div>
        <div className={styles.Stickers}>
          {parseInt(formattedDiscount) && <div className={styles.Discount}>-{formattedDiscount}%</div>}
          {quantity === 1 && <div className={styles.Last}>Последний</div>}
        </div>
      </div>
    );
  }

  return (
    <div className={classNames}>
      <div className={styles.CurrentPrice}>
        {formatPrice(origin_price)}
      </div>
      <div className={styles.Stickers}>
        {quantity === 1 && <div className={styles.Last}>Последний</div>}
      </div>
    </div>
  )
};

export const Price = memo(PriceMemo);
