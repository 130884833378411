// Libraries
import { memo, useCallback, useState } from "react";

// Styles
import styles from './style.module.scss';

const TEXT_LIMIT = 35;

type PropTypes = {
  text: string;
  length?: number;
}

const TextCroppingMemo = ({text, length = TEXT_LIMIT}: PropTypes) => {
  const [showAllText, setShowAllText] = useState(false);

  const truncate = useCallback((text) => {
    if (text?.length <= length) {
      return text;
    }
    return `${text?.split(' ').splice(0, length).join(' ')}...\t`
  }, [length]);

  return (
    <>
      {showAllText ? text : truncate(text)}
      {!showAllText && text?.length > length &&
        <span className={styles.ShowAllText} onClick={() => setShowAllText(true)}>
          показать всё
        </span>
      }
    </>
  )
}

export const TextCropping = memo(TextCroppingMemo);
