import { useMemo } from 'react';
import Slider from 'react-slick';
import classnames from "classnames";
import ProductCard from '../ProductCard';
import EventCard from '../EventCard';
import PrevArrow from '../Arrows/PrevArrow';
import NextArrow from '../Arrows/NextArrow';
import { useGetOnlyNoveltiesProducts, useGetOnlyHitsProducts, useCategories } from '../../hooks';
import { useResize } from '../../constants';
import {Loader} from "../Loader";
import Button from "../Button";
import styles from './style.module.scss';

const TYPES = {
  productCard: 'productCard',
  events: 'events',
};

const SLIDES_TO_SHOW = {
  [TYPES.productCard]: {
    desktop: 4,
    tablet: 2,
    mobile: 1,
  },
  [TYPES.events]: {
    desktop: 3,
    tablet: 2,
    mobile: 1,
  },
};

const RowItems = ({
  title,
  link,
  items = [],
  itemsType = TYPES.productCard,
  slidesToShow,
  showMore = true,
  classes = {}
}) => {
  const { isDesktop, isTablet, isLargeMobile, isMobile } = useResize();

  // trash code
  const { categoriesLoading } = useCategories();
  const { noveltiesProducts, loading: loadingNovelties } = useGetOnlyNoveltiesProducts();
  const { hitsProducts, loading: loadingHits } = useGetOnlyHitsProducts();

  const slidesLimit = useMemo(() => {
    if (slidesToShow) {
      return slidesToShow;
    }

    if (isDesktop) {
      return SLIDES_TO_SHOW[itemsType].desktop;
    }

    if (isTablet) {
      return SLIDES_TO_SHOW[itemsType].tablet;
    }

    if (isLargeMobile) {
      return SLIDES_TO_SHOW[itemsType].mobile;
    }
  }, [itemsType, isDesktop, isTablet, isLargeMobile]);

  const getRootData = () => {
    switch(title) {
      case 'Новинки': {
        return noveltiesProducts
      }

      case 'Хиты продаж': {
        return hitsProducts
      }

      default: {
        return items
      }
    }
  };

  const rootData = getRootData();

  const settings = useMemo(() => ({
    infinite: slidesLimit < rootData.length,
    touchMove: true,
    speed: 300,
    slidesToShow: slidesLimit,
    slidesToScroll: slidesLimit,
    prevArrow: <PrevArrow arrowClassName={styles.PrevArrow} />,
    nextArrow: <NextArrow arrowClassName={styles.NextArrow} />,
  }), [rootData.length, slidesLimit]);

  const renderContent = useMemo(() => {
    if (itemsType === TYPES.events) {
      return rootData.map((item) => {
        return <EventCard key={item.id} data={item} category={null} />;
      });
    }

    return rootData.map((item) => {
      return <ProductCard key={item.id} item={item} category={null} />;
    });
  }, [itemsType, rootData]);

  return categoriesLoading || loadingNovelties || loadingHits
    ? <Loader />
    : Array.isArray(rootData) && rootData.length > 0
      ? (
        <div className={styles.Container}>
          <div className={styles.TopRow}>
            <div className={styles.Title}>{title}</div>
          </div>
          <div className={classnames(styles.Items, classes?.Items)}>
            {isMobile ? renderContent : (
              <Slider styles={{ display: 'flex'}} {...settings}>
                {renderContent}
              </Slider>
            )}
          </div>
          {showMore && (
            <div className={classnames(styles.ButtonContainer, classes?.Button)}>
              <Button
                link={link}
                linkClassName={styles.Link}
              >
                Смотреть всё
              </Button>
            </div>
          )}
        </div>
      )
      : <></>;
};

export default RowItems;
