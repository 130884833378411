// Styles
import styles from './style.module.scss';

const HaveQuestions = () => (
  <div className={styles.HaveQuestions}>
    <div className={styles.Title}>Есть вопросы по заказу?</div>
    <a className={styles.Phone}>8 (812) 429-74-07</a>
    <a className={styles.Email}>info@hermitageshop.ru</a>
  </div>
);

export default HaveQuestions;
