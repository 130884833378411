// Libraries
import { Checkbox as CheckboxMaterial, FormControlLabel } from '@material-ui/core';
import { Field } from 'react-final-form';

// Styles
import styles from './style.module.scss';

export const Checkbox = ({ name, label, ...props }) => {
  return (
    <Field name={name} type={'checkbox'}>
      {({ input, meta }) => {
        return (
          <>
            <FormControlLabel
              control={<CheckboxMaterial className={styles.Checkbox} />}
              label={<span className={styles.Label}>{label}</span>}
              {...props}
              {...input}
            />
            {meta.error && <span className={styles.Error}>{meta.error}</span>}
          </>
        );
      }}
    </Field>
  );
};
