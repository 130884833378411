import classNames from 'classnames';

// Assets
import { ReactComponent as SuccessIcon } from '../../../assets/success.svg';

// Button
import Button from '../../Button';

// Styles
import styles from './style.module.scss';

const SuccesssPopup =  ({ data, onClose }) => {

    return (
        <>
            <div className={classNames(styles.SuccesssPopup, {[styles.SuccesssPopup_open]: true })}>
                <SuccessIcon />
                <div className={styles.SuccesssPopup__title}>{data?.title}</div>
                {!!data?.subtitle && <div className={styles.SuccesssPopup__subtitle}>{data?.subtitle}</div>}
                <Button onClick={data?.onComplete || onClose} className={styles.SuccesssPopup__button}>Готово</Button>
            </div>
        </>
    )
};
export default SuccesssPopup;