// Libraries
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

// Components
import { ReactComponent as EmptyProducts } from '../../assets/empty_products.svg';
import { ReactComponent as EmptyFavorites } from '../../assets/empty_favorites.svg';
import { ReactComponent as EmptyOrderIcon } from '../../assets/order.svg';
import { ReactComponent as EmptyTicketsIcon } from '../../assets/tickets.svg';
import Button from '../Button';

// Constants
import { CATALOG, FAVORITES, ORDERS, TICKETS } from '../../constants';

import styles from './EmptyContent.module.scss';

export const EmptyContent = ({ type, onResetFilters }) => {
  const renderIcon = useMemo(() => {
    if (type === CATALOG) {
      return <EmptyProducts />;
    }

    if (type === FAVORITES) {
      return <EmptyFavorites />;
    }

    if (type === ORDERS) {
      return <EmptyOrderIcon />;
    }

    if (type === TICKETS) {
      return <EmptyTicketsIcon />;
    }

    return null;
  }, [type]);

  const renderMessage = useMemo(() => {
    if (type === CATALOG) {
      return 'К сожалению, нам не удалось ничего найти по вашему запросу. Попробуйте изменить фильтры или поисковый запрос';
    }

    if (type === FAVORITES) {
      return 'У вас пока нет товаров в избранном. Поищите что-то интересное в каталоге!';
    }

    if (type === ORDERS) {
      return 'У вас пока нет заказов. Поищите что-то интересное в каталоге!';
    }

    if (type === TICKETS) {
      return 'К сожалению, нам не удалось ничего найти по вашему запросу. Попробуйте изменить фильтры или поисковый запрос';
    }
  }, [type]);

  const renderAction = useMemo(() => {
    if (type === CATALOG) {
      return (
        <Button onClick={onResetFilters} filled>
          Сбросить результаты поиска
        </Button>
      );
    }

    if (type === FAVORITES) {
      return (
        <Link className={styles.EmptyContents__link} to={'/catalog'}>
          Перейти в каталог
        </Link>
      );
    }

    if (type === ORDERS) {
      return (
        <Link className={styles.EmptyContents__link} to={'/catalog'}>
          Перейти в каталог
        </Link>
      );
    }

    if (type === TICKETS) {
      return (
        <Link className={styles.EmptyContents__link} to={'/'}>
          На главную
        </Link>
      );
    }

    return null;
  }, [type]);

  return (
    <div className={styles.EmptyContents}>
      <div className={styles.EmptyContents__cirle}>{renderIcon}</div>
      <span className={styles.EmptyContents__text}>{renderMessage}</span>
      {renderAction}
    </div>
  );
};
