
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';

const query = loader('../../../graphql/getNews.graphql');

const useGetNews = () => {
    const { data } = useQuery(query);

    return {
        news: data?.getNews || []
    };
}

export default useGetNews;