import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import {  useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react'; 
import { setExcursion } from './../actions/setExcursions';
import { selectExcursion } from './../selectors/selectExcursions';

const query = loader('../graphql/getExcursion.graphql');

export const useGetExcursionById = (id) => {
  const dispatch = useDispatch();
  const excursion = useSelector(selectExcursion);
  const { data, loading } = useQuery(query, { variables: {id} });
  
  useEffect(() => {
    if (data?.getExcursion) {
      dispatch(setExcursion(data.getExcursion));
    }
  }, [data, dispatch, loading]);

  return {
    excursion,
    loading,
  };
}