// Libraries
import classnames from 'classnames';

// Styles
import styles from './style.module.scss';

export const Title = ({ children, style, className, ...props }) => (
  <div className={classnames(styles.Title, className)} style={style} {...props}>
    {children}
  </div>
);

export const SubTitle = ({ children, style, className, ...props }) => (
  <div className={classnames(styles.SubTitle, className)} style={style} {...props}>
    {children}
  </div>
);
