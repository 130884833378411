/* eslint-disable no-mixed-spaces-and-tabs */
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import Cookies from 'js-cookie';

// Actions
import { updateProductCart } from '../../../actions';

// Constants
import { USER_COOKIE } from '../../../constants';
import { selectAllProductsFromCart } from "../../../selectors";

const mutation = loader('../../../graphql/changeQuantityOfProductsInCart.graphql');

export const useSetCommonCountCart = (product) => {
	const dispatch = useDispatch();
	const [count, setCount] = useState(product.quantity_selected);
	const [changeQuantity, { loading }] = useMutation(mutation);
  const userCookie = Cookies.get(USER_COOKIE);
  const productsFromCart = useSelector(selectAllProductsFromCart);

  useEffect(() => {
    setCount(product.quantity_selected);
  }, [product, productsFromCart]);

  const handleChangeCount = newValue => {
    dispatch(
      updateProductCart({
        ...product,
        quantity_selected: +newValue,
      }),
    );
    setCount(newValue);
  }

  const { id } = productsFromCart.find(({ id }) => id?.toString() === product?.id?.toString());

  const handleCount = (newValue) => {
    if (!loading) {
      if (userCookie) {
        changeQuantity({
          variables: {
            cartId: parseInt(id),
            quantity: +newValue
          },
        }).then(() => handleChangeCount(newValue));
      } else {
        handleChangeCount(newValue)
      }
    }
  }

	return {
		increaseCount: () => handleCount(count + 1),
		subtractionCount: () => handleCount(count - 1),
    setCount: handleCount,
		count,
    loading
	};
};
