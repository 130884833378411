// Libraries
import classnames from 'classnames';

// Components
import Link from '../Link';

// Styles
import styles from './style.module.scss';

const COLORS = {
  pink: 'pink',
  white: 'white',
  black: 'black',
};

const IconButton = ({ iconComponent, link, classes = {}, color = COLORS.black, onClick }) => {
  const Button = () => (
    <button
      className={classnames(
        styles.Action,
        color === COLORS.pink && styles.Action_pink,
        color === COLORS.black && styles.Action_black,
        color === COLORS.white && styles.Action_white,
        classes?.button,
      )}
      onClick={onClick}
    >
      {iconComponent}
    </button>
  );

  return (
    <>
      {link ? (
        <Link to={link} className={classnames(link && styles.Action, classes?.link)}>
          <Button />
        </Link>
      ) : (
        <Button />
      )}
    </>
  );
};

export default IconButton;
