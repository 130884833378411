// Components
import Link from '../../../components/Link';
import NextArrow from '../../../components/Arrows/NextArrow';

import styles from './HaveAcc.module.scss';

export const HaveAcc = () => {
  return (
    <div className={styles.Block}>
      <div className={styles.HaveAcc}>
        <div>У вас уже есть аккаунт?</div>
        <Link to="/auth" alwaysLighting>
          <span className={styles.HaveAcc__Link}>
            <span>Войти</span>
            <NextArrow arrowClassName={styles.HaveAcc__Arrow} />
          </span>
        </Link>
      </div>
    </div>
  );
};
