import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Components
import { RadioGroup } from '../../../components/RadioGroup/RadioGroup';

// Hooks
import { useResize } from '../../../constants';

// Selectors
import { selectCartPrice } from '../../../selectors';

// Assets
import webmoneyIcon from '../../../assets/payments/webmoney.png';
import mastercardIcon from '../../../assets/payments/mastercard.png';
import visaIcon from '../../../assets/payments/visa.png';
import mirIcon from '../../../assets/payments/mir.png';

import styles from './PaymentType.module.scss';

const PAYMENTS_TYPES = {
  pauy: 'pauy',
  cash: 'cash',
  card: 'card',
};

const PAYMENT_TYPES_CDEK = {
  [PAYMENTS_TYPES.pauy]: 'Платёжная система PayU'
};

const PAYMENT_TYPES_PICKUP = {
  [PAYMENTS_TYPES.cash]: 'Оплата при получении',
  [PAYMENTS_TYPES.pauy]: 'Платёжная система PayU'
}

export const PaymentType = ({ initialPaymentMethod, deliveryType }) => {
  const { isMobile } = useResize();
  const [methods, setMethods] = useState({});
  const cartPrice = useSelector(selectCartPrice);

  useEffect(() => {
    if (cartPrice === 0) {
      setMethods({[PAYMENTS_TYPES.cash]: PAYMENT_TYPES_PICKUP[PAYMENTS_TYPES.cash]});
    } else if (deliveryType === 'pickup') {
      setMethods(PAYMENT_TYPES_PICKUP)
    } else {
      setMethods(PAYMENT_TYPES_CDEK);
    }
  }, [cartPrice, deliveryType])

  const paymentMethods = Object.entries(methods).map(([value, label]) => ({
    value,
    label:
      value === PAYMENTS_TYPES.pauy ? (
        <div className={styles.PaymentType__Radio}>
          <span>{label}</span>
          {!isMobile && (
            <div className={styles.PaymentType__Systems}>
              <div className={styles.PaymentType__System}>
                <img src={webmoneyIcon} alt="" />
              </div>
              <div className={styles.PaymentType__System}>
                <img src={mastercardIcon} alt="" />
              </div>
              <div className={styles.PaymentType__System}>
                <img src={visaIcon} alt="" />
              </div>
              <div className={styles.PaymentType__System}>
                <img src={mirIcon} alt="" />
              </div>
            </div>
          )}
        </div>
      ) : (
        label
      ),
  }));

  if (cartPrice === 0) {
    return <></>
  }

  return (
    <div className={styles.PaymentType}>
      <div className={styles.Title}>Способ оплаты</div>
      <div className={styles.PaymentType__Item}>
        <RadioGroup
          value={initialPaymentMethod}
          name={'payment_method'}
          data={paymentMethods}
          classes={{
            Items: styles.PaymentType__RadioItems,
            Item: styles.PaymentType__RadioItem,
          }}
        />
      </div>
    </div>
  );
};
