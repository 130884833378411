
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from '@material-ui/core';
import Button from '../Button';
import useGetPopup from '../../pages/Landing/hooks/useGetPopup';
import { setPopup } from '../../actions';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import styles from './style.module.scss';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};

const PopUp = ({ popUpPage }) => {
	const dispatch = useDispatch();
	const { popupData, popupStats } = useGetPopup({ page: popUpPage });

	const handleClose = () => {
		dispatch(setPopup({
			data: null,
			stats: {
				...popupStats,
				[popupData.id]: popupStats[popupData.id] === 'more' ? 'more' : 0
			}
		}));
	};

	const needShow = useMemo(() => !!(
		popupData
		&& popupStats
		&& Object.prototype.hasOwnProperty.call(popupStats, popupData.id)
		&& popupStats[popupData.id] !== 0
	), [popupData, popupStats]);

	return needShow ? (
		<Modal
			open={true}
			onClose={handleClose}
			className={styles.PopUp}
		>
			<div className={styles.Container}>
				<CloseIcon
					className={styles.CloseIcon}
					onClick={handleClose}
				/>
				<div className={styles.Title}>
					{popupData?.title}
				</div>
				<div className={styles.Description}>
					{popupData?.description}
				</div>
				<Button
					filled
					className={styles.Button}
					onClick={handleClose}
				>
					Закрыть
				</Button>
			</div>
		</Modal>
	) : <></>
};

export default PopUp;