import { useLayoutEffect, useState } from 'react';
// import Cookies from 'js-cookie';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { formatSexForUi } from '../helpers';

const query = loader('../graphql/getCustomer.graphql');

export const useCustomer = () => {
  // const userCookie = Cookies.get('hermitag_user');
  const [user, setUser] = useState(null);
  const { data, loading } = useQuery(query);

  useLayoutEffect(() => {
    if (data?.getCustomer) {
      setUser(data.getCustomer);
    }
  }, [data]);

  // useEffect(() => {
  //   if (userCookie) {
  //     setUser(JSON.parse(userCookie));
  //   }
  // }, [userCookie]);

  return {
    loading,
    user: {
      name: user ? `${user.firstname} ${user.lastname}` : '',
      email: user ? user.email : '',
      pol: formatSexForUi(user?.pol),
      birth_day: user ? user.birth_day : null,
      address: user?.address?.address_1 ? user.address.address_1 : '',
    },
  };
};
