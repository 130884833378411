
// Components
import { useResize } from '../../constants';
import classnames from 'classnames';

// Styles
import styles from './style.module.scss';

const Sticker = ({ content = '%' }) => {
	const { isMobile } = useResize();

	return (
		<div className={classnames(styles.Sticker, isMobile && styles.Sticker_mobile)}>
			<div className={styles.Sticker__Content}>
				{content}
			</div>
		</div>
	)
};

export default Sticker;