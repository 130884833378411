
// Hooks
import { useEffect, useState } from 'react';
import { useWindowDimensions } from '../../hooks';

// Assets
import toUpArrowIcon from '../../assets/to_up_arrow.svg';

// Styles
import styles from './style.module.scss';


const ToUpArrow = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const { height } = useWindowDimensions();

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleToUp = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  return scrollPosition > height / 3 ? (
    <div className={styles.ToUpArrow} onClick={handleToUp}>
      <div className={styles.Arrow}>
        <img src={toUpArrowIcon} alt='go to up' />
      </div>
    </div>
  ) : <></>
};

export default ToUpArrow;