import { 
  SET_PROMO, 
  SET_PROMO_IS_USED, 
  SET_PROMO_VALUE, 
  SET_PROMO_ERROR 
} from '../actions'

const initialState = {
  data: null,
  error: null,
  isUsed: false,
  value: ''
}

export const promoReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROMO: {
      return {
        ...state,
        data: action.payload
      }
    }
    case SET_PROMO_ERROR: {
      return {
        ...state,
        error: action.payload
      }
    }
    case SET_PROMO_IS_USED: {
      return {
        ...state,
        isUsed: action.payload
      }
    }
    case SET_PROMO_VALUE: {
      return {
        ...state,
        value: action.payload
      }
    }
    default:
      return state
  }
}