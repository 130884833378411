import Cookies from 'js-cookie';
import {clearProductsFromCart, deleteProductFromCart} from '../actions';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useDispatch } from 'react-redux';
import { USER_CART_COOKIE, USER_COOKIE } from '../constants';
import { useCart } from "./useCart";

const deleteProductFromCartMutation = loader('../graphql/deleteCartItem.graphql');

export const useClearProductsFromCart = () => {
  const { products } = useCart();
  const dispatch = useDispatch();
  const user = Cookies.get(USER_COOKIE);

  const [deleteCartItem] = useMutation(deleteProductFromCartMutation, {
    onCompleted: (res) => {
      if (res?.deleteCartItem?.status === true) {
        dispatch(deleteProductFromCart(res?.deleteCartItem?.id));
      }
    },
  });

  const handleClearProductsFromCart = () => products.map(({ id }) => {
    if (user) {
      deleteCartItem({ variables: { id } });
    }
    localStorage.setItem(USER_CART_COOKIE, JSON.stringify([]));
    dispatch(clearProductsFromCart());
  });


  return { handleClearProductsFromCart };
};
