import { loader } from 'graphql.macro';
import { useLazyQuery } from '@apollo/client';
import {  useDispatch, useSelector } from 'react-redux';

import { setFilters } from '../actions';
import { selectFilters } from '../selectors';
import { useEffect } from 'react';

const query = loader('../graphql/getFilters.graphql');

export const useFilters = (isLoad) => {
  const dispatch = useDispatch();
  const filters = useSelector(selectFilters);
  const [getFilters, { loading }] = useLazyQuery(query, {
    onCompleted: (res) => {
      if (res?.getFilters) {
        dispatch(setFilters(res.getFilters));
      }
    }
  });

  useEffect(() => { 
    if (isLoad) {
      getFilters()
    }
  }, [getFilters, isLoad])

  return {
      filters,
      loading,
    };
}