// Components
import Page from '../../components/Page';
import Head from '../../components/Head';
import Ticket from '../../components/Ticket';
import LoadContainer from '../../components/LoadContainer';
import { TICKETS } from '../../constants';

// Styles
import styles from './style.module.scss';

const Tickets = () => {
  const renderTickets = (item) => {
    return item && <Ticket data={item} />;
  };

  return (
    <Page>
      <Head title="Посещение музея" />
      <div className={styles.Tickets}>
        <LoadContainer
          component={renderTickets}
          fetchRequest={() => null}
          type={TICKETS}
          items={[]}
          gridSettings={{
            itemsPerRow: {
              desktop: 3,
              tablet: 2,
              largeMobile: 1,
              mobile: 1,
            },
            itemsPerPage: {
              desktop: 15,
              tablet: 15,
              largeMobile: 15,
              mobile: 15,
            },
          }}
        />
      </div>
    </Page>
  );
};

export default Tickets;
