// Libraries
import { useState } from 'react';
import classnames from 'classnames';

// Components
import Divider from '../../components/Divider';

// Styles
import styles from './style.module.scss';

const Tabs = ({ title, tabs = {}, content = {}, defaultTab = 0, handleSetTab = () => {} }) => {
  const [selectedTab, setSelectedTab] = useState(defaultTab);

  const handleSelectedTab = (key) => {
    handleSetTab(key);
    setSelectedTab(key);
  }

  return (
    <div className={styles.Tabs}>
      <div className={styles.Top}>
        {title && <div className={styles.Title}>{title}</div>}
        <div className={classnames(styles.Nav, title && styles.Nav_withTitle)}>
          {Object.entries(tabs).map(([key, title]) => (
            <div
              key={title}
              className={classnames(styles.Tab, selectedTab === key && styles.Tab_selected)}
              onClick={() => handleSelectedTab(key)}
            >
              <span>{title}</span>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.Divider}>
        <Divider />
      </div>
      <div className={styles.Content}>{content[selectedTab]}</div>
    </div>
  );
};

export default Tabs;
