import {useEffect, useMemo} from 'react';
// Components
import { RowSelector } from '../../../components/RowSelector';
import Select, {ReactSelect} from '../../../components/Select';
import { RadioGroup } from '../../../components/RadioGroup/RadioGroup';
import { Input } from '../../../components/Input/Input';

// Hooks
import { DELIVERY_TYPES, useResize } from '../../../constants';

import styles from './DeliveryType.module.scss';

const courierPlaces = [
  {
    label: (
      <div className={styles.DeliveryType__RadioContent}>
        <div className={styles.DeliveryType__RadioTitle}>Доставка СДЭК до двери</div>
      </div>
    ),
    value: 'toDoor',
  },
  {
    label: (
      <div className={styles.DeliveryType__RadioContent}>
        <div className={styles.DeliveryType__RadioTitle}>
          Доставка СДЭК до пункта выдачи заказов
        </div>
      </div>
    ),
    value: 'toPoint',
  },
];

const pickupPlaces = [
  {
    label: (
      <div className={styles.DeliveryType__RadioContent}>
        <div className={styles.DeliveryType__RadioTitle}>Магазин на выходе из Главного штаба</div>
      </div>
    ),
    value: 1,
  },
];

export const DeliveryType = ({
  initialCourierPlace,
  initialPickupPlace,
  setOrders,
  orders,
  courierPlace,
  cdekRef,
  getDeliveryInfo,
  cities,
  values,
}) => {
  const ROW_SELECT_DATA = [
    {
      value: DELIVERY_TYPES.courier,
      label: 'Доставка курьером',
    },
    {
      value: DELIVERY_TYPES.pickup,
      label: 'Самовывоз',
    },
  ];

  const {
    delivery: {
      type,
      data: { shop },
    },
  } = orders;

  const handleChangeData = (field, value) => {
    setOrders((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  useEffect(() => {
    if (courierPlace && type) {
      getDeliveryInfo({ courier_place: courierPlace, type, ...values });
    }
  }, [courierPlace, type, values]);

  const handleChangeType = (updatedType) => {
    handleChangeData('delivery', { ...orders.delivery, type: updatedType });
  };

  const renderCourierFields = useMemo(() => {
    if (courierPlace === 'toDoor') {
      return (
        <>
          <ReactSelect name={'city'} placeholder={'Город'} options={Object.values(cities)} />
          <Input name={'address'} label={'Адрес доставки'} placeholder={'Адрес доставки'} />
          <Input
            name={'index'}
            label={'Индекс'}
            placeholder={'Индекс'}
            type={'number'}
          />
        </>
      );
    }

    if (courierPlace === 'toPoint') {
      return null;
    }
  }, [courierPlace, cities]);

  const renderWidjet = useMemo(() => {
    return (
      <div
        ref={cdekRef}
        id="forpvz"
        style={{
          height: 325,
          display: courierPlace === 'toDoor' || type === DELIVERY_TYPES.pickup ? 'none' : 'block',
          marginBottom: 40,
        }}
      />
    )
  }, [courierPlace, type, cdekRef]);

  return (
    <div className={styles.DeliveryType}>
      <div className={styles.Title}>Способ доставки</div>
      <RowSelector value={type} data={ROW_SELECT_DATA} onChange={handleChangeType} />
      <div className={styles.DeliveryType__Content}>
        {type === DELIVERY_TYPES.courier && (
          <>
            <RadioGroup
              value={initialCourierPlace}
              name={'courier_place'}
              data={courierPlaces}
              classes={{
                Item: styles.DeliveryType__Radio,
              }}
            />
            {renderCourierFields}
          </>
        )}
        {type === DELIVERY_TYPES.pickup && (
          <>
            <RadioGroup value={initialPickupPlace} name={'pickup_place'} data={pickupPlaces} />
            <div className={styles.DeliveryAddress}>
              <div className={styles.DeliveryType__Item}>
                <div className={styles.DeliveryType__Label}>Адрес</div>
                <div className={styles.DeliveryType__Value}>
                  <span>{shop.address.name}</span>{' '}
                  <a href={shop.address.link} className={styles.Link}>
                    Показать на карте
                  </a>
                </div>
              </div>
              <div className={styles.DeliveryType__Item}>
                <div className={styles.DeliveryType__Label}>График работы</div>
                <div className={styles.DeliveryType__Wrapper}>{shop.workingTime.map(item => (
                  <div key={item} className={styles.DeliveryType__Value}>{item}</div>
                ))}
                </div>
              </div>
              <div className={styles.DeliveryType__Item}>
                <div className={styles.DeliveryType__Label}>Срок доставки</div>
                <div className={styles.DeliveryType__Value}>{shop.deliveryTime}</div>
              </div>
            </div>
          </>
        )}
        {renderWidjet}
        <Input placeholder="Комментарий к заказу" name={'comment'} />
      </div>
    </div>
  );
};
