// Libraries
import { useDispatch } from 'react-redux'
import classnames from 'classnames';
import { Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';

// Components
import Button from '../Button';
import { Input } from '../Input/Input';

// Constants
import { THEMES, useResize } from '../../constants';

// Actions
import { setKeyword } from '../../actions';

//Assets
import searchIcon from '../../assets/actions/search_mobile.svg';

// Hooks
import { useGetProducts } from '../../hooks';

// Styles
import styles from './style.module.scss';

const Search = ({ isShow, onHide, theme, showMenu }) => {
  const dispatch = useDispatch();
  const { isMobile } = useResize();
  const { handlerGetProducts } = useGetProducts();
  const history = useHistory();

  const onSubmit = (values) => {
      dispatch(setKeyword(values.keyword));
      onHide(false);
      history.push('/catalog');
  };

  if (isMobile && showMenu) {
    return null;
  }

  return (
    isShow && (
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => {
          return (
            <form
              className={classnames(styles.Search, theme === THEMES.black && styles.Search_black)}
              onSubmit={handleSubmit}
            >
              <Input
                className={styles.Input}
                name={'keyword'}
                placeholder="Поиск по эрмитажному магазину..."
                onKeyUp={(e) => e.key === 'Enter' && handlerGetProducts()}
              />
              <Button className={styles.Button} filled={theme === THEMES.black}>
                Найти
              </Button>
            </form>
          );
        }}
      />
    )
  );
};

export const SearchMobile = ({ setHide }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onSubmit = (values) => {
      dispatch(setKeyword(values.keyword));
      setHide();
      history.push('/catalog');
  };

  return (
    <Form onSubmit={onSubmit}>
      {({handleSubmit}) => {
        return (
          <div className={styles.SearchMobile}>
            <form onSubmit={handleSubmit}>
              <Input name={'keyword'} placeholder={'Поиск...'} className={styles.SearchMobile__input}/>
              <button className={styles.SearchMobile__Icon} type="submit">
                <img src={searchIcon} alt="" />
              </button>
            </form>
          </div>
        )
      }}
  </Form>
  )
}

export default Search;
