// Components
import Divider from '../Divider';

// Styles
import styles from './style.module.scss';

const Head = ({ title, text }) => {
  return (
    <>
      <div className={styles.Head}>
        {title && <div className={styles.Title}>{title}</div>}
        <div className={styles.Texts}>
          <div className={styles.Text}>{text}</div>
        </div>
      </div>
      <Divider />
    </>
  );
};

export default Head;
